/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col } from "react-bootstrap";
import firebase from "../../Firebase";

import { Span } from "../../common/Span";
import { Button } from "../../common/Button";

import "./OrderDetails.scss";

import { RootState, Dispatch } from "../../store";
import OrderInfo from "./OrderInfo/OrderInfo";
import CancelModal from "./Modal/CancelModal";
import { IVehicles } from "../../types/IVehicle";
import { getExpressOrdersById } from "../../common/api/endpoints/orderlist";
import { DATA_TYPE } from "../../constants";
import Loader from "../../components/Loader/Loader";

let DELIVERY_UNSUBSCRIBE: any = null;
let DRIVER_UNSUBSCRIBE: any = null;

type Props = {
  datatype: string;
};

const OrderDetails = ({ datatype }: Props) => {
  const history = useHistory();
  const [order, setOrder] = useState({} as any);
  const [delivery, setDelivery] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<Dispatch>();

  const { referenceNumber }: any = useParams();

  const [cancelModal, setCancelModal] = useState(false);
  const handleCancelModal = (e: any) => {
    e.preventDefault();
    setCancelModal(true);
  };

  const vehicles = useSelector<RootState>(
    (state: RootState) => state.Order.vehicles
  ) as IVehicles;

  const pathname = useSelector<RootState>(
    (state: RootState) => state.UI.currentRoute
  );

  const handleBookAgain = () => {
    dispatch.Order.setVehicles(
      vehicles.map((v) => {
        v.isSelected = v.vehicleType === delivery?.vehicleType;
        return v;
      })
    );

    dispatch.Order.updateState({
      itemDescription: delivery.itemDescription,
      deliveryInstruction: delivery.notes
    });

    const pickupLocation = {
      locationAddress: delivery.pickup?.locationAddress,
      locationDetails: delivery?.pickup?.location?.address,
      contactName: delivery.pickup?.contactName,
      contactPhone: delivery.pickup?.contactPhone
        .split("+63")
        .slice(1)
        .join("."),
      lat: delivery?.pickup?.location?.lat,
      lng: delivery?.pickup?.location?.lng,
      showPopOver: false,
      isSelected: true
    };

    const stopsList = delivery?.stops
      ?.sort((a, b) => a.sequenceNo > b.sequenceNo)
      ?.map((stp: any) => {
        const data = {
          locationAddress: stp.locationAddress,
          locationDetails: stp.location.address,
          contactName: stp.contactName,
          contactPhone: stp.contactPhone.split("+63").slice(1).join("."),
          deliveryInstruction: stp.deliveryInstruction,
          itemCategory: stp.itemCategory,
          lat: stp.location.lat,
          lng: stp.location.lng,
          showPopOver: false,
          isSelected: true
        };
        return data;
      });

    const dropOffLocation = {
      locationAddress: delivery.dropoff?.locationAddress,
      locationDetails: delivery?.dropoff?.location?.address,
      contactName: delivery.dropoff?.contactName,
      contactPhone: delivery.dropoff?.contactPhone
        .split("+63")
        .slice(1)
        .join("."),
      deliveryInstruction: delivery.dropoff?.deliveryInstruction,
      itemCategory: delivery.dropoff?.itemCategory,
      lat: delivery?.dropoff?.location?.lat,
      lng: delivery?.dropoff?.location?.lng,
      showPopOver: false,
      isSelected: true
    };

    const newLocations = [pickupLocation, ...stopsList, dropOffLocation];

    dispatch.Locations.updateState({
      locations: [...newLocations]
    });
    history.push("/order");
  };

  const listenToOpernOrder = async () => {
    let order = (
      await firebase.firestore().collection("orders").doc(referenceNumber).get()
    ).data();

    DELIVERY_UNSUBSCRIBE = firebase
      .firestore()
      .collection("deliveries")
      .doc(order?.deliveryId)
      .onSnapshot((doc) => {
        let delivery: any = doc.data();
        delivery?.stops?.sort((a, b) => (a.sequenceNo > b.sequenceNo ? 1 : -1));
        delivery.dropoff = delivery?.stops?.pop();

        setDelivery(delivery);
        dispatch.Locations.updateState(delivery);
      });
    setOrder(order);
  };

  const listenToCompletedOrder = async () => {
    setIsLoading(true);
    try {
      const res = await getExpressOrdersById(referenceNumber);
      res[0].delivery.dropoff = res[0].delivery?.stops?.pop();
      setDelivery(res[0]?.delivery);
      dispatch.Locations.updateState(res[0]?.delivery);
      setOrder(res[0]);
    } catch (error) {
      throw error;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setDelivery([]);
    if (datatype === DATA_TYPE.open) {
      listenToOpernOrder();
    } else {
      listenToCompletedOrder();
    }
    return function () {
      try {
        if (DELIVERY_UNSUBSCRIBE) DELIVERY_UNSUBSCRIBE();
        if (DRIVER_UNSUBSCRIBE) DRIVER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, [referenceNumber]);

  return (
    <>
      <Col
        lg="4"
        className="order-details-side absolute inset-y-0 right-0 px-0"
      >
        {!isLoading ? (
          <>
            <OrderInfo
              order={order}
              delivery={delivery}
              referenceNumber={referenceNumber}
              showViewMap={true}
              pathname={pathname}
              datatype={datatype}
            />

            <div className="p-3 mb-2 flex justify-between inset-x-0 bottom-0">
              <div
                onClick={() => history.push(`${pathname}`)}
                className="cursor-pointer my-auto"
              >
                <Span weight="semi" color="orange">
                  Close
                </Span>
              </div>

              {delivery.status in
                { pending: true, accepted: true, assigned: true } && (
                <div
                  className="cursor-pointer p-2 order-details-border"
                  onClick={handleCancelModal}
                >
                  <Span weight="semi">Cancel Order</Span>
                </div>
              )}

              {order?.status === "canceled" || order?.status === "delivered" ? (
                <>
                  <Button
                    width="none"
                    padding="p-2"
                    weight="medium"
                    margin="mx"
                    bg="orange"
                    onClick={handleBookAgain}
                  >
                    <Span weight="semi" color="white">
                      Book delivery again
                    </Span>
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <Loader ultra={true} />
        )}

        {cancelModal && (
          <CancelModal
            setCancelModal={setCancelModal}
            orderId={order.orderId}
          />
        )}
      </Col>
    </>
  );
};

export default OrderDetails;
