/* eslint-disable eqeqeq */
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import { Col, Spinner } from "react-bootstrap";
import "../OrderConfirmation.scss";
import {
  currencyCommaFormat,
  validatePaymentMethod
} from "../../../common/utils";

import { Span } from "../../../common/Span";
import { Button } from "../../../common/Button";
import Peso from "../../../assets/img/web/peso.png";
import { analyticEvent, Event } from "../../../customHooks/analytics";

const ConfirmOrder = () => {
  const {
    totalFee,
    isRequestOrderLoading,
    isFareDetailsLoading,
    isValidatePromoCodeLoading,
    orderDetails,
    itemDescription,
    paymentMethod,
    billTo,
    isRemitOrCollect,
    amountToBeCollected
  } = useSelector((state: RootState) => state.Order);

  const renderLoading = () => {
    return (
      <Col
        lg="12"
        className="position-absolute text-primary p-3 d-flex justify-content-center align-items-center"
      >
        <Spinner animation="border" role="status" className="spinner" />
      </Col>
    );
  };

  const isLoading =
    isRequestOrderLoading || isFareDetailsLoading || isValidatePromoCodeLoading;

  const validPaymentMethod = validatePaymentMethod(paymentMethod, billTo);
  const dispatch = useDispatch<Dispatch>();

  return (
    <div className="flex p-3 absolute inset-x-0 bottom-0 bg-white-1 desktop-visible">
      <Col sm="5" className="text-left my-auto pl-0">
        <div className="w-100 d-flex align-items-center">
          {!isFareDetailsLoading ? (
            <>
              <p className="order-price__amount">
                {orderDetails?.fare?.promo?.isValidPromo ? (
                  <span className="standard_fair_line">
                    {`Php ${currencyCommaFormat(
                      orderDetails?.fare?.promo?.originalRate,
                      2
                    )}`}
                    <br />
                  </span>
                ) : null}
                <div className="flex">
                  <img src={Peso} alt="" className="h-6 my-1" />
                  <Span weight="semi" size="3xl" padding="none">
                    {currencyCommaFormat(totalFee, 2)}
                  </Span>
                </div>
              </p>
            </>
          ) : (
            renderLoading()
          )}
        </div>
        {!isRemitOrCollect && amountToBeCollected != 0 ? (
          <p>
            <span className="text-xs">
              Return trip cost included {isRemitOrCollect}
            </span>
          </p>
        ) : (
          <></>
        )}
      </Col>
      <Col sm="7" className="text-right">
        <Button
          width="none"
          padding="px-12"
          disabled={
            isLoading ||
            itemDescription === null ||
            !validPaymentMethod ||
            itemDescription?.toString().trim().length === 0
          }
          onClick={() => {
            dispatch.Order.requestOrder();
            analyticEvent(Event.ExpressConfirmation);
          }}
        >
          <span className="font-weight-bold">Confirm Order</span>
        </Button>
      </Col>
    </div>
  );
};

export default ConfirmOrder;
