/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import MainContainer from "../../components/MainContainer/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { RootState, Dispatch } from "../../store";
import { Row, Col } from "react-bootstrap";
import Map from "../../components/Map/Map";
import ItemDescription from "../../components/ItemDescription/ItemDescription";
import "./OrderConfirmation.scss";
import { currencyCommaFormat } from "../../common/utils";
import PromoCode from "../../components/PromoCode/PromoCode";
import { useHistory } from "react-router-dom";

import { Span } from "../../common/Span";
import FeeBreakdown from "../Order/Price/FeeBreakdown";
import PaymentMethod from "../../components/PaymentMethod/PaymentMethod";
import WalletBalance from "../../components/WalletBalance/WalletBalance";
import ConfirmOrder from "./ConfirmOrder/ConfirmOrder";
import ConfirmOrderResponsive from "./ConfirmOrder/ConfirmOrdeResponsive";

const OrderConfirmation = () => {
  const alertHook = useAlert();
  const dispatch = useDispatch<Dispatch>();
  const { confirmedLocations } = useSelector(
    (state: RootState) => state.OrderConfirmation
  );

  const {
    totalFee,
    isRequestOrderLoading,
    requestOrderResponse,
    orderDetails,
    itemDescription,
    paymentMethod
  } = useSelector((state: RootState) => state.Order);

  const history = useHistory();

  useEffect(() => {
    // Meaning request order is successful
    if (!isRequestOrderLoading && requestOrderResponse?.order) {
      history.push("/order-list");
    }
  }, [isRequestOrderLoading, requestOrderResponse?.order, history]);

  // handle error responses
  useEffect(() => {
    if (requestOrderResponse?.message) {
      alertHook.show(requestOrderResponse?.message);
    }
    dispatch.Order.updateState({ requestOrderResponse: null });
  }, [
    requestOrderResponse?.message,
    alertHook,
    requestOrderResponse?.errorCode,
    dispatch.Order
  ]);

  useEffect(() => {
    dispatch.Locations.updateState({
      locations: confirmedLocations.map((location) => ({ ...location }))
    });
    dispatch.Order.updateState({
      isValidPromoCode: null,
      isRequestOrderLoading: false
    });
    dispatch.Order.calculateFare();
  }, [dispatch.Order, dispatch.Locations]);

  useEffect(() => {
    dispatch.Order.calculateFare();
  }, [paymentMethod]);

  useEffect(() => {
    dispatch.Order.setMapPermission("READ");
    return () => {
      dispatch.Order.setMapPermission("WRITE");
    };
  }, []);

  return (
    <>
      <MainContainer>
        {/* <WalletBalance /> */}

        <Row className="h-screen ml-100">
          <Col
            lg="4"
            className="position-relative left-column border-right px-0"
          >
            <div className="order-container scrollable-container">
              <ItemDescription
                itemDescription={itemDescription}
                onDescriptionChange={(e) =>
                  dispatch.Order.updateState({
                    itemDescription: e.target.value
                  })
                }
                onNotesChange={(e) =>
                  dispatch.Order.updateState({
                    deliveryInstruction: e.target.value //notes
                  })
                }
              />

              <PaymentMethod />
              <PromoCode />
              <div className="p-4">
                <div className="py-2 border-dashed border-b border-getgrey-1 text-center">
                  <Span
                    size="base"
                    weight="semi"
                    color="orange"
                    padding="px-6"
                    letters="widest"
                  >
                    Delivery Fee Breakdown
                  </Span>
                </div>
                <FeeBreakdown />

                <div className="flex justify-between">
                  <div>
                    <Span margin="none" weight="semi" padding="py-2">
                      Subtotal{" "}
                    </Span>
                  </div>
                  <div>
                    <Span margin="none" padding="py-1" weight="semi">
                      ₱{currencyCommaFormat(orderDetails?.fare?.total, 2)}
                    </Span>
                  </div>
                </div>

                <div className="flex justify-between">
                  <div>
                    <Span margin="none" padding="py-2">
                      Discount{" "}
                    </Span>
                  </div>
                  <div>
                    <Span margin="none" padding="py-1">
                      ₱
                      {currencyCommaFormat(
                        orderDetails?.fare?.discountAmount,
                        2
                      )}
                    </Span>
                  </div>
                </div>

                <div className="flex justify-between">
                  <div>
                    <Span margin="none" weight="semi" padding="py-2">
                      Total{" "}
                    </Span>
                    <Span margin="none" padding="py-2" size="xs">
                      (incl. VAT)
                    </Span>
                  </div>
                  <div>
                    <Span margin="none" padding="py-1" weight="semi">
                      ₱{currencyCommaFormat(totalFee, 2)}
                    </Span>
                  </div>
                </div>
              </div>
              <ConfirmOrderResponsive />
            </div>

            <ConfirmOrder />
          </Col>
          <Col lg="8" className="p-0 position-relative map-section">
            <div className="map-view">
              <Map />
            </div>
          </Col>
        </Row>
      </MainContainer>
    </>
  );
};

export default OrderConfirmation;
