import { Container } from "react-bootstrap";
import "./MainContainer.scss"; 

const MainContainer = (props?: any) => {
  return (
    <>
      <div className='main-content'>
        <Container className={props?.className} fluid>
          {props.children}
        </Container>
      </div> 
    </>
  );
};

export default MainContainer;