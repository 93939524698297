import * as React from "react";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  position?: "absolute" | "center";
  border?: "grey" | "lightgrey";
  bg?: "grey" | "gray" | "none" | "orange";
  margin?: "top" | "right" | "my" | "m-auto" | "mt-2" | "my-2" | "mt-1";
  flex?: "flex" | "wrap";
  width?:
    | "none"
    | "primary"
    | "full"
    | "modal-xs"
    | "modal-s"
    | "medium"
    | "large";
  grid?: "two" | "four";
  gap?: "none";
  padding?: "none" | "0.5" | "p4" | "py-2";
};

let getPositon = (position: Props["position"]) => {
  switch (position) {
    case "center":
      return "items-center justify-center ";
    case "absolute":
      return "absolute";
    default:
      return "relative";
  }
};

let getBorder = (border: Props["border"]) => {
  switch (border) {
    case "lightgrey":
      return "border border-getgrey-1";
    case "grey":
      return "border border-getgrey-3";
    default:
      return "";
  }
};

let getBg = (bg: Props["bg"]) => {
  switch (bg) {
    case "orange":
      return "bg-palette-main";
    case "none":
      return "bg-transparent";
    case "grey":
      return "bg-getgrey-4";
    default:
      return "bg-white-1";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {
    case "my-2":
      return "my-2";
    case "m-auto":
      return "m-auto";
    case "my":
      return "my-3";
    case "right":
      return "mr-3 mt-6";
    case "mt-2":
      return "mt-2.5";
    case "top":
      return "mt-8";
    case "mt-1":
      return "mt-1";
    default:
      return "";
  }
};

let getFlex = (flex: Props["flex"]) => {
  switch (flex) {
    case "wrap":
      return "flex flex-wrap";
    case "flex":
      return "flex";
    default:
      return "flex-none";
  }
};

let getWidth = (width: Props["width"]) => {
  switch (width) {
    case "large":
      return "w-8/12";
    case "medium":
      return "w-6/12";
    case "modal-s":
      return "lg:w-5/12 md:w-11/12";
    case "modal-xs":
      return "lg:w-4/12 md:w-11/12";
    case "full":
      return "w-full";
    case "primary":
      return "w-full lg:w-5/12 px-5";
    case "none":
      return "";
    default:
      return "w-96";
  }
};

let getGap = (gap: Props["gap"]) => {
  switch (gap) {
    case "none":
      return "gap-0";
    default:
      return "gap-5";
  }
};

let getGrid = (grid: Props["grid"]) => {
  switch (grid) {
    case "four":
      return "lg:grid-cols-4 md:grid-cols-4";
    case "two":
      return "lg:grid-cols-2 md:grid-cols-2";
    default:
      return "lg:grid-cols-3 md:grid-cols-3";
  }
};

let getPadding = (padding: Props["padding"]) => {
  switch (padding) {
    case "py-2":
      return "py-2";
    case "p4":
      return "p-4";
    case "0.5":
      return "p-0.5";
    case "none":
      return "p-0";
    default:
      return "p-2.5";
  }
};

export const InputContainer = ({
  position,
  border,
  flex,
  margin,
  padding,
  children
}: Props) => {
  return (
    <div
      className={classnames(
        "rounded-md",
        getPositon(position),
        getBorder(border),
        getFlex(flex),
        getMargin(margin),
        getPadding(padding)
      )}
    >
      {children}
    </div>
  );
};

export const Container = ({
  margin,
  bg,
  border,
  position,
  width,
  padding,
  children
}: Props) => {
  return (
    <div
      className={classnames(
        "rounded-md",
        getBg(bg),
        getBorder(border),
        getMargin(margin),
        getWidth(width),
        getPositon(position),
        getPadding(padding)
      )}
    >
      {children}
    </div>
  );
};

export const ModalContainer = ({ bg, width, children }: Props) => {
  return (
    <div
      className={classnames(
        "flex fixed inset-0 z-50 outline-none focus:outline-none items-center justify-center"
      )}
    >
      <div className={classnames(getWidth(width))}>
        <div
          className={classnames(
            "shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-sm",
            getBg(bg)
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export const GridContainer = ({
  margin,
  grid,
  border,
  gap,
  children
}: Props) => {
  return (
    <div
      className={classnames(
        "grid sm:grid-cols-1",
        getMargin(margin),
        getGrid(grid),
        getBorder(border),
        getGap(gap)
      )}
    >
      {children}
    </div>
  );
};

export const Card = ({ margin, border, padding, bg, children }: Props) => {
  return (
    <div
      className={classnames(
        "rounded-md shadow-md",
        getMargin(margin),
        getBorder(border),
        getPadding(padding),
        getBg(bg)
      )}
    >
      {children}
    </div>
  );
};
