import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  useExpanded
} from "react-table";
import "./Table.scss";
import BTable from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Dispatch } from "../../store";
import { Skeleton } from "@material-ui/lab";

interface TableProps<D extends object> {
  columns: Column<D>[];
  data: D[];
  controlledPageCount: number;
  currentPage: number;
  renderRowSubComponent?: any;
  fetchData: any;
  isLoading?: boolean;
  totalCount?: number;
  hideFooterControl?: boolean;
  showOrderDetails?: boolean;
  isDateFilter?: boolean;
  dataType?: any;
}

export function generateBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function RTable<D extends object>({
  columns,
  data,
  isLoading,
  showOrderDetails = false,
  controlledPageCount,
  dataType
}: TableProps<D>) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        pageCount: controlledPageCount,
        initialState: { pageIndex: 0 },
        manualPagination: true
      },
      useSortBy,
      useExpanded,
      usePagination
    );

  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  function renderPreloader(preloaderCount: number) {
    return new Array(preloaderCount).fill(null).map((_, index) => (
      <tr key={`row-preloader-${index}`}>
        {columns.map((col, i) => (
          <td
            key={`col-preloader-${i}`}
            className={`pr-6 py-4 bg-white-default ${
              i === 0 ? "pl-6" : ""
            } border-b border-gray-200`}
          >
            <Skeleton height={12} width={`${generateBetween(50, 100)}%`} />
          </td>
        ))}
      </tr>
    ));
  }

  const handleRowClick = (e: any, row: any) => {
    switch (dataType) {
      case "express-orders":
        dispatch.UI.setCurrentRoute("/order-list");
        history.push(`/order-list/${row?.original?.orderId}`);
        break;
      case "express-orders-completed":
        dispatch.UI.setCurrentRoute("/order-list-completed");
        history.push(`/order-list-completed/${row?.original?.orderId}`);
        break;
      default:
        return "";
    }
  };

  return (
    <>
      <BTable
        striped
        bordered
        hover
        size="lg"
        className="table-list"
        responsive
        {...getTableProps()}
      >
        <thead className="table-list-header">
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-list-header__row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="table-list-header__row-cell"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {isLoading && (
          <tbody className="table-list-body" {...getTableBodyProps()}>
            {renderPreloader(generateBetween(2, 6))}
          </tbody>
        )}

        {!isLoading && (
          <tbody className="table-list-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const lastRow = row?.cells?.length - 1;
              return (
                <>
                  <tr
                    className="table-list-body__row"
                    {...row.getRowProps()}
                    style={{ cursor: showOrderDetails ? "pointer" : "unset" }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          className="table-list-body__row-cell"
                          onClick={(e) =>
                            lastRow !== index ? handleRowClick(e, row) : ""
                          }
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        )}
      </BTable>
    </>
  );
}

function Table(props: any) {
  return <RTable {...props}> </RTable>;
}

export default Table;
