import * as React from "react";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  position?: "relative" | "absolute" | "top";
  margin?: "m-2" | "none" | "mt-1.5" | "my-auto" | "mb-8";
  padding?:
    | "pr"
    | "pt"
    | "py-1"
    | "py-2"
    | "py-1.5"
    | "none"
    | "px-2"
    | "p-2.5"
    | "px-1.5"
    | "p-1.5"
    | "px-6"
    | "px-4"
    | "normal"
    | "padding-35";
  border?: "bottom" | "left" | "dashed" | "bottom-white" | "grey" | "orange";
  color?:
    | "default"
    | "green"
    | "darkgreen"
    | "grey"
    | "darkgrey"
    | "lightgrey"
    | "white"
    | "red"
    | "darkred"
    | "violet-green"
    | "orange"
    | "black"
    | "blackgrey"
    | "orange-two";
  size?: "4xl" | "3xl" | "2xl" | "xl" | "lg" | "base" | "xs";
  weight?: "medium" | "semi" | "bold";
  index?: "10";
  font?: "roboto" | "montserrat";
  bg?: "green" | "orange" | "violet";
  radius?: "3xl" | "md";
  letters?: "wider" | "widest";
  uppercase?: boolean;
  transform?: "uppercase" | "capitalize";
  width?: "none";
  align?: "right";
  whitespace?: "nowrap" | "normal";
  className?: string;
};

let getBg = (bg: Props["bg"]) => {
  switch (bg) {
    case "violet":
      return "bg-getviolet-2";
    case "orange":
      return "bg-getorange-4";
    case "green":
      return "bg-getgreen-4";
    default:
      return "";
  }
};

let getFont = (font: Props["font"]) => {
  switch (font) {
    case "montserrat":
      return "fontFamily-montserrat";
    case "roboto":
      return "font-roboto";
    default:
      return "";
  }
};

let getRadius = (radius: Props["radius"]) => {
  switch (radius) {
    case "3xl":
      return "rounded-3xl";
    case "md":
      return "rounded-md";
    default:
      return "rounded-none";
  }
};

let getPosition = (position: Props["position"]) => {
  switch (position) {
    case "top":
      return "absolute inset-x-0 top-0";
    case "absolute":
      return "absolute";
    case "relative":
      return "relative";
    default:
      return "";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {
    case "my-auto":
      return "my-auto";
    case "none":
      return "m-0";
    case "m-2":
      return "m-2";
    case "mt-1.5":
      return "mt-1.5";
    case "mb-8":
      return "mb-8";
    default:
      return "m-auto";
  }
};

let getPadding = (padding: Props["padding"]) => {
  switch (padding) {
    case "pt":
      return "pt-2.5 pb-1.5";
    case "pr":
      return "pr-2.5 py-2";
    case "py-1.5":
      return "py-1.5";
    case "px-1.5":
      return "px-1.5";
    case "padding-35":
      return "padding-35";
    case "p-1.5":
      return "p-1.5";
    case "px-2":
      return "px-2";
    case "none":
      return "";
    case "normal":
      return "py-2 px-1.5";
    case "p-2.5":
      return "p-2.5";
    case "px-4":
      return "py-2 px-4";
    case "px-6":
      return "py-2 px-6";
    case "py-2":
      return "py-2";
    case "py-1":
      return "py-1";
    default:
      return "p-2";
  }
};

let getColor = (color: Props["color"]) => {
  switch (color) {
    case "red":
      return "text-getred-1";
    case "white":
      return "text-white-1";
    case "darkgrey":
      return "text-getgrey-6";
    case "lightgrey":
      return "text-getgrey-2";
    case "grey":
      return "text-getgrey-5";
    case "violet-green":
      return "text-getviolet-1 hover:text-getgreen-4";
    case "darkred":
      return "text-getred-4";
    case "darkgreen":
      return "text-getgreen-3";
    case "green":
      return "text-getgreen-1";
    case "orange":
      return "text-getorange-2";
    case "orange-two":
      return "text-getorange-5";
    case "black":
      return "text-getblack-2";
    case "blackgrey":
      return "text-getgrey-6";
    default:
      return "text-getblack-1";
  }
};

let getBorder = (border: Props["border"]) => {
  switch (border) {
    case "dashed":
      return "border-dashed border-b border-getgrey-1";
    case "left":
      return "border-solid border-r border-black";
    case "bottom-white":
      return "border-b-4 border-solid border-white";
    case "bottom":
      return "border-solid border-b border-getgrey-1";
    case "orange":
      return "border-maincolor";
    case "grey":
      return "border border-solid border-getgrey-4";
    default:
      return "";
  }
};

let getFontSize = (size: Props["size"]) => {
  switch (size) {
    case "4xl":
      return "text-4xl";
    case "3xl":
      return "text-3xl";
    case "2xl":
      return "text-2xl";
    case "xl":
      return "text-xl";
    case "lg":
      return "text-lg";
    case "base":
      return "text-base";
    case "xs":
      return "text-xs";
    default:
      return "text-sm";
  }
};

let getFontWeight = (weight: Props["weight"]) => {
  switch (weight) {
    case "bold":
      return "font-bold";
    case "semi":
      return "font-semibold";
    case "medium":
      return "font-medium";
    default:
      return "font-normal";
  }
};

let getZindex = (index: Props["index"]) => {
  switch (index) {
    case "10":
      return "z-10";
    default:
      return "";
  }
};

let getLetterSpacing = (letters: Props["letters"]) => {
  switch (letters) {
    case "widest":
      return "tracking-widest";
    case "wider":
      return "tracking-wider";
    default:
      return "tracking-normal";
  }
};

let getTransform = (transform: Props["transform"]) => {
  switch (transform) {
    case "capitalize":
      return "capitalize";
    case "uppercase":
      return "uppercase";
    default:
      return "normal-case";
  }
};

let getWidth = (width: Props["width"]) => {
  switch (width) {
    case "none":
      return "";
    default:
      return "w-full";
  }
};

let getAlign = (align: Props["align"]) => {
  switch (align) {
    case "right":
      return "text-right";
    default:
      return "text-left";
  }
};

let getWhitespace = (whitespace: Props["whitespace"]) => {
  switch (whitespace) {
    case "normal":
      return "whitespace-normal";
    case "nowrap":
      return "whitespace-nowrap";
    default:
      return "";
  }
};

export const Span = ({
  position,
  margin,
  padding,
  border,
  color,
  size,
  weight,
  index,
  children,
  font,
  bg,
  whitespace,
  radius,
  align,
  width,
  transform,
  letters,
  className
}: Props) => {
  return (
    <span
      className={classnames(
        getPosition(position),
        getMargin(margin),
        getPadding(padding),
        getBorder(border),
        getColor(color),
        getFontSize(size),
        getFontWeight(weight),
        getZindex(index),
        getFont(font),
        getBg(bg),
        getRadius(radius),
        getLetterSpacing(letters),
        getTransform(transform),
        getWidth(width),
        getAlign(align),
        getWhitespace(whitespace),
        className
      )}
    >
      {children}
    </span>
  );
};
