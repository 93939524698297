import { Models } from "@rematch/core";

import { User } from "./UserModel"; 
import { Order } from "./OrderModel";  
import { Parcels } from "./ParcelModel";
import { Locations } from "./LocationsModel";
import { OrderConfirmation } from "./OrderConfirmation";
import { OrderList } from "./OrderList"; 
import { UI } from "./UIModel";
import { WalletList } from "./WalletList";
import { Wallet } from "./WalletModel";
import { RiderforADayModel } from "./RiderforADayModel";
import { RentalList } from "./RentalList"; 

export interface RootModel extends Models<RootModel> {
  User: typeof User;
  Order: typeof Order;   
  Parcels: typeof Parcels;   
  Locations: typeof Locations;
  OrderConfirmation: typeof OrderConfirmation;
  OrderList: typeof OrderList;  
  RentalList: typeof RentalList;
  UI: typeof UI;
  WalletList: typeof WalletList;
  Wallet: typeof Wallet;
  RiderforADayModel: typeof RiderforADayModel;
}

export const models: RootModel = {
  User,
  Order,  
  Parcels,
  Locations,
  OrderConfirmation,
  OrderList, 
  UI,
  WalletList,
  Wallet,
  RiderforADayModel,
  RentalList
};
