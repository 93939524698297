import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../../store";

import { Span } from '../../../../common/Span';
import { Select } from '../../../../common/Select';   
import clock from '../../../../assets/img/rental/clock_icon.png';

const ScheduleType = () => {
    
    const dispatch = useDispatch<Dispatch>();
    const { scheduleType }: any = useSelector((state: RootState) => state.RiderforADayModel); 

    return (<>
    <div className='px-3 py-4'>
        <Span color="orange" padding='none'>Schedule Type</Span> 
        <div className="flex py-1">
            <img src={clock} className="my-auto mx-2" style={{ height: 'auto', width: 20 }} alt="" />
            
            <Select  
            className="w-full text-sm font-normal p-2 rounded-md border border-solid border-getgrey-4 cursor-pointer"
            width='full'
            value={scheduleType}
            onChange={(e: any) => {
                dispatch.RiderforADayModel.updateState({
                    scheduleType: e.target?.value
                })
            
                dispatch.RiderforADayModel.updateState({
                    scheduleMethod: "0"
                })  
            }}   
            >
                <option value="" selected disabled hidden> Select Schedule Type </option> 
                <option value='wholeday'> Whole Day </option> 
                <option value='halfday'> Half Day </option> 
            </Select>
        </div>
    </div>
    </>);
};

export default ScheduleType;