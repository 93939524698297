/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { Span } from "../../../../common/Span";
import PlacesAutocomplete from "react-places-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../../store";
import { analyticEvent, Event } from "../../../../customHooks/analytics";
import { getPlaceDetails } from "../../../../common/api";

const PickupLocationAutocomplete = () => {
  const { pickup } = useSelector((state: RootState) => state.RiderforADayModel);

  const [address, setAddress] = useState({
    address: pickup?.locationAddress
  }) as any;
  const [oldAddress, setOldAddress] = useState<any>({
    oldAddress: "",
    newAddress: address
  });

  const dispatch = useDispatch<Dispatch>();

  const handleChange = (address) => {
    setOldAddress({ oldAddress: oldAddress.newAddress, newAddress: address });
    setAddress({ address });
  };

  const handleSelect = async (address, placeId) => {
    analyticEvent(Event.RentalSearchedPickup, {
      searched_location: oldAddress.newAddress
    });
    if (!placeId) {
      return;
    }
    let place = await getPlaceDetails(placeId);
    analyticEvent(Event.RentalSelectedPickup, { selected_location: address });

    let location = {
      lat: place?.result?.geometry?.location?.lat,
      lng: place?.result?.geometry?.location?.lng,
      address
    };

    dispatch.Locations.setLatLng({
      lat: location?.lat,
      lng: location.lng,
      index: 0
    });
    dispatch.RiderforADayModel.updateState({
      pickup: { locationAddress: address, location: location }
    });
    setAddress({ address });
  };

  return (
    <div className="w-full">
      <PlacesAutocomplete
        value={address?.address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className="relative">
            <input
              {...getInputProps({
                placeholder: "",
                className: "loc-address"
              })}
            />
            <div className="absolute z-10 w-full shadow-md">
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#f2f2f2" : "#ffffff",
                  cursor: "pointer"
                };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    <div className="text-left p-2 border-b border-themegrey-400">
                      <Span padding="none">{suggestion.description}</Span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default PickupLocationAutocomplete;
