/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch } from "react-redux";
import { Dispatch } from "../../../../store";
import { useEffect, useState } from "react";
import { Span } from "../../../../common/Span";

import firebase from "../../../../Firebase";
import {
  formatDateStartEnd,
  formatDateStartEndHalfday
} from "../../../../common/utils";
import { RENTALSTATUS } from "../../../../constants";
import AttachmentViewModal from "./AttachmentViewModal";
import "./Content.scss";

const ScheduleInformation = ({ rentals }: any) => {
  const { id } = rentals;
  const dispatch = useDispatch<Dispatch>();

  const [rentalItems, setRentalItems] = useState([]) as any;

  const getRentalItems = async () => {
    let db = firebase.firestore();
    var unsubscribe = db
      .collection("rental_items")
      .where("referenceNumber", "==", id)
      .onSnapshot((doc) => {
        const listData = (doc?.docs || [])
          .map((ref) => {
            const data = ref.data();
            return data;
          })
          .sort((a: any, b: any) => {
            a = new Date(a?.scheduleStartTime);
            b = new Date(b?.scheduleStartTime);
            return a - b;
          }) as any[];
        setRentalItems(listData);
      });
  };
  useEffect(() => {
    if (id) {
      getRentalItems();
    }
  }, [id]);

  const [viewAttachmentModal, setViewAttachmentModal] = useState(false);

  return (
    <>
      <div className="mt-4">
        <Span color="lightgrey" weight="semi" size="base">
          Schedule & Driver Information
        </Span>
        <span className="schedule-highlight">{rentals?.scheduleType}</span>
        <ul className="timeline">
          {rentalItems
            ?.sort(
              (a, b) =>
                a?.scheduleStartTime?.seconds - b?.scheduleStartTime?.seconds
            )
            ?.map((item, index) => {
              const isThirdparty = item?.thirdPartyDriver ? true : false;
              const assignedDriver = isThirdparty
                ? item?.thirdPartyDriver
                : item?.driver;
              const name =
                assignedDriver?.contactName ??
                `${assignedDriver?.driverProfile?.lastName}, ${assignedDriver?.driverProfile?.firstName}`;
              const phoneNumber =
                assignedDriver?.contactNumber ??
                assignedDriver?.phoneNumber?.replace("+63", "");
              return (
                <li key={index}>
                  <Span weight="semi" padding="none" transform="capitalize">
                    {item?.scheduleType === "halfday"
                      ? formatDateStartEndHalfday(
                          item?.scheduleStartTime?.seconds
                        )
                      : formatDateStartEnd(item?.scheduleStartTime?.seconds)}
                  </Span>
                  <ul>
                    {item?.status != "pending" && item?.status != "canceled" ? (
                      isThirdparty ? (
                        <>
                          <li>
                            <div className="my-2">
                              <span className="thirdparty">
                                3rd Party Driver
                              </span>
                            </div>
                          </li>

                          <li>
                            <Span transform="capitalize">
                              <strong>Driver Name: </strong>
                              {item?.thirdPartyDriver.contactName}
                            </Span>
                          </li>
                          <li>
                            <Span transform="capitalize">
                              <strong>Driver Ctc#: </strong>
                              {item?.thirdPartyDriver.contactNumber}
                            </Span>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <Span transform="capitalize">
                              <strong>Driver Name: </strong>
                              {name.includes(undefined) ? "No Data" : name}
                            </Span>
                          </li>
                          <li>
                            <Span>
                              <strong>Driver Ctc#:</strong>{" "}
                              {!phoneNumber ? "No Data" : "+63" + phoneNumber}
                            </Span>
                          </li>
                        </>
                      )
                    ) : (
                      <></>
                    )}

                    <li>
                      <Span>Status:</Span>
                      <span className={`status_${item?.status} status_label`}>
                        {RENTALSTATUS[item?.status]}
                      </span>
                    </li>
                    {item?.status === "canceled" ? (
                      <li>
                        <Span transform="capitalize">
                          <strong>Cancel Reason:</strong>{" "}
                          {item?.cancelReason ?? "No Data"}
                        </Span>
                      </li>
                    ) : (
                      <></>
                    )}

                    {item?.status != "pending" ? (
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch.RiderforADayModel.updateState({
                            scheduleId: item.id
                          });
                          setViewAttachmentModal(true);
                        }}
                        className="cursor-pointer my-auto"
                      >
                        <Span weight="semi" color="orange" uppercase>
                          View Attachment
                        </Span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </ul>
                </li>
              );
            })}
        </ul>
      </div>
      {viewAttachmentModal && (
        <AttachmentViewModal
          setViewAttachmentModal={setViewAttachmentModal}
          id={id}
        />
      )}
    </>
  );
};

export default ScheduleInformation;
