import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { lazy, Suspense } from "react";

const GoogleMap = lazy(() => import("./Google"));
const LeafletMap = lazy(() => import("./Leaflet"));

export default function Map() {
  const mapType = useSelector((state: RootState) => state.Order?.mapType);

  return (
    <Suspense fallback={<></>}>
      {mapType === "GOOGLE" && <GoogleMap />}
      {mapType === "LEAFLET" && <LeafletMap />}
    </Suspense>
  );
}
