import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store";
import { Col } from "react-bootstrap";

import "./ScheduleSelection.scss";
import moment from "moment";
import CalendarIconActive from "../../../assets/img/web/scheduled-icon-black.png";
import ClockIconActive from "../../../assets/img/web/pickupnow-icon-black.png"; 

import { Button } from "../../../common/Button"; 

const ScheduleSelection = ({
  setIsDatePickerShown,
  isScheduled,
  isInstant
}: any) => {
  const dispatch = useDispatch<Dispatch>();
  const { schedule, scheduleType, isScheduledSelected, vehicleDescription } = useSelector(
    (state: RootState) => state.Order
  ); 

  const isHelperSelected: any = useSelector(
    (state: RootState) => state.Order.isHelperSelected
  );
   
  return (
    <>
      <div
        className="mt-3 mx-3"
        style={{ backgroundColor: "#DDDDDD", borderRadius: 5 }}
      >
        <div className="d-flex flex-row">
          <Col lg="6" className="px-0">
            <Button
              radius="r-none"
              border="grey"
              bg={scheduleType === "2" && isScheduledSelected ? "orange" : "grey"}
              onClick={() => {
                dispatch.Order.updateState({
                  scheduleType: "2",
                  isScheduledSelected: true
                });
              }}
              disabled={!isInstant || !vehicleDescription}
            >
              <img
                src={ClockIconActive}
                className="m-auto py-1"
                style={{ height: "auto", width: 20 }}
                alt=""
              />
              <span className="text-getblack-1">Pickup Now</span>
            </Button>
          </Col>
          <Col lg="6" className="px-0">
            <Button
              radius="l-none"
              bg={scheduleType === "1" && isScheduledSelected ? "orange" : "grey"}
              border="grey"
              onClick={() => {
                dispatch.Order.updateState({
                  scheduleType: "1",
                  isScheduledSelected: true
                });
                setIsDatePickerShown(true);
              }}
              disabled={!isScheduled || !vehicleDescription}
            >
              <img
                src={CalendarIconActive}
                className="m-auto py-1"
                style={{ height: "auto", width: 19 }}
                alt=""
              />
              <span className="text-getblack-1">Scheduled</span>
            </Button>
          </Col>
        </div>
        {scheduleType === "1" &&  isScheduledSelected ? (
          <div className="schedule-selection__date d-flex justify-content-center">
            <strong>
              Pick up on {moment(schedule).format("MMMM D, YYYY (ddd) h:mm A")}
            </strong>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ScheduleSelection;
