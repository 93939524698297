/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { Dispatch, RootState } from "../../store";
import { findMyOrder } from "../../common/api/endpoints/orderlist";
import "./RecentTransaction.scss";
import LocationIcon from "../../assets/img/web/pinlocation_map.png";

import { Spinner } from "react-bootstrap";
import _ from "lodash";

interface IRecentTransactionLocation {
  contactPhone: string;
  contactName: string;
  itemCategory?: string;
  deliveryInstruction?: string;
  locationAddress: string;
  location: {
    lat: number;
    lng: number;
    address: string;
  };
}

const TransactionList = ({
  loading,
  onScroll,
  listInnerRef,
  data,
  locationIndex,
  setIndexClicked,
  setIsLoading
}) => {
  const dispatch = useDispatch<Dispatch>();

  const { locations } = useSelector((state: RootState) => state.Locations);

  useEffect(() => {
    if (!data.length && !loading) {
      setIsLoading(true);
    }
  }, []);

  const renderLoading = () => {
    return (
      <div className="order-loading">
        <Spinner animation="border" role="status" className="spinner m-auto">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  };

  return (
    <>
      <div
        onScroll={onScroll}
        ref={listInnerRef}
        className="suggestion-container space-y-3"
      >
        {!loading ? (
          data.map((item, idx) => {
            return item?.locationAddress ? (
              <div
                key={idx}
                className="d-flex align-content-center pointer suggestion-items hover:bg-gray-200"
              >
                <div className="p-3">
                  <img src={LocationIcon} alt="" />
                </div>

                <div className="text-left">
                  <div
                    className="group flex flex-col my-3 cursor-pointer transform transition duration-200"
                    onClick={() => {
                      dispatch.Locations.updateState({
                        targetLocationIndex: locationIndex
                      });

                      let newLocations = locations.map((loc, i) => {
                        if (i === locationIndex) {
                          loc.locationAddress = item?.locationAddress;
                          // loc.showPopOver = false;
                          loc.showPopOver = true;
                          loc.isSelected = true;
                          loc.lat = item?.location?.lat;
                          loc.lng = item?.location?.lng;
                          loc.contactName = item?.contactName;
                          loc.contactPhone = item?.contactPhone.replace(
                            "+63",
                            ""
                          );
                          loc.location!.lat = item?.location!.lat;
                          loc.location!.lng = item?.location!.lng;
                          loc.locationDetails = item?.location?.address;
                          loc.deliveryInstruction =
                            item?.deliveryInstruction ?? null;
                          loc.itemCategory = item?.itemCategory ?? null;
                        }
                        return { ...loc };
                      });

                      dispatch.Locations.updateState({
                        locations: [...newLocations]
                      });
                      dispatch.Order.updateState({ orderModified: true });
                      dispatch.Order.setLastUpdate(moment.now());

                      if (locationIndex !== 0) {
                        dispatch.Order.calculateFare();
                      }
                      setIndexClicked(false);
                    }}
                    key={idx}
                    >
                    {item?.locationAddress ? (
                      <span className="text-sm">{item?.locationAddress}</span>
                    ) : (
                      <></>
                    )}
                    {item?.location?.address ? (
                      <span className="text-xs font-thin">
                        Location Details : {item?.location?.address}
                      </span>
                    ) : (
                      <></>
                    )}

                    {item?.contactName ? (
                      <span className="text-xs font-thin">
                        Name : {item?.contactName}
                      </span>
                    ) : (
                      <></>
                    )}

                    {item?.contactPhone ? (
                      <span className="text-xs font-thin">
                        Number : {item?.contactPhone}
                      </span>
                    ) : (
                      <></>
                    )}

                    {locationIndex !== 0 && item?.itemCategory ? (
                      <span className="text-xs font-thin">
                        Items : {item?.itemCategory}
                      </span>
                    ) : (
                      <></>
                    )}

                    {locationIndex !== 0 && item?.deliveryInstruction ? (
                      <span className="text-xs font-thin">
                        Delivery Instruction : {item?.deliveryInstruction}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            );
          })
        ) : (
          <>{renderLoading()}</>
        )}
      </div>
    </>
  );
};

const RecentTransaction = ({ index, setIndexClicked }) => {
  const listInnerRef = useRef(null);

  const [loading, setIsLoading] = useState(true);
  const [data, setData] = useState<(IRecentTransactionLocation)[]>([]);
  const [lastList, setLastList] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [prevPageIndex, setPrevPageIndex] = useState(0);

  const { locations } = useSelector((state: RootState) => state.Locations);

  useEffect(() => {
    const fetchData = async () => {
      const { results } = await findMyOrder({
        status: "delivered",
        pageIndex,
        pageSize: 10
      });

      if (!results.length) {
        setLastList(true);
        setIsLoading(false);
        return;
      }

      if (index === 0) {
        const pickUpData: IRecentTransactionLocation[] = results?.map((r) => {
          const pickUp = { ...r?.delivery?.pickup };
          return pickUp;
        });

        setData([...data, ...pickUpData]);
      } else {
        const stopsResults: IRecentTransactionLocation[] = [];
        const dropOffLocations = _.drop(locations);
        results?.map((s) =>
          s?.delivery?.stops.forEach((s: any) => {
            const isDropOffUsed = dropOffLocations.some(
              (loc) => loc.locationAddress === s.locationAddress
            );

            if (!isDropOffUsed) stopsResults.push({ ...s });
          })
        );

        setData([...data, ...stopsResults]);
      }
      setPrevPageIndex(pageIndex);
      setIsLoading(false);
    };

    if (!lastList && prevPageIndex !== pageIndex) {
      fetchData().catch(() => {
        setIsLoading(false);
      });
    }
  }, [pageIndex, lastList, prevPageIndex]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current!;
      if (scrollTop + clientHeight === scrollHeight) {
        setPageIndex((prev) => prev + 1);
      }
    }
  };

  return (
    <TransactionList
      loading={loading}
      onScroll={onScroll}
      listInnerRef={listInnerRef}
      data={data}
      locationIndex={index}
      setIndexClicked={setIndexClicked}
      setIsLoading={setIsLoading}
    />
  );
};

export default RecentTransaction;
