/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../store";

export const useMapWorker = () => {
  const dispatch = useDispatch<Dispatch>();

  // Ctrl + M
  // Toggles Map UI
  const keyListener = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.code === "KeyM") {
      dispatch.Order.toggleMap();
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", keyListener);

    return () => {
      window.removeEventListener("keypress", keyListener);
    };
  }, []);
};
