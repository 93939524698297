import { useState } from "react";
import { Col, Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";

// components - scss
import Autocomplete from "../../../components/Autocomplete/Autocomplete";
import "./Location.scss";

// images
import XButton from "../../../assets/img/x-buttonb.png";
import AdditionalStopIcon from "../../../assets/img/web/addstop-icon.png";
import StopIcon from "../../../assets/img/web/stop-icon.png";
import PickUpLocationIcon from "../../../assets/img/web/pickup-icon.png";
import DropOffIcon from "../../../assets/img/web/dropoff-icon.png";
import DeleteLocationIcon from "../../../assets/img/web/remove-icon.png";

import { Input } from "../../../common/Input";
import { Span } from "../../../common/Span";
import { Button } from "../../../common/Button";
import { InputContainer } from "../../../common/Container";
import { Textarea } from "../../../common/Textarea";

import { RootState, Dispatch } from "../../../store";
import {
  checkLocationIsAllowed,
  optimizeRoutes,
  onlyNumberKey
} from "../../../common/utils";
import { ILocation } from "../../../types/ILocations";
import RecentTransaction from "../../../components/RecentTransaction/RecentTransaction";

let truncateString = (text, length) => {
  text = text ?? "";
  if (text.length < length) {
    return text;
  }
  return text.substring(0, length) + "...";
};

const Location = () => {
  const dispatch = useDispatch<Dispatch>();

  const [isIndexClicked, setIndexClicked] = useState(false);

  const indexOnClick = () => {
    setIndexClicked(!isIndexClicked);
  };

  const { locations, targetLocationIndex } = useSelector(
    (state: RootState) => state.Locations
  );

  const { tripType, isOptimizeLocation, optimizeBasedLocations } = useSelector(
    (state: RootState) => state.Order
  );

  const extraHandleSelect = (locationAddress: any, finRes: any) => {
    let newLocations = locations.map((location, index) => {
      if (index === targetLocationIndex) {
        if (location.lat !== finRes.lat && location.lng !== finRes.lng) {
          dispatch.Order.updateState({ isOptimizeLocation: false });
        }

        location.locationAddress = locationAddress;
        location.showPopOver = true;
        location.lat = finRes.lat;
        location.lng = finRes.lng;
        location.isSelected = true;
      }

      return { ...location };
    });

    if (
      !newLocations.every((location) => {
        if (!location.lat && !location.lng) return true;
        return checkLocationIsAllowed(location.lat, location.lng);
      })
    ) {
      dispatch.UI.setAlert({
        message: `Cannot place pin to this location.`,
        type: "error"
      });
      const targetIndex = Number(targetLocationIndex);
      if (locations[targetIndex]) {
        newLocations[targetIndex] = locations[targetIndex];
        newLocations[targetIndex].locationAddress = "";
        newLocations[targetIndex].lat = 0;
        newLocations[targetIndex].lng = 0;
      }
    }

    dispatch.Locations.updateState({ locations: [...newLocations] });
    dispatch.Order.updateState({ orderModified: true });
    dispatch.Order.setLastUpdate(moment.now());
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = [...locations];
    const [reorderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderItem);

    dispatch.Locations.updateState({
      locations: [...items.map((elem) => ({ ...elem }))]
    });
    dispatch.Order.updateState({ orderModified: true });
    dispatch.Order.setLastUpdate(moment.now());
  };

  return (
    <>
      <div className="m-3">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="locations">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {locations.map((location: ILocation, index: number) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <OverlayTrigger
                          show={location.showPopOver}
                          placement="right"
                          overlay={
                            <Popover
                              id="popover-basic"
                              className="destination-pop-over-style"
                            >
                              <Button
                                className="pop-over-x-button bg-transparent p-2"
                                onClick={() => {
                                  let newLocations = locations.map(
                                    (location, i) => {
                                      if (i === index) {
                                        location.showPopOver = false;
                                      }
                                      return { ...location };
                                    }
                                  );

                                  dispatch.Locations.updateState({
                                    locations: [...newLocations]
                                  });
                                }}
                              >
                                <img
                                  src={XButton}
                                  style={{ width: 30, height: 30 }}
                                  alt=""
                                />
                              </Button>
                              <div className="py-2 px-4  bg-getorange-2">
                                <Form className="mt-3">
                                  <Form.Group controlId="locationDetails">
                                    <Form.Label className="text-white h6">
                                      <p>Location Details</p>
                                    </Form.Label>

                                    <Textarea
                                      value={
                                        locations[index].locationDetails || ""
                                      }
                                      onChange={(e: any) => {
                                        let newLocations = locations.map(
                                          (location, i) => {
                                            if (i === index) {
                                              location.locationDetails =
                                                e.target.value;
                                            }
                                            return { ...location };
                                          }
                                        );

                                        dispatch.Locations.updateState({
                                          locations: [...newLocations]
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                  <Form.Group controlId="contactName">
                                    <Form.Label className="text-white h6">
                                      <p>Name</p>
                                    </Form.Label>
                                    <Input
                                      width="full"
                                      radius="md"
                                      value={locations[index].contactName || ""}
                                      onChange={(e: any) => {
                                        let newLocations = locations.map(
                                          (location, i) => {
                                            if (i === index) {
                                              location.contactName =
                                                e.target.value;
                                            }
                                            return { ...location };
                                          }
                                        );

                                        dispatch.Locations.updateState({
                                          locations: [...newLocations]
                                        });
                                      }}
                                    />
                                  </Form.Group>
                                  <Form.Group controlId="contactPhone">
                                    <Form.Label className="text-white h6">
                                      Number
                                    </Form.Label>
                                    <InputContainer padding="none">
                                      <Span
                                        weight="semi"
                                        position="absolute"
                                        padding="p-2.5"
                                        border="left"
                                        width="none"
                                      >
                                        +63
                                      </Span>
                                      <Input
                                        type="phone"
                                        maxLength={10}
                                        minLength={10}
                                        width="full"
                                        radius="md"
                                        padding="pl-5"
                                        margin="none"
                                        onKeyPress={onlyNumberKey}
                                        value={
                                          locations[index].contactPhone || ""
                                        }
                                        onChange={(e: any) => {
                                          let newLocations = locations.map(
                                            (location, i) => {
                                              if (i === index) {
                                                location.contactPhone =
                                                  e.target.value;
                                              }
                                              return { ...location };
                                            }
                                          );

                                          dispatch.Locations.updateState({
                                            locations: [...newLocations]
                                          });
                                        }}
                                      />
                                    </InputContainer>
                                    {locations[index].contactPhone.length !==
                                      0 &&
                                    locations[index].contactPhone.length !==
                                      10 ? (
                                      <p>Invalid Phone Number!</p>
                                    ) : (
                                      ""
                                    )}
                                  </Form.Group>
                                  {index > 0 ? (
                                    <>
                                      <Form.Group controlId="itemCategory">
                                        <Form.Label className="text-white h6">
                                          <p>Items</p>
                                        </Form.Label>
                                        <Textarea
                                          value={
                                            locations[index].itemCategory || ""
                                          }
                                          onChange={(e: any) => {
                                            let newLocations = locations.map(
                                              (location, i) => {
                                                if (i === index) {
                                                  location.itemCategory =
                                                    e.target.value;
                                                }
                                                return { ...location };
                                              }
                                            );

                                            dispatch.Locations.updateState({
                                              locations: [...newLocations]
                                            });
                                          }}
                                        />
                                      </Form.Group>
                                      <Form.Group controlId="deliveryInstruction">
                                        <Form.Label className="text-white h6">
                                          <p>Delivery Instruction</p>
                                        </Form.Label>
                                        <Textarea
                                          value={
                                            locations[index]
                                              .deliveryInstruction || ""
                                          }
                                          onChange={(e: any) => {
                                            let newLocations = locations.map(
                                              (location, i) => {
                                                if (i === index) {
                                                  location.deliveryInstruction =
                                                    e.target.value;
                                                }
                                                return { ...location };
                                              }
                                            );

                                            dispatch.Locations.updateState({
                                              locations: [...newLocations]
                                            });
                                          }}
                                        />
                                      </Form.Group>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Form>

                                <p className="text-center text-white p-1">
                                  Note: All details are required
                                </p>
                              </div>
                              <div className="popover-bg"></div>
                            </Popover>
                          }
                        >
                          <div key={index}>
                            <div className="d-flex flex-row align-items-center py-2 position-relative">
                              <div className="location-wrapper">
                                <div className="location-icon">
                                  <img
                                    src={
                                      index === 0
                                        ? PickUpLocationIcon
                                        : index === locations.length - 1
                                        ? `${
                                            tripType === 1
                                              ? DropOffIcon
                                              : StopIcon
                                          }`
                                        : StopIcon
                                    }
                                    style={
                                      index === locations.length - 1
                                        ? { height: 20, width: 20 }
                                        : {}
                                    }
                                    alt=""
                                  />
                                  {index === 0 ||
                                  index === locations.length - 1 ? (
                                    tripType === 2 &&
                                    index === locations.length - 1 ? (
                                      <strong className="location-icon__text">
                                        {index}
                                      </strong>
                                    ) : null
                                  ) : (
                                    <strong className="location-icon__text">
                                      {index}
                                    </strong>
                                  )}
                                </div>
                                <div
                                  className="flex-fill px-3"
                                  onClick={() => {
                                    dispatch.Locations.updateState({
                                      targetLocationIndex: index
                                    });
                                    let newLocations = locations.map(
                                      (location, i) => {
                                        if (index === i && location.lat !== 0) {
                                          location.showPopOver = true;
                                        } else {
                                          location.showPopOver = false;
                                        }
                                        return { ...location };
                                      }
                                    );

                                    dispatch.Locations.updateState({
                                      locations: [...newLocations]
                                    });
                                  }}
                                >
                                  <Autocomplete
                                    className="w-full"
                                    handleSelectedIndex={() => {
                                      indexOnClick();
                                    }}
                                    handleOnChange={(e) => {
                                      setIndexClicked(false);
                                      dispatch.Locations.updateState({
                                        targetLocationIndex: index
                                      });

                                      let newLocations = locations.map(
                                        (location, i) => {
                                          if (i === index) {
                                            location.locationAddress =
                                              e.target.value;
                                            location.showPopOver = false;
                                            location.isSelected = false;
                                            location.lat = 0;
                                            location.lng = 0;
                                          }
                                          return { ...location };
                                        }
                                      );

                                      dispatch.Locations.updateState({
                                        locations: [...newLocations]
                                      });
                                      dispatch.Order.updateState({
                                        orderModified: true
                                      });
                                    }}
                                    handleSelectAddedFunc={extraHandleSelect}
                                    value={location.locationAddress || ""}
                                    placeholder={
                                      index === 0
                                        ? "Pick Up Location"
                                        : index === locations.length - 1
                                        ? `${
                                            tripType === 1
                                              ? "Drop Off Location"
                                              : `Stop ${index}`
                                          }`
                                        : `Stop ${index}`
                                    }
                                  />
                                  <small
                                    className="small"
                                    style={{ color: "#a5a5a5" }}
                                  >
                                    {locations[index].contactName}

                                    {`${
                                      locations[index].contactName &&
                                      locations[index].contactPhone
                                        ? " | "
                                        : ""
                                    }`}
                                    {locations[index].contactPhone &&
                                      `+63${locations[index].contactPhone}`}
                                    {`${
                                      (locations[index].contactPhone ||
                                        locations[index].contactName) &&
                                      locations[index].locationDetails
                                        ? " | "
                                        : ""
                                    }`}
                                    {truncateString(
                                      locations[index].locationDetails,
                                      15
                                    )}
                                  </small>

                                  {index > 0 ? (
                                    <small
                                      className="small block"
                                      style={{ color: "#a5a5a5" }}
                                    >
                                      {truncateString(
                                        locations[index].itemCategory,
                                        25
                                      )}

                                      {`${
                                        locations[index].itemCategory &&
                                        locations[index].deliveryInstruction
                                          ? " | "
                                          : ""
                                      }`}
                                      {truncateString(
                                        locations[index].deliveryInstruction,
                                        25
                                      )}
                                    </small>
                                  ) : (
                                    <></>
                                  )}

                                  {locations[index].valid === false ? (
                                    <small
                                      className="small block"
                                      style={{ color: "#EE4B2B" }}
                                    >
                                      Please provide the required details.
                                    </small>
                                  ) : (
                                    <></>
                                  )}
                                  {locations[index].contactPhone.length !== 0 &&
                                  locations[index].contactPhone.length !==
                                    10 ? (
                                    <small
                                      className="small block"
                                      style={{ color: "#EE4B2B" }}
                                    >
                                      Invalid Phone Number!
                                    </small>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>

                              <div className="location-button-wrapper pr-1">
                                {locations.length > 2 &&
                                location.locationAddress.length === 0 ? (
                                  <Button
                                    width="none"
                                    bg="none"
                                    onClick={() => {
                                      let newLocations = [...locations];
                                      newLocations.splice(index, 1);

                                      dispatch.Locations.updateState({
                                        locations: [...newLocations]
                                      });
                                      dispatch.Order.setLastUpdate(
                                        moment.now()
                                      );
                                    }}
                                  >
                                    <img
                                      src={DeleteLocationIcon}
                                      className="mx-2 p-1"
                                      alt=""
                                      height={24}
                                      width={24}
                                    />
                                  </Button>
                                ) : null}
                              </div>

                              {!location.isSelected &&
                              location.locationAddress.length > 0 ? (
                                <div className="location-underline-active" />
                              ) : (
                                <div className="location-underline" />
                              )}

                              {isIndexClicked &&
                              targetLocationIndex === index ? (
                                <RecentTransaction
                                  index={targetLocationIndex}
                                  setIndexClicked={setIndexClicked}
                                />
                              ) : null}
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="mt-3 text-small">
          <Row>
            <Col className="px-0 justify-content-between align-items-center">
              {locations.length > 2 ? (
                <Form.Check
                  onChange={(e: any) => {
                    if (isOptimizeLocation === false) {
                      dispatch.Order.updateState({
                        optimizeBasedLocations: locations
                      });

                      dispatch.Locations.updateState({
                        locations: optimizeRoutes(locations)
                      });
                    } else {
                      if (optimizeBasedLocations) {
                        dispatch.Locations.updateState({
                          locations: optimizeBasedLocations.map((elem) => ({
                            ...elem
                          }))
                        });
                      }
                    }

                    dispatch.Order.setIsOptimizedLocation(!isOptimizeLocation);
                    dispatch.Order.setLastUpdate(moment.now());
                  }}
                  checked={isOptimizeLocation ? true : false}
                  type="checkbox"
                  label="Optimize Route"
                  className="location-checkbox"
                />
              ) : (
                <div />
              )}
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                bg="none"
                width="none"
                flex="flex"
                padding="px-2"
                onClick={() => {
                  let newLocations = [...locations];
                  newLocations.push({
                    locationDetails: "",
                    contactName: "",
                    lat: 0,
                    lng: 0,
                    locationAddress: "",
                    contactPhone: "",
                    showPopOver: false,
                    isSelected: false
                  });
                  // dispatch.Order.setLastUpdate(moment.now());
                  dispatch.Order.updateState({
                    optimizeBasedLocations: null,
                    isOptimizeLocation: false,
                    orderModified: true
                  });

                  dispatch.Locations.updateState({
                    locations: [...newLocations]
                  });
                }}
              >
                <img
                  className="m-1 p-0.5"
                  style={{ height: 23, width: 23 }}
                  src={AdditionalStopIcon}
                  alt=""
                />
                <Span color="orange" size="xl" padding="none">
                  Add Stop
                </Span>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Location;
