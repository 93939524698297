/* eslint-disable import/no-anonymous-default-export */
import { ModalContainer } from "../../../../common/Container";
import { Span } from "../../../../common/Span";

import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./TermsAndCondition.scss";
import { IoMdCloseCircle } from "react-icons/io";

type Props = {
  setTermsAndConditionModal: Function;
};

export default ({ setTermsAndConditionModal }: Props) => {
  const { quoteResult }: any = useSelector(
    (state: RootState) => state.RiderforADayModel
  );

  return (
    <>
      <ModalContainer width="modal-xs">
        <div className="py-4 whitespace-normal">
          <div className="text-center my-2.5 px-2">
            <Span size="2xl" padding="none" color="orange">
              Terms and Condition
            </Span>

            <div className="my-auto">
              <IoMdCloseCircle
                onClick={() => setTermsAndConditionModal(false)}
                className="modal_terms_close"
              />
            </div>
          </div>

          <div className="h-72 modal_terms_body">
            <Span padding="none">{quoteResult?.fare?.termsAndCondition}</Span>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
