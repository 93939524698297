/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import MainContainer from "../../components/MainContainer/MainContainer";
import VehicleSelection from "./VehicleSelection/VehicleSelection";
import AddOns from "./AddOns/AddOns";
import Price from "./Price/Price";
import Location from "./Location/Location";
import ScheduleSelection from "./ScheduleSelection/ScheduleSelection";
import SchedulePicker from "./SchedulePicker/SchedulePicker";

import { Button } from "../../common/Button";
import { Span } from "../../common/Span";
import { Link } from "react-router-dom";

import Papa from "papaparse";

import { State as OrderType } from "../../models/OrderModel";
import { RootState, Dispatch } from "../../store";
import "./Order.scss";
import moment from "moment";
import Map from "../../components/Map/Map";
import {
  getByLatLngAddressName,
  getConfiguresConstant,
  getVehicleType
} from "../../common/api";
import WalletBalance from "../../components/WalletBalance/WalletBalance";
import ConfigService from "../../components/ConfigService/ConfigService";
import PriceMobileResponsive from "./Price/PriceMobileResponsive";

const Order = () => {
  const [uploading, setUploading] = useState(false);

  const { Order, Locations } = useDispatch<Dispatch>();
  const { dropOff, pickup } = useSelector(
    (state: RootState) => state.Locations
  );
  const { vehicles } = useSelector((state: RootState) => state.Order);
  const { confirmedLocations } = useSelector(
    (state: RootState) => state.OrderConfirmation
  );

  const { addOns } = useSelector<RootState>(
    (state: RootState) => state.Order
  ) as OrderType;

  const [isDatePickerShown, setIsDatePickerShown] = useState<boolean>(false);
  const [loading, setIsLoading] = useState(false);

  const [configDataValue, setConfigData] = useState<boolean>(false);
  const getConfigures = async () => {
    setIsLoading(true);

    try {
      const config = await getConfiguresConstant();
      const configData = config.filter(
        (d: any) => d.name === "ENABLE_EXPRESS_SERVICE"
      );
      setConfigData(configData[0].value);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getConfigures();
  }, []);

  useEffect(() => {
    if (dropOff?.location?.lat == 0) {
      return;
    }

    if (pickup?.location?.lat == 0) {
      return;
    }
  }, [dropOff?.location?.lat, vehicles, addOns]);

  useEffect(() => {
    if (confirmedLocations?.length > 0) {
      Order.updateState({
        isFareDetailsLoading: false,
        totalFee: null,
        reference: null,
        orderModified: false
      });
    }

    Order.updateState({
      requestOrderResponse: null,
      isFareDetailsLoading: false,
      amountToBeCollected: 0,
      isRemitOrCollect: false,
      collectAmountFrom: ""
    });

    Order.resetAddOns()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFile = (e: any) => {
    setUploading(true);
    const files = e.target.files;
    if (files) {
      Papa.parse(files[0], {
        complete: async (results) => {
          let data_results: Array<any> = results.data.splice(1);

          let newLocations: Array<any> = [];

          for (let index = 0; index < data_results.length; index++) {
            const element = data_results[index];
            if (element.length >= 4) {
              let res = await getByLatLngAddressName(element[0]);

              let loc = res.results[0];
              let location = {
                locationAddress: loc.formatted_address,
                lat: loc.geometry.location.lat,
                lng: loc.geometry.location.lng,
                contactName: element[1],
                contactPhone: element[2],
                locationDetails: element[3],
                itemCategory: element[4] ?? null,
                deliveryInstruction: element[5] ?? null,
                showPopOver: false,
                isSelected: true
              };

              newLocations.push(location);
            }
          }

          if (newLocations.length > 1) {
            Locations.updateState({ locations: [...newLocations] });
            Order.setLastUpdate(moment.now());
            Order.updateState({ orderModified: true });
          }

          setUploading(false);
        }
      });
    }
  };

  const [vehicleDetails, setVehicleDetails] = useState([] as any);

  // Selected Vehicle
  const vehicle = vehicles?.find((v) => v?.isSelected);
  const selectedVehicleType = vehicle?.vehicleType;

  const getVehicle = async () => {
    try {
      const res = await getVehicleType();
      const activeVehicle = res.filter((d: any) => d.portalEnabled === true);
      setVehicleDetails(activeVehicle);
    } catch (err) {
      console.log(err);
    }
  };

  const vDetailType = vehicleDetails?.find(
    (d) => d?.code === selectedVehicleType
  );
  const vehicleDetailType = vDetailType ? vDetailType : "motorcycle";

  const portalDeliveryType = vehicleDetailType?.portalDeliveryType;
  const isInstant = portalDeliveryType?.includes("Instant");
  const isScheduled = portalDeliveryType?.includes("Scheduled");

  useEffect(() => {
    getVehicle();
  }, []);

  return (
    <>
      {configDataValue ? (
        <>
          <MainContainer className="pr-3.5 pl-0 w-100 overflow-hidden">
            {/* <WalletBalance /> */}

            <Row className="h-screen">
              <Col
                lg="4"
                className="px-0 position-relative left-column border-right"
              >
                <div className="order-container scrollable-container">
                  <VehicleSelection
                    vehicleDetails={vehicleDetails}
                    selectedVehicleType={selectedVehicleType}
                  />
                  <ScheduleSelection
                    setIsDatePickerShown={setIsDatePickerShown}
                    isInstant={isInstant}
                    isScheduled={isScheduled}
                  />

                  <div className="flex m-3">
                    <Link to="/file/Template.csv" target="_blank" download>
                      <Button
                        padding="p-2"
                        radius="none"
                        border="grey"
                        bg="white-only"
                      >
                        <Span>Bulk Template</Span>
                      </Button>
                    </Link>
                    <div className="mx-2.5">
                      <input
                        id="file"
                        value=""
                        type="file"
                        name="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={handleFile}
                      />
                      <label htmlFor="file" className="px-4">
                        {uploading ? "Processing..." : "Upload & Generate"}
                      </label>
                    </div>
                  </div>

                  <Location />
                  <AddOns />

                  <PriceMobileResponsive />
                </div>
                <Price />
                {isDatePickerShown && (
                  <SchedulePicker setIsDatePickerShown={setIsDatePickerShown} />
                )}
              </Col>
              <Col lg="8" className="p-0 position-relative map-section">
                <div className="map-view">
                  <Map />
                </div>
              </Col>
            </Row>
          </MainContainer>
        </>
      ) : (
        <MainContainer className="w-100 overflow-auto">
          <ConfigService loading={loading} />
        </MainContainer>
      )}
    </>
  );
};

export default Order;
