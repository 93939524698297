/* eslint-disable jsx-a11y/alt-text */
import { useHistory } from "react-router";
import { Span } from "../../../common/Span";

import "./RentalDetails.scss";
import moment from "moment";

import back from "../../../assets/img/web/back-icon.png";
import dropdown from "../../../assets/img/web/orderlistdropoff-icon.png";

import ScheduleInformation from "./Content/ScheduleInformation";
import PaymentMethod from "./Content/PaymentMethod";
import { useState } from "react";

import History from "./Modal/HistoryModal";
import { IoIosArrowForward } from "react-icons/io";
import { VEHICLE } from "../../../constants";

const RentalInfo = ({ rentals, rentalItems, orderCode, pathname }: any) => {
  const history = useHistory();
  const [historyModal, setHistoryModal] = useState(false);
  const handleHistory = (e) => {
    e.preventDefault();
    setHistoryModal(true);
  };

  return (
    <>
      <div className="flex pt-5 pb-3 px-3 border-solid border-b border-getgrey-1">
        <img
          src={back}
          className="my-auto p-2 h-14 cursor-pointer"
          onClick={() => history.push(`${pathname}`)}
        />
        <div className="m-auto text-center">
          <Span weight="semi" size="base">
            {VEHICLE[rentals?.vehicleType]} | {orderCode}
          </Span>
          <div className="flex py-1">
            <div>
              <p>
                <Span size="xs" padding="pr">
                  {rentals?.updatedAt?.toDate()
                    ? moment(rentals?.updatedAt?.toDate()).format(
                        "MMMM DD, YYYY | h:mm:ss A"
                      )
                    : moment(rentals?.createdAt?.toDate()).format(
                        "MMMM DD, YYYY | h:mm:ss A"
                      )}
                </Span>
              </p>
            </div>

            <div
              className={`status_details_${rentals?.status?.toLowerCase()} status_details`}
            >
              {rentals?.status?.toUpperCase().replace("_", " ")}
            </div>
          </div>
          <div
            className="flex w-full justify-center cursor-pointer"
            onClick={handleHistory}
          >
            <Span
              weight="semi"
              color="orange"
              whitespace="nowrap"
              padding="none"
              width="none"
              margin="my-auto"
            >
              Transaction History
            </Span>
            <IoIosArrowForward className="my-auto text-getorange-2" />
          </div>
        </div>
      </div>

      <div className="p-3">
        <div className="flex mx-auto pt-3">
          <div className="">
            <img src={dropdown} className="" />
          </div>

          <div className="mx-2">
            <Span weight="semi" padding="none">
              {rentals.pickup?.locationAddress}
            </Span>
            {rentals.pickup?.location.address ? (
              <p>
                <Span color="grey" padding="none" transform="capitalize">
                  {`${rentals.client?.locationDetails}`}
                </Span>
              </p>
            ) : (
              <></>
            )}
            {rentals.client?.name || rentals.client?.contactNumber ? (
              <>
                <p>
                  <Span color="grey" padding="none" transform="capitalize">
                    {`${rentals.client?.name} | ${rentals.client?.contactNumber}`}
                  </Span>
                </p>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <ScheduleInformation rentals={rentals} />

        <div className="my-3">
          <Span color="lightgrey" weight="semi">
            Order Type
          </Span>
          <p>
            <Span transform="capitalize">{rentals?.orderType}</Span>
          </p>
        </div>

        {rentalItems?.itemDescription ? (
          <div className="my-3">
            <Span color="lightgrey" weight="semi">
              Item Description
            </Span>
            <p>
              <Span transform="capitalize">{rentalItems?.itemDescription}</Span>
            </p>
          </div>
        ) : (
          <></>
        )}

        {rentalItems?.notes ? (
          <div className="my-3">
            <Span color="lightgrey" weight="semi">
              Note to Driver
            </Span>
            <p>
              <Span transform="capitalize">{rentalItems?.notes}</Span>
            </p>
          </div>
        ) : (
          <></>
        )}

        <PaymentMethod rentals={rentals} />
      </div>

      {historyModal && (
        <History setHistoryModal={setHistoryModal} rentals={rentals} />
      )}
    </>
  );
};

export default RentalInfo;
