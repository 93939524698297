import { Spinner } from "react-bootstrap";
import "./PromoCode.scss";
import { Input } from "../../common/Input";
import { Button } from "../../common/Button";
import paymentmethod from "../../assets/img/web/paymentmethod_icon.png";

import { useEffect, useState } from "react";
import { RootState, Dispatch } from "../../store";
import { useDispatch, useSelector } from "react-redux";

interface PromoCodeProps {
  loading?: boolean;
  onChange?: (e) => void;
  successMessage?: string | null;
}

const PromoCode = ({ loading }: PromoCodeProps) => {
  const { orderDetails, isValidPromoCode, promoCode } = useSelector(
    (state: RootState) => state.Order
  );
  const dispatch = useDispatch<Dispatch>();

  let [code, setCode] = useState(promoCode ?? "");

  const onClick = async () => {
    if (isValidPromoCode) {
      await dispatch.Order.setPromoCode(null);
    }
    dispatch.Order.calculateFare();
  };

  const onChange = (e) => {
    dispatch.Order.setPromoCode(e.target.value);
  };

  useEffect(() => {
    setCode(promoCode ?? "");
  }, [promoCode]);

  return (
    <div className="px-4 pt-4">
      <div className="flex">
        <img src={paymentmethod} alt="" className="payment-icon" />
        <h6 className="my-auto">Voucher</h6>
      </div>
      <div className="flex mt-1.5 border border-getgrey-3 rounded-md">
        <Input
          width="full"
          radius="md"
          border="none"
          value={promoCode ?? ""}
          placeholder="Add Voucher"
          onChange={onChange}
          disabled={!!isValidPromoCode}
        />
        {loading ? (
          <Spinner animation="border" className="spinner" />
        ) : (
          <Button
            bg={isValidPromoCode ? "background-grey" : undefined}
            padding="p-2"
            width="none"
            radius="l-none-normal"
            onClick={onClick}
            disabled={loading || code?.length < 1}
          >
            {isValidPromoCode === true ? "Remove" : "Apply"}
          </Button>
        )}
      </div>
      {isValidPromoCode === true && (
        <div
          style={{
            marginTop: 5,
            fontSize: 13,
            fontWeight: 200,
            color: "green"
          }}
        >
          {`₱ ${orderDetails.fare.discountAmount} Discount Applied.`}
        </div>
      )}
      {isValidPromoCode === false && (
        <div
          style={{
            marginTop: 5,
            fontSize: 13,
            fontWeight: 200,
            color: "red"
          }}
        >
          Invalid Promo Code
        </div>
      )}
    </div>
  );
};

export default PromoCode;
