import * as React from 'react';
import classnames from 'classnames';

type Props = React.HTMLProps<HTMLInputElement> & {  
  border?: 'none' | 'grey' | 'bottom',
  padding?: 'p-4' | 'pl-5' | 'left' | 'top' | 'pl-10' | 'p-3',
  width?: 'full' | 'none' | '80' | '72' | '20',
  bg?: 'grey' | 'none',
  radius?: 'md',
  margin?: 'top' | 'mx-1' | 'none',
  position?: 'relative',
  align?: 'center'
}

let getBorder = (border: Props['border']) => {
  switch (border) { 
    case 'none':
      return 'border-none' 
    case 'bottom':
      return 'border-b-2 border-black' 
    case 'grey':
      return 'border-2 border-getgrey-4 focus:border-getgrey-3' 
    default:
      return 'border border-getgrey-3';
  }
}

let getPadding = (padding: Props['padding']) => {
  switch (padding) { 
  case 'pl-5':
    return 'pl-5 pr-2 py-2'
  case 'left':
    return 'py-4 pl-4 pr-10'
  case 'pl-10':
    return 'py-1 pr-2 pl-10'
  case 'p-3':
    return 'p-3' 
  case 'p-4':
    return 'p-4' 
  case 'top':
    return 'px-3 pt-10 pb-3' 
  default:
    return 'p-2';
  }
}

let getBg = (bg: Props['bg']) => {
  switch (bg) { 
  case 'none':
    return 'bg-transparent' 
  case 'grey':
    return 'bg-getgrey-4' 
  default:
    return 'bg-white-1';
  }
}

let getWidth = (width: Props['width']) => {
  switch (width) { 
  case 'full':
    return 'w-full' 
  case 'none':
    return '' 
  case '20':
    return 'w-20' 
  case '72':
    return 'w-72' 
  case '80':
    return 'w-80' 
  default:
      return 'w-96';
  }
} 
  
let getRadius = (radius: Props['radius']) => {
  switch (radius) { 
  case 'md':
      return 'rounded-md' 
  default:
      return 'rounded-none';
  }
}

let getMargin = (margin: Props['margin']) => {
  switch (margin) { 
    case 'none':
      return 'm-0' 
    case 'top':
      return 'mt-10' 
    case 'mx-1':
      return 'mx-1' 
    default:
      return 'my-1';
  }
}

let getPosition = (position: Props['position']) => {
  switch (position) { 
  case 'relative':
      return 'relative' 
  default:
      return '';
  }
} 

let getAlign = (align: Props['align']) => {
  switch (align) { 
  case 'center':
      return 'text-center' 
  default:
      return 'text-left';
  }
} 
 
export const Input = ({border, width, padding, bg, radius, margin, position, align, ...props}: Props) => { 
  return <input className={classnames("focus:outline-none text-sm", getBorder(border), getWidth(width),
          getPadding(padding), getBg(bg), getRadius(radius), getMargin(margin), getPosition(position),
          getAlign(align), 
          )} { ...props } />
} 
