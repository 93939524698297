/* eslint-disable @typescript-eslint/no-unused-vars */
import { post, get, put } from "../core";

export const requestOTP = async (mobile: string) => {
  try {
    let res = await post(`/customers/web/alleasy/register-request-otp`, {mobile});
    return res.data;
  } catch (err) {
    return false;
  }
};

export const validateOTP = async (mobile: string, otp: string) => {
  try {
    let res = await post(`/customers/web/alleasy/register-validate-otp`, {mobile, otp});
    return res.data;
  } catch (err: any) {
    return err;
  }
};



export const registerUser = async (body: any) => {
  try {
    body.phoneNumber = `+63${body.phone}` 
    let res = await post(`/customers/web/register`, body);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const authenticateViaPassword = async ({ phone, password }: any) => {
  try {
    let res = await post(`/customers/web/login`, {
      phoneNumber: `+63${phone}`,
      password: password
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const LoginViaPin = async ({ phone, pin }: any) => {
  try {
    let res = await post(`/customers/login-via-pin`, {
      phone,
      pin
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const authenticateViaAllEasy = async ({ phone, pin }: any) => {
  try {
    let res = await post(`/customers/web/alleasy/login`, {
      mobile: phone,
      pin
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async (password:string) => {
  try {
    let res = await post(`/customers/reset-password`, {password});

    return res.data;
  } catch (err) {
    return false;
  }
};

export const getUserCredentials = async () => {
  try {
    let res = await get(`/customers/me`);

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateUserProfile = async (body: any) => {
  try {
    const { data } = await put(`/profiles`, body);
    return {
      isSuccess: true
    };
  } catch (err) {
    throw err;
  }
};