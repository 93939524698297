import * as React from 'react';
import classnames from 'classnames'


type Props = React.HTMLProps<HTMLSelectElement> & {
  children?: React.ReactNode 
  type?: 'default' | 'green',
  radius?: 'none'
}

let getStyle = (type: Props['type']) => {
  switch (type) { 
    case 'green':
        return 'border border-getgetgrey-3 focus:outline-none focus:border-getgreen-1'; 
      default:
        return 'border-none';
  }
}

let getRadius = (radius: Props['radius']) => {
    switch (radius) {  
      case 'none':
        return 'rounded-none'; 
      default:
        return 'rounded';
    }
  }
 
export const Select = ({width, radius, type, children, ...props }: Props) => {  
    return <select required className={classnames("w-full p-1.5 focus:outline-none",
        getStyle(type), getRadius(radius) )}
            {...props}>
            {children}
          </select>
}