import { createModel } from "@rematch/core"; 
import firebase from "firebase";
import { RootModel } from ".";
import { getWalletBalance } from "../common/api";

import { 
  IDataWallet,
  IWalletList, 
} from "../types/IDataWallet";

let data: IDataWallet = {
  data: [],
  currentPage: 1
};

const initialState = {
  page: 1,
  maxPage: 0,
  isLoading: false,
  dataWallet: data,
} as IWalletList;

export const WalletList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    setDataWallet(state, payload: IDataWallet) {
      state.dataWallet = payload;
      return { ...state };
    }, 
    setState(state, newState: Partial<IWalletList>) {
      return { ...state, ...newState };
    },
    handleNext: (_) => ({ ..._, page: _.page + 1 }),
    handlePrevious: (_) => ({ ..._, page: _.page - 1 }),
    setIsLoading: (_, isLoading: boolean) => ({ ..._, isLoading }),
    setMaxPage: (_, maxPage: number) => ({ ..._, maxPage })
  },
  effects: (dispatch) => ({
    /**
     * @name getWalletBalance
     * @desc get user's wallet balance
     */
     async getWalletBalance(){
      let balance
      try {
        dispatch.Wallet.setNewState({isLoading: true})
        balance = await getWalletBalance();
      } catch (err) {
        throw err
      } finally {
        dispatch.Wallet.setNewState({ balance: balance, isLoading: false, isAllEasyTokenSet: Boolean((await firebase.auth().currentUser?.getIdTokenResult(true))?.claims["allEasyToken"])})
      }
    }
  })
});
