import axios from "axios";
import { store } from "../../store";
import firebase from "../../Firebase";

const logout = async () => {
  firebase.auth().signOut();
  store.dispatch.User.resetState();
  store.dispatch.Order.resetState();
  store.dispatch.OrderConfirmation.resetState();
  store.dispatch.OrderList.resetState();
  store.dispatch.UI.resetState();
  store.dispatch.RiderforADayModel.resetState();
  store.dispatch.User.updateState({ shouldRedirectToLogin: true });
  store.dispatch.UI.setAlert({ message: "Session expired.", type: "error" });
};

axios.interceptors.request.use(
  async (config) => {
    let accessToken = await firebase.auth().currentUser?.getIdToken(true);

    config.headers.source = `customer`;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      store.dispatch.User.updateState({ userToken: accessToken })
    }

    return config
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response?.status === 401) {
      logout();
      throw err;
    }

    throw err?.response?.data?.message ?? "error";
  }
);

const getUrl = (endpoint) => {
  const url =
    endpoint.indexOf("http://") === 0 || endpoint.indexOf("https://") === 0
      ? endpoint
      : process.env.REACT_APP_API_URL + endpoint;

  return url;
};

export const constructHeader = (token = store.getState().User.userToken) => {
  return token ? { Authorization: "Bearer " + token } : {};
};

const coreFunc = (verb, endpoint, config = {}, data = null) => {
  config.headers = {
    ...config.headers,
    ...constructHeader(),
  };

  if (data !== null) {
    return axios[verb](getUrl(endpoint), data, config);
  }
  return axios[verb](getUrl(endpoint), config);
};

export const post = async (endpoint, data, headers = {}) =>
  coreFunc(
    "post",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const put = async (endpoint, data, headers = {}) =>
  coreFunc(
    "put",
    endpoint,
    { headers: { "Content-Type": "application/json", ...headers } },
    JSON.stringify(data)
  );

export const postFormData = async (endpoint, data) =>
  coreFunc(
    "post",
    endpoint,
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } },
    data
  );

export const get = async (endpoint, headers = {}) =>
  coreFunc("get", endpoint, {
    headers: { "Content-Type": "application/json", ...headers },
  });

export const getBlob = async (endpoint) =>
  coreFunc("get", endpoint, { responseType: "blob" });

export const postMultipart = async (endpoint, data) =>
  coreFunc(
    "post",
    endpoint,
    { headers: { "Content-Type": "multipart/form-data" } },
    data
  );
