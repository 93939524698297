/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer/MainContainer";
import { Row, Col, Spinner } from "react-bootstrap";
import firebase from "../../Firebase";

import "./OrderDetails.scss";

import OrderInfo from "./OrderInfo/OrderInfo";
import Map from "./Map/Map";

let DELIVERY_UNSUBSCRIBE: any = null;
let DRIVER_UNSUBSCRIBE: any = null;

const OrderDetailsPage = () => {
  const [delivery, setDelivery] = useState({} as any);
  const [order, setOrder] = useState({} as any);
  const [loading, setLoading] = useState(true);

  const { referenceNumber }: any = useParams();

  const listenToOrder = async () => {
    let order = (
      await firebase.firestore().collection("orders").doc(referenceNumber).get()
    ).data();

    DELIVERY_UNSUBSCRIBE = firebase
      .firestore()
      .collection("deliveries")
      .doc(order?.deliveryId)
      .onSnapshot((doc) => {
        let delivery: any = doc.data();
        delivery?.stops?.sort((a, b) => (a.sequenceNo > b.sequenceNo ? 1 : -1));
        delivery.dropoff = delivery?.stops?.pop();

        setDelivery(delivery);
        setLoading(false);
      });
    setOrder(order);
  };

  useEffect(() => {
    listenToOrder();

    return function () {
      try {
        if (DELIVERY_UNSUBSCRIBE) DELIVERY_UNSUBSCRIBE();
        if (DRIVER_UNSUBSCRIBE) DRIVER_UNSUBSCRIBE();
      } catch (err) {
        console.log({ err });
      }
    };
  }, [referenceNumber]);

  const renderLoading = () => {
    return (
      <Col
        lg="12"
        className="d-flex text-primary justify-content-center p-5 align-items-center"
      >
        <Spinner animation="border" role="status" className="spinner">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Col>
    );
  };

  return (
    <MainContainer className="pr-3 pl-0 w-100 ">
      <Row className="h-screen">
        <Col
          lg="4"
          className="px-0 position-relative left-column border-right border-r border-solid border-getgrey-1"
        >
          <div className="order-details__container scrollable-container">
            {loading ? (
              renderLoading()
            ) : (
              <div className="w-full absolute bg-white-1 inset-y-0 left-0">
                <OrderInfo order={order} delivery={delivery} />
              </div>
            )}
          </div>
        </Col>

        <Col lg="8" className="p-0 position-relative map-section">
          <div className="map-view">
            {!loading && (
              <Map
                locations={[
                  delivery?.pickup ?? {},
                  ...(delivery?.stops ?? []),
                  delivery?.dropoff ?? {}
                ]}
                driver_location={delivery?.driverLocation}
              />
            )}
          </div>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default OrderDetailsPage;
