import { Span } from '../../../common/Span'
import { Card } from '../../../common/Container'   
import { PAYMENTMETHOD } from '../../../constants/index'
import { useHistory } from "react-router"; 

const RentalContent = ({rentalList}:any) => {  
    
  const history = useHistory(); ; 
  
    return (
       <div className='responsive-content'>
            {rentalList?.length > 0 ? (<> 
            {rentalList?.map((item) => (<>  
                <div onClick={() => {history.push("/order-history-rental/"+item?.orderCode);}} className="cursor-pointer mx-2">
                    <Card margin='top'>
                        <div className='flex justify-between'>
                            <Span margin="none" size='base' color='grey' whitespace='nowrap'>Name</Span>
                            <Span margin="none" size="base" align='right'>{item?.client?.name}</Span>
                        </div>
                        <div className='flex justify-between'>
                            <Span margin="none" size='base' color='grey' whitespace='nowrap'>Contact Number</Span>
                            <Span margin="none" size="base" align='right' transform='uppercase'>{item?.client?.contactNumber}</Span>
                        </div>
                        
                        <div className='flex justify-between'>
                            <Span margin="none" size='base' color='grey' whitespace='nowrap'>Location Details</Span>
                            <Span margin="none" size="base" align='right'>{item?.client?.locationDetails?.toUpperCase()}</Span>
                        </div>
                        <div className='flex justify-between'>
                            <Span margin="none" size='base' color='grey' whitespace='nowrap'>Status</Span>
                            <Span margin="none" size="base" align='right' transform='capitalize'>{item?.status?.replace("_", ' ')}</Span>
                        </div>

                        <div className='flex justify-between'>
                            <Span margin="none" size='base' color='grey' whitespace='nowrap'>Origin Total</Span>
                            <Span margin="none" size="base" align='right'>
                            {`₱ ${parseFloat(item?.originTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            </Span>
                        </div>

                        <div className='flex justify-between'>
                            <Span margin="none" size='base' color='grey' whitespace='nowrap'>Payment Method</Span>
                            <Span margin="none" size="base" align='right'>{PAYMENTMETHOD[item?.paymentMethod]}</Span>
                        </div> 
                    </Card>  
                </div>
            </>))}  
            </>): (<>
                <div className='w-full mx-auto text-center bg-palette-lightblue p-2'>
                    <Span size='base'>No Rental Booking Yet!</Span>
                </div> 
            </>)} 
       </div>
    );
};

export default RentalContent;