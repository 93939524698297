/* eslint-disable import/no-anonymous-default-export */
import { ModalContainer } from "../../common/Container";
import { ModalCloseButton } from "../../common/Button";
import { Button } from "../../common/Button";
import { Span } from "../../common/Span";
import { IoClose } from "react-icons/io5";

import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { currencyCommaFormat } from "../../common/utils";
import walletviolet from "../../assets/img/Alleasy_walletviolet.png";

type Props = {
  setWalletModal: Function;
};

export default ({ setWalletModal }: Props) => {
  const { totalFee } = useSelector((state: RootState) => state.Order);

  const balance: any = useSelector<RootState>(
    (state: RootState) => state.Wallet.balance
  );

  return (
    <>
      <ModalContainer width="primary" position="center">
        <div className="flex pt-1.5">
          <ModalCloseButton onClick={() => setWalletModal(false)}>
            <IoClose className="text-2xl" />
          </ModalCloseButton>
          <div className="p-5">
            <Span weight="semi" size="lg" padding="none" color="darkred">
              You have to pay
            </Span>
            <p className="relative">
              <Span
                margin="none"
                padding="none"
                color="darkgrey"
                position="top"
              >
                ₱
              </Span>
              <Span
                margin="none"
                padding="p-2.5"
                size="3xl"
                color="darkred"
                weight="semi"
              >
                {currencyCommaFormat(totalFee, 2)}
              </Span>
            </p>
          </div>
        </div>

        <div className="mx-5" onClick={() => setWalletModal(false)}>
          <div className="flex justify-between p-1 border-b border-solid border-getgrey-1">
            <div className="flex">
              <img className="check-icon" src={walletviolet} alt="" />
              <div>
                <Span size="base" color="darkred">
                  Wallet
                </Span>
                <p>
                  <Span>Balance ₱{balance != null ? balance : "0"}</Span>
                </p>
              </div>
            </div>
            <input type="radio" id="" name="" value="" className="my-auto" />
          </div>

          <div className="my-5 text-right">
            <Button border="green" width="none" padding="p-2">
              Pay ₱{currencyCommaFormat(totalFee, 2)}
            </Button>
          </div>
        </div>
      </ModalContainer>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
