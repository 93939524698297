import { createModel } from "@rematch/core";
import {
  authenticateViaPassword,
  authenticateViaAllEasy,
  getUserCredentials,
  updateUserProfile,
  registerUser,
  LoginViaPin
} from "../common/api/endpoints/login";
import { IUserInfo, ISignUp } from "../types"; 
import { RootModel } from ".";
import { getFirebaseToken } from "../common/utils";
import firebase from "firebase";

export interface State {
  customToken: string;
  isUserLoggedIn: boolean;
  signupComplete: boolean;
  isAllEasyModalOpen: boolean;
  userInfo: IUserInfo | null;
  userSignUp: ISignUp | null;
  userToken: string;
  shouldRedirectToLogin: boolean;
}

const initialState = {
  customToken: "",
  isUserLoggedIn: false,
  signupComplete: false,
  isAllEasyModalOpen: false,
  shouldRedirectToLogin: false,
  userInfo: null,
  userSignUp: null,
  userToken: "",
} as State

export const User = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState}
    },
    setUserLoggedIn(state, payload: boolean) {
      state.isUserLoggedIn = payload;
      return { ...state };
    },
    setUserInfo(state, payload: IUserInfo | null) {
      state.userInfo = payload;
      return { ...state };
    },
    setSignUp(state, payload: ISignUp | null) {
      state.userSignUp = payload;
      return { ...state };
    },
    setUserToken(state, payload: string) {
      state.userToken = payload;
      return { ...state };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState }
    },
  },
  effects: (dispatch) => ({
    /**
     * @name login
     * @desc login user using email and password
     */
    async login(payload, rootState) {
      dispatch.UI.setIsUserLoading({ target: "login", value: true });


      try {
        let res = await authenticateViaPassword({
          phone: rootState.UI.forms.login.fields.phone,
          password: rootState.UI.forms.login.fields.password,
        });

        const customToken = res.accessToken
        
        const {  userToken } = await getFirebaseToken(customToken) 
        dispatch.User.updateState({ customToken: customToken, userToken });
        
        dispatch.User.updateState({
          customToken: userToken,
          userToken
        });

        dispatch.UI.setErrorMsg({
          target: "login",
          value: "",
        });

        dispatch.UI.resetForm("login");
        await this.getUserInfoViaToken();
      } catch (err: any) { 
        dispatch.UI.setErrorMsg({
          target: "login",
          value: "Invalid credentials",
        });
      } finally {
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
      }
    },
    async LoginPin(pin:string, rootState) {
      if(pin.length !== 6){
        return;
      }

      dispatch.UI.setIsUserLoading({ target: "login", value: true });

      try {
        let res = await LoginViaPin({
          phone: `+63${rootState.UI.forms.login.fields.phone}`,
          pin: pin,
        });

        const customToken = res.accessToken
        
        const {  userToken } = await getFirebaseToken(customToken) 
        dispatch.User.updateState({ customToken: customToken, userToken });
        
        dispatch.User.updateState({
          customToken: userToken,
          userToken
        });

        dispatch.UI.setErrorMsg({
          target: "login",
          value: "",
        });

        dispatch.UI.resetForm("login");
        await this.getUserInfoViaToken();

        // dispatch.Wallet.getWalletBalance()
      } catch (err: any) { 
        dispatch.UI.setErrorMsg({
          target: "login",
          value: err,
        });
      } finally {
        
        dispatch.UI.setInputFormFields({
          target: "login",
          field: "pin",
          value: "",
        })
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
      }
    },
    async AllEasyLogin(pin:string, rootState) {
      dispatch.UI.setIsUserLoading({ target: "login", value: true });
      if(pin.length !== 6){
        return;
      }


      try {
        let res = await authenticateViaAllEasy({
          phone: (rootState.User?.userInfo?.phoneNumber ?? "").replace("+63", ""),
          pin: pin,
        });


        const customToken = res.accessToken
        
        const {  userToken } = await getFirebaseToken(customToken) 
        dispatch.User.updateState({ customToken: customToken, userToken });
        
        dispatch.User.updateState({
          customToken: userToken,
          userToken
        });

        dispatch.UI.setErrorMsg({
          target: "login",
          value: "",
        });

        dispatch.Wallet.setNewState({ isAllEasyTokenSet: Boolean((await firebase.auth().currentUser?.getIdTokenResult(true))?.claims["allEasyToken"]) })

        dispatch.UI.resetForm("login");
        await this.getUserInfoViaToken();

        // dispatch.Wallet.getWalletBalance()
      } catch (err: any) { 
        dispatch.UI.setErrorMsg({
          target: "login",
          value: "Invalid Pin",
        });
      } finally {
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
      }
    },
    /**
     * @name getUserInfoViaToken
     * @desc fetches and sets user data via token(state.userToken)
     */
    async getUserInfoViaToken() {
        let user_profiles = await getUserCredentials();

        dispatch.User.setUserInfo({
          ...user_profiles
        });
        dispatch.User.setUserLoggedIn(true);
    },
    async updateUserProfile (body: Partial<IUserInfo>) {
      try {
        const data = await updateUserProfile(body)
        if (data.isSuccess) {
          dispatch.UI.setAlert({ message: 'Profile updated.', type: "success"})
        }
        return true
      } catch (err) {
        dispatch.UI.setAlert({ message: 'Update failed.', type: 'error'})
        return false
      }
  },
  async register(body: Partial<ISignUp>) { 
    let res = await registerUser(body);  

    if (res) {
      dispatch.User.updateState({signupComplete: true})
      dispatch.UI.setAlert({ message: 'Thank You for Signing Up For All Easy Go', type: "success"}) 
    } else {
      dispatch.UI.setAlert({ message: 'Signup failed.', type: 'error'}) 
    }
  
  },
  }),
});
