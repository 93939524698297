import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../../store";

import { ModalContainer } from "../../../common/Container";
import { Span } from "../../../common/Span";
import { Input } from "../../../common/Input";
import { Button } from "../../../common/Button";
import Switch from "@mui/material/Switch";

import "./AddOns.scss";
import { onlyNumberKey } from "../../../common/utils";
import { RiErrorWarningFill } from "react-icons/ri";
import { amountToBeCollectedMaxAmount } from "../../../constants/express.constant";

const CashHandlingContent = () => {
  const isRemitOrCollect: any = useSelector(
    (state: RootState) => state.Order.isRemitOrCollect
  );

  const { amountToBeCollected, collectAmountFrom } = useSelector<RootState>(
    (state: RootState) => state.Order
  ) as any;

  const dispatch = useDispatch<Dispatch>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch.Order.updateState({
      isRemitOrCollect: e.target.checked
    });

    // dispatch.Order.updateState({
    //   remittanceFee: 0,
    //   cashHandling: 30
    // });
    // if (isRemitOrCollect) {
    //   dispatch.Order.updateState({
    //     remittanceFee: 0,
    //     cashHandling: 30
    //   });
    // } else {
      // dispatch.Order.updateState({
      //   remittanceFee: 30,
      //   cashHandling: 0
      // });
    // }

    dispatch.Order.updateState({ amountToBeCollected: amountToBeCollected });
  };

  const handleInputAmount = (v: any) => {
    dispatch.Order.updateState({
      amountToBeCollected: v.target.value
    });
  }; 

  return (
    <>
      <ModalContainer width="modal-s">
        <div className="py-4 whitespace-normal">
          <div className="px-10">
            <div className="text-center pt-4 py-2">
              <Span size="4xl" weight="bold" color="blackgrey">
                Amount to be Collected
              </Span>
              <p className="py-2">
                <Span size="xl" color="blackgrey">
                  Please enter amount to be collected
                </Span>
              </p>

              <Input
                border="grey"
                radius="md"
                width="full" 
                onKeyPress={onlyNumberKey}
                maxLength={4}
                value={amountToBeCollected}
                onChange={(e: any) => {
                  handleInputAmount(e);
                }}
              />

              <select
                className="dropdownArrow p-2.5 my-2"
                onChange={(e) => dispatch.Order.updateState({
                  collectAmountFrom: e.target.value
                })} 
                value={collectAmountFrom}
              >
                <option value="" selected disabled hidden>
                  Select where to collect
                </option>
                <option value="receiver"> Receiver </option>
                <option value="sender"> Sender </option>
              </select>
            </div>

            <Switch
              // defaultChecked
              checked={isRemitOrCollect}
              onChange={handleChange}
            />

            {isRemitOrCollect
              ? "Electronic Remittance"
              : "Non Electronic Remittance"}
          </div>

          {amountToBeCollected > amountToBeCollectedMaxAmount ? (
            <div className="flex my-3 bg-gray-200 py-3">
              <div className="flex m-auto">
                <RiErrorWarningFill className="m-auto text-red-400 text-4xl" />
                <Span size="lg" color="blackgrey">
                  The amount limit is {amountToBeCollectedMaxAmount}.
                </Span>
              </div>
            </div>
          ) : (
            ""
          )}

          {amountToBeCollected === "0" ||
          amountToBeCollected === "00" ||
          amountToBeCollected === "000" ||
          amountToBeCollected === "0000" ? (
            <div className="flex my-3 bg-gray-200 py-3">
              <div className="flex m-auto">
                <RiErrorWarningFill className="m-auto text-red-400 text-4xl" />
                <Span size="lg" color="blackgrey">
                  Please enter valid amount!
                </Span>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="text-center my-4 px-10">
            <Button
              disabled={
                !collectAmountFrom ||
                amountToBeCollected === "0" ||
                amountToBeCollected === "00" ||
                amountToBeCollected === "000" ||
                amountToBeCollected === "0000" ||
                !amountToBeCollected ||
                amountToBeCollected > amountToBeCollectedMaxAmount
              }
              width="none"
              padding="py-2 px-12"
              weight="medium"
              margin="mx"
              onClick={() => {
                dispatch.Order.updateState({
                  isCashHandlingModal: false,
                  amountToBeCollected: amountToBeCollected,
                  collectAmountFrom: collectAmountFrom
                });

                dispatch.Order.setAddOnsIsChecked({
                  target: "cashHandling",
                  value: true
                });
              }}
            >
              Ok
            </Button>

            <Button
              width="none"
              padding="py-2 px-12"
              weight="medium"
              margin="mx"
              bg="bg-gray"
              onClick={() => {
                dispatch.Order.updateState({
                  isCashHandlingModal: false,
                  amountToBeCollected: 0,
                  remittanceFee: 0,
                  cashHandling: 0,
                  isRemitOrCollect: false,
                  collectAmountFrom: ""
                });

                dispatch.Order.setAddOnsIsChecked({
                  target: "cashHandling",
                  value: false
                });
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default CashHandlingContent;
