import { Span } from "../../../common/Span";
import { Card } from "../../../common/Container";

const Content = ({ data }: any) => {
  return (
    <div className="responsive-content">
      {data?.map((wallet) => (
        <>
          <Card margin="top">
            <div className="flex justify-between">
              <Span margin="none" size="base" color="grey" whitespace="nowrap">
                Tracking No.
              </Span>
              <Span margin="none" size="base" align="right">
                {wallet?.id}
              </Span>
            </div>
            <div className="flex justify-between">
              <Span margin="none" size="base" color="grey" whitespace="nowrap">
                Payment Type
              </Span>
              <Span
                margin="none"
                size="base"
                align="right"
                transform="capitalize"
              >
                {`${wallet.typeDescription}`}
              </Span>
            </div>

            <div className="flex justify-between">
              <Span margin="none" size="base" color="grey" whitespace="nowrap">
                Amount
              </Span>
              <Span margin="none" size="base" align="right">
                {wallet?.totalAmountDescription}
              </Span>
            </div>
            <div className="flex justify-between">
              <Span margin="none" size="base" color="grey" whitespace="nowrap">
                Date
              </Span>
              <Span margin="none" size="base" align="right">
                {wallet?.dateDescription}
              </Span>
            </div>
          </Card>
        </>
      ))}
    </div>
  );
};

export default Content;
