/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import MainContainer from "../../components/MainContainer/MainContainer";
import VehicleSelection from "./VehicleSelection/VehicleSelection";
import PriceRental from "./Content/Price/PriceRental";
import PickupLocation from "./Content/PickupLocation/PickupLocation";
import ScheduleSelection from "./Content/ScheduleSelection/ScheduleSelection";
import SchedulePicker from "./Content/SchedulePicker/SchedulePicker";
import ClientInformation from "./Content/ClientInformation/ClientInformation";

import AddSchedule from "../../assets/img/rental/add_icon.png";

import { Span } from "../../common/Span";

import "../Order/Order.scss";
import "./Style/RiderforADay.scss";
import Map from "../../components/Map/Map";
import WalletBalance from "../../components/WalletBalance/WalletBalance";

import { RootState, Dispatch } from "../../store";
import { Button } from "../../common/Button";
import ScheduleType from "./Content/ScheduleType/ScheduleType";
import ImmediateScheduled from "./Content/ImmediateScheduled/ImmediateScheduled";
import { getConfiguresConstant, getVehicleType } from "../../common/api";
import ConfigService from "../../components/ConfigService/ConfigService";

const RiderforADay = () => {
  const dispatch = useDispatch<Dispatch>();

  const { locations } = useSelector((state: RootState) => state.Locations);
  const { vehicles } = useSelector(
    (state: RootState) => state.RiderforADayModel
  );
  const [isDatePickerShown, setIsDatePickerShown] = useState<boolean>(false);
  const [sequence, setSequence] = useState(null);

  const { schedule, datePickerRanges } = useSelector(
    (state: RootState) => state.RiderforADayModel
  );

  const [loading, setIsLoading] = useState(false);
  const [configDataValue, setConfigData] = useState<boolean>(false);
  const getConfigures = async () => {
    setIsLoading(true);

    try {
      const config = await getConfiguresConstant();
      const configData = config.filter(
        (d: any) => d.name === "ENABLE_RENTAL_SERVICE"
      );
      setConfigData(configData[0].value);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getConfigures();
  }, []);

  useEffect(() => {
    let newLocations = locations.map((loc, i) => {
      if (loc.lat && loc.lng) {
        loc.lat = 0;
        loc.lng = 0;
      }
      return { ...loc };
    });
    dispatch.Locations.updateState({
      locations: [...newLocations]
    });
    dispatch.RiderforADayModel.updateState({
      schedule: [
        ...schedule.map((sched: any, index) => {
          sched.sequence = index;
          return sched;
        })
      ],
      datePickerRanges: [
        ...datePickerRanges.map((range: any, index) => {
          range.sequence = index;
          return range;
        })
      ]
    });
  }, [schedule?.length]);

  const [immediate, setImmediate] = useState(true);
  const [scheduled, setScheduled] = useState(false);

  const [vehicleDetails, setVehicleDetails] = useState([] as any);

  // Selected Vehicle
  const vehicle = vehicles?.find((v) => v?.isSelected);
  const selectedVehicleType = vehicle?.vehicleType;

  const getVehicle = async () => {
    try {
      const res = await getVehicleType();
      const activeVehicle = res.filter((d: any) => d.webRiderEnabled === true);
      setVehicleDetails(activeVehicle);
    } catch (err) {
      console.log(err);
    }
  };

  const vDetailType = vehicleDetails?.find(
    (d) => d?.code === selectedVehicleType
  );
  const vehicleDetailType = vDetailType ? vDetailType : "motorcycle";

  const portalDeliveryType = vehicleDetailType?.portalDeliveryType;
  const isInstant = portalDeliveryType?.includes("Instant");
  const isScheduled = portalDeliveryType?.includes("Scheduled");

  useEffect(() => {
    getVehicle();
  }, []);

  return (
    <>
      {!configDataValue ? (
        <>
          <MainContainer className="pr-3.5 pl-0 w-100 overflow-hidden">
            {/* <WalletBalance /> */}

            <Row className="h-screen">
              <Col
                lg="4"
                className="px-0 position-relative left-column border-right"
              >
                <div className="order-container scrollable-container">
                  <VehicleSelection
                    vehicleDetails={vehicleDetails}
                    selectedVehicleType={selectedVehicleType}
                  />

                  <ScheduleType />

                  <ImmediateScheduled
                    setIsDatePickerShown={setIsDatePickerShown}
                    setSequence={setSequence}
                    immediate={immediate}
                    setImmediate={setImmediate}
                    scheduled={scheduled}
                    setScheduled={setScheduled}
                    isInstant={isInstant}
                    isScheduled={isScheduled}
                  />

                  {scheduled ? (
                    <>
                      {schedule?.map((sched) => (
                        <ScheduleSelection
                          key={sched.sequence}
                          setIsDatePickerShown={setIsDatePickerShown}
                          sched={sched}
                          setSequence={setSequence}
                        />
                      ))}

                      <div className="d-flex justify-content-end px-2">
                        <Button
                          bg="none"
                          width="none"
                          flex="flex"
                          onClick={() => {
                            dispatch.RiderforADayModel.setScheduleAndRange({
                              sequence:
                                Math.max(
                                  ...schedule.map((sched) => sched.sequence)
                                ) + 1,
                              startDate: null,
                              endDate: new Date("")
                            });
                          }}
                        >
                          <img
                            className="my-auto h-2.5"
                            src={AddSchedule}
                            alt={AddSchedule}
                          />
                          <Span>Add more schedules</Span>
                        </Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <PickupLocation />
                  <ClientInformation />
                </div>
                <PriceRental />
                {isDatePickerShown && (
                  <SchedulePicker
                    setIsDatePickerShown={setIsDatePickerShown}
                    sequence={sequence}
                  />
                )}
              </Col>
              <Col lg="8" className="p-0 position-relative map-section">
                <div className="map-view">
                  <Map />
                </div>
              </Col>
            </Row>
          </MainContainer>
        </>
      ) : (
        <MainContainer className="w-100 overflow-auto">
          <ConfigService loading={loading} />
        </MainContainer>
      )}
    </>
  );
};

export default RiderforADay;
