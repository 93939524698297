/* eslint-disable no-useless-computed-key */
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../../store";

import { Button } from "../../../../common/Button";
import { Input } from "../../../../common/Input";
import { Span } from "../../../../common/Span";

import moment from "moment";
import { useEffect, useState } from "react";
import TimePicker from "react-time-picker";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./SchedulePicker.scss";
import clock from "../../../../assets/img/rental/clock_icon.png";
import { getfinalStartEndDate } from "../../../../common/utils";

const SchedulePicker = ({ setIsDatePickerShown, sequence }: any) => {
  const { scheduleType }: any = useSelector(
    (state: RootState) => state.RiderforADayModel
  );

  const dispatch = useDispatch<Dispatch>();
  const { datePickerRanges, schedule } = useSelector(
    (state: RootState) => state.RiderforADayModel
  );
  const dateRange: any = datePickerRanges?.find((d) => d.sequence === sequence);
  const existingDateRange = {
    ...dateRange,
    startDate: new Date(dateRange?.startDate),
    endDate: new Date(dateRange?.endDate)
  };

  const minDate = moment(moment.now()).format("LL");
  const minTime = !schedule[0].startDate
    ? moment(moment.now()).add(30, "m").format("HH:mm a")
    : moment(new Date(schedule[0].startDate)).format("HH:mm a");
  const [date, setDate] = useState<string>(minDate);
  const [time, setTime] = useState<string>(minTime);

  const { [0]: HH, [1]: mm } = time.replace(/[" "pmam]/g, "").split(":");
  const selectedDate = new Date(
    moment(dateRange?.startDate).format(`YYYY-MM-DDT${HH}:${mm}:ss.SSS`)
  );

  const defaultEndTime = moment(
    new Date(
      selectedDate?.setHours(
        scheduleType === "halfday"
          ? selectedDate.getHours() + 5
          : selectedDate.getHours() + 9
      )
    )
  ).format("HH:mm a"); //default endTime

  const { date: DATE, day: DAY } = getfinalStartEndDate(
    dateRange?.startDate,
    dateRange?.endDate
  );

  const selectedPickUpHours = parseInt(time?.split(":")[0]);
  const selectedPickUpMinutes = parseInt(time?.split(":")[1]);

  const getArrayOfSelectedDays: any = (schedule || [])
    ?.filter((sched) => sched?.sequence !== sequence)
    ?.map((sched) => {
      const startDay = new Date(
        moment(sched?.startDate).format("YYYY-MM-DD HH:mm:ss")
      );
      const endDay = new Date(
        moment(sched?.endDate).format("YYYY-MM-DD HH:mm:ss")
      );

      const getDates = () => {
        const date = new Date(startDay.getTime());

        const dates: Array<any> = [];

        while (date <= endDay) {
          dates.push(moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss"));
          date.setDate(date.getDate() + 1);
        }

        return dates;
      };

      const arrayOfDates = getDates();

      return arrayOfDates;
    });

  const disabledDates = [].concat.apply(
    [],
    [...getArrayOfSelectedDays, new Date()]
  );

  const handleDateChange = (item) => {
    dispatch.RiderforADayModel.updateState({
      datePickerRanges: [
        ...datePickerRanges.map((d) =>
          d.sequence === sequence
            ? { ...item.selection, sequence: d?.sequence }
            : d
        )
      ]
    });
  };

  useEffect(() => {
    setDate(moment(dateRange.startDate).format("LL"));
  }, [dateRange]);

  return (
    <div className="position-absolute date-picker d-flex flex-column justify-content-center align-items-center bg-white-1">
      <DateRange
        editableDateInputs={true}
        onChange={handleDateChange}
        moveRangeOnFirstSelection={false}
        ranges={[dateRange.startDate === null ? dateRange : existingDateRange]}
        showSelectionPreview={false}
        minDate={new Date()}
        disabledDates={disabledDates?.map((date) => new Date(date))}
      />
      <div className="bg-white-1 w-250">
        <div className="flex mx-2">
          <img className="my-auto mx-2 h-5" src={clock} alt={clock} />
          <Input radius="md" margin="mx-1" width="full" value={time} disabled />
          <Input
            radius="md"
            margin="mx-1"
            width="full"
            disabled
            value={defaultEndTime === "Invalid date" ? "" : defaultEndTime}
          />
        </div>

        <TimePicker
          onChange={(e: any) => {
            setTime(e);
          }}
          value={time}
          format={"HH:mm"}
          disableClock
          clearIcon={null}
          className="w-100 text-center h4 my-2"
        />

        {date === minDate && time < minTime && (
          <p
            className="text-danger text-center py-2 px-3"
            style={{ fontSize: 14 }}
          >
            Schedule of order must be atleast 30 minutes before the delivery
            time
          </p>
        )}

        <div className="text-center">
          {![DATE, DAY].includes("Invalid date") && (
            <p>
              <Span>{DATE + " " + DAY}</Span>
            </p>
          )}
          <p>
            <Span>Pick-up Time: {time}</Span>
          </p>
          <p>
            <Span>
              End Time:{" "}
              {defaultEndTime === "Invalid date" ? "" : defaultEndTime}
            </Span>
          </p>
        </div>
      </div>

      <div className="flex justify-end w-250 p-2.5 bg-white-1 rounded-b-lg">
        <Button
          bg="lightgrey"
          width="none"
          type="black"
          style={{ fontSize: "1.2rem", width: "6rem" }}
          onClick={(e) => {
            setIsDatePickerShown(false);
          }}
        >
          Cancel
        </Button>
        <div className="p-2"></div>
        <Button
          width="none"
          style={{ fontSize: "1.2rem", width: "6rem" }}
          disabled={
            (date === minDate && time < minTime) ||
            dateRange?.startDate === null
          }
          onClick={() => {
            setIsDatePickerShown(false);
            const endDate = new Date(
              moment(dateRange.endDate).format(`YYYY-MM-DDT${HH}:${mm}:ss.SSS`)
            );
            const startDate = new Date(dateRange?.startDate);
            dispatch.RiderforADayModel.updateSchedule({
              sequence: sequence,
              startDate: moment(
                new Date(
                  startDate?.setHours(
                    selectedPickUpHours,
                    selectedPickUpMinutes
                  )
                )
              ).format("YYYY-MM-DD HH:mm:ss"),
              endDate: moment(
                new Date(
                  endDate.setHours(
                    scheduleType === "halfday"
                      ? endDate.getHours() + 5
                      : endDate.getHours() + 9
                  )
                )
              ).format("YYYY-MM-DD HH:mm:ss")
            });
          }}
        >
          Set
        </Button>
      </div>
    </div>
  );
};

export default SchedulePicker;
