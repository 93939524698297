import { useSelector, useDispatch } from "react-redux";
import { RootState, Dispatch } from "../../store";
import { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Col, Form, Spinner } from "react-bootstrap"; 
import "./Login.scss";

import phLogo from "../../assets/img/web/ph.png";
import { getFirebaseToken, onlyNumberKey, validatePhone } from "../../common/utils"; 

import { MdVisibility, MdVisibilityOff } from "react-icons/md"; 

import { InputContainer } from '../../common/Container'
import { Span } from '../../common/Span' 
import { Button } from '../../common/Button'  
import { BRAND_IMAGES } from "../../constants";

export const ERROR_MESSAGE = {
  USER_NOT_FOUND : 'The email you entered does not exist',
  INVALID_PASSWORD: 'The password you entered is incorrect',
  'Invalid data': 'Please enter only valid credentials' 
}
const Login = () => {
  const history = useHistory();
  const params: any = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const isUserLoggedIn: any = useSelector(
    (state: RootState) => state.User.isUserLoggedIn
  );
  const phone: any = useSelector(
    (state: RootState) => state.UI.forms.login.fields.phone
  );
  const password: any = useSelector(
    (state: RootState) => state.UI.forms.login.fields.password
  );
  const isRequesting: any = useSelector(
    (state: RootState) => state.UI.forms.login.isRequesting
  );
  const errorMessage: any = useSelector(
    (state: RootState) => state.UI.forms.login.errorMessage
  );

  const dispatch = useDispatch<Dispatch>();

  const cdm = async () => {
    await dispatch.User.getUserInfoViaToken();
  }; 


  const validateUser = async() => {
    if (params.authId) {
      try {
        setIsLoading(true);
        const {  userToken } = await getFirebaseToken(params.authId)
        dispatch.User.updateState({ customToken: params.authId, userToken });

        history.push("/");
        cdm();
        return;
      } catch(e) {
        setIsLoading(false); 
      }
    }
  }

  useEffect(() => {

    dispatch.User.updateState({signupComplete: false});
    
    if (isUserLoggedIn) {
      history.push("/order");
    } else {
      validateUser();
    }

    return (() => {})
    // eslint-disable-next-line
  }, [isUserLoggedIn]);

  const isPhoneValid = validatePhone(phone);
  const isFormValid = isPhoneValid;

  // Password
  const [showPassword, setshowPassword] = useState(false);

  const togglePassword = (e) => {
    e.preventDefault();
    setshowPassword(!showPassword);
  };

  const renderLoading = () => {
    return (
      <Col lg="12" className="d-flex text-primary justify-content-center p-5 align-items-center">
          <Spinner animation="border" role="status" className="spinner">
            <span className="sr-only">Loading...</span>
          </Spinner>
      </Col>
    )
  };

  return (
    <div className="login login-bg p-0 m-0"> 
      <div className="d-flex align-items-center justify-content-center p-4">
        <div className="login-card p-2">
          <div className="logo-container">
            <img src={BRAND_IMAGES['BRAND_LOGO']} alt={BRAND_IMAGES['BRAND_LOGO']} className="login-logo" />
          </div>

        {!isLoading ? <>
          {errorMessage ? (
            <div className="mt-4 justify-content-center align-items-center"> 
              <p className="text-getred-2 text-center"> 
                Invalid phone number or password
              </p>
            </div>
          ) : null}
          
          <Form
            className="mt-4"
            onSubmit={async(e) => {
              e.preventDefault();
              setIsLoading(true);
              await dispatch.User.login();
              setIsLoading(false);
            }}
          > 
            <InputContainer flex='wrap' margin='top' padding='none'>
              <div className="absolute flex">
                <img src={phLogo} alt="" className="h-6 my-auto"/>
                <Span padding='p-1.5' size='lg' index='10'>+63</Span>  
                <Span padding='p-1.5' size='2xl' index='10' border='bottom-white'> | </Span>
              </div>
            <div className='group flex-1'> 
              <input  
                className='phone pl-10'
                id="formBasicPhone" 
                value={phone ? phone : ""}
                type="phone"
                disabled={isRequesting} 
                maxLength={10}
                onChange={(e) => {
                  dispatch.UI.setInputFormFields({
                    target: "login",
                    field: "phone",
                    value: e.target.value, 
                  });
                }} 
                onKeyPress={onlyNumberKey}
                required
                autoFocus
              /> 
              <span className="bar"></span>
              <label className='phone'>Phone number</label></div>
            </InputContainer>

            <InputContainer flex='wrap' margin='top'>
              <input 
                className='password w-full relative'
                id="formBasicPassword" 
                value={password ? password : ""}
                type={showPassword ? 'text' : 'password'} 
                disabled={isRequesting}
                onChange={(e) => {
                  dispatch.UI.setInputFormFields({
                    target: "login",
                    field: "password",
                    value: e.target.value,
                  });
                }} required/>
              <span className="bar"></span>

              <label>Password</label>
              <span onClick={togglePassword} className='eyes'>
                {showPassword ? <MdVisibility /> : <MdVisibilityOff /> }
              </span>  
            </InputContainer> 

            <Button 
              weight="semi" margin='top'
              button_type="submit"
              disabled={!isFormValid || isRequesting} 
            >
              {isRequesting ? "Loading ..." : "Log In"}
            </Button>
            
            <div className="text-center mt-3">
              <Link to="./">
                <Span weight="semi" color='orange'>Click here to login using PIN</Span>
              </Link>
            </div>
            
          </Form>
          </>: 
          renderLoading()
        } 
          
        </div>
      </div> 
    </div>
  );
};

export default Login;
