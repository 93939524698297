/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Span } from "../../../../common/Span";
import payment from "../../../../assets/img/rental/paymentmethod_icon.png";
import swal from "sweetalert2";
import { Select } from "../../../../common/Select";
import { RootState, Dispatch } from "../../../../store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ALLEASY_ENABLED = true;

const PaymentMethod = () => {
  const dispatch = useDispatch<Dispatch>();

  const walletBalance = useSelector<RootState>(
    (state: RootState) => state.Wallet.balance
  ) as string | undefined;

  const billings = useSelector<RootState>(
    (state: RootState) => state.User.userInfo?.billings ?? []
  ) as Array<any>;
  const billingEnable = (billings ?? []).length > 0;

  const {
    totalFee,
    paymentMethod,
    billTo: billToUid,
    quoteResult
  }: any = useSelector((state: RootState) => state.RiderforADayModel);

  const handleChange = (e: any) => {
    //  setSelectedOption(e.target.value)
    if (e.target.value === "cash_on_pickup") {
      dispatch.RiderforADayModel.updateState({
        paymentMethod: "cash_on_pickup",
        billTo: "pickup",
        orderModified: true
      });
    } else if (e.target.value === "billing") {
      if (!billingEnable) {
        swal.fire(
          "Billing is not enabled",
          "Please contact admin to enable your billing"
        );
        return;
      }

      if (billings.length === 1) {
        dispatch.RiderforADayModel.updateState({
          paymentMethod: "billing",
          billTo: billings[0].billingUid,
          orderModified: true
        });
        return;
      }

      swal
        .fire({
          title: "Billing",
          text: "Please select billing account",
          input: "select",
          inputOptions: (billings ?? []).reduce(
            (obj, item) => ((obj[item.billingUid] = item.name), obj),
            {}
          )
        })
        .then(({ value }) => {
          if (value) {
            dispatch.RiderforADayModel.updateState({
              paymentMethod: "billing",
              billTo: value,
              orderModified: true
            });
          }
        });
    } else if (e.target.value === "all_easy") {
      if (!ALLEASY_ENABLED) {
        if (typeof walletBalance === "undefined") {
          dispatch.User.updateState({
            isAllEasyModalOpen: true
          });
          return;
        } else if (
          (totalFee ?? 0) > parseFloat(walletBalance.replace(",", "") ?? "0")
        ) {
          swal.fire(
            "Low Balance",
            "Please top-up on you alleasy app to continue"
          );
          return;
        }
        dispatch.RiderforADayModel.updateState({
          paymentMethod: "all_easy",
          orderModified: true
        });
        // dispatch.Wallet.getWalletBalance();
      } else {
        dispatch.User.updateState({
          isAllEasyModalOpen: true
        });
        return;
      }
    }
  };

  useEffect(() => {
    dispatch.RiderforADayModel.updateState({
      paymentMethod: "",
      promoVoucher: null
    });
  }, []);

  const billName = (billings ?? []).find(
    (b) => b.billingUid == billToUid
  )?.name;

  return (
    <>
      <div className="px-4">
        <div className="flex">
          <img src={payment} alt={payment} className="payment-icon" />
          <h6 className="my-auto">Payment Method</h6>
        </div>
        <div className="py-2">
          <Select
            type="green"
            onChange={(e: any) => handleChange(e)}
            value={paymentMethod}
          >
            <option value="" selected disabled hidden>
              Select Payment Method
            </option>
            <option value="billing"> Billing {billName}</option>
          </Select>
        </div>

        <div className="pt-3">
          <div className="py-2 border-dashed border-b border-getgrey-1 text-center">
            <Span
              size="base"
              weight="semi"
              color="orange"
              padding="px-6"
              letters="widest"
            >
              Delivery Fee Breakdown
            </Span>
          </div>
        </div>

        <div className="py-2.5">
          {" "}
          {quoteResult?.fare?.orderType}
          <div className=" py-2">
            <div className="flex justify-between ">
              <div>
                <Span color="darkgrey">Holiday Charge</Span>
              </div>
              <div>
                <Span color="darkgrey">
                  ₱{" "}
                  {parseFloat(quoteResult?.fare?.holidaySurchargeFee)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Span>
              </div>
            </div>

            <div className="flex justify-between ">
              <div>
                <Span color="darkgrey">Total Fare</Span>
              </div>
              <div>
                <Span color="darkgrey">
                  ₱{" "}
                  {parseFloat(quoteResult?.fare?.total)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </Span>
              </div>
            </div>
          </div>
          <div className="flex  border-t border-dashed border-black justify-between py-2.5">
            <div>
              <Span weight="semi">Subtotal</Span>
            </div>
            <div>
              <Span color="darkgrey">
                ₱{" "}
                {parseFloat(totalFee)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Span>
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <Span weight="semi">Total</Span>
              <Span margin="none" padding="py-2" size="xs">
                (incl. VAT)
              </Span>
            </div>
            <div>
              <Span weight="semi">
                ₱{" "}
                {parseFloat(totalFee)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
