import * as React from 'react';
import classnames from 'classnames';

type Props = React.HTMLProps<HTMLButtonElement> & {  
    children: React.ReactNode, 
    button_type?: 'submit',
    type?: 'black' | 'grey',
    bg?: 'default' | 'white' | 'none' | 'gray' | 'background-grey' | 'grey' | 'lightgrey' | 'white-only' | 'bg-gray' | 'orange' | 'lightorange' | 'darkred',
    radius?: 'r-none' | 'l-none' | 'l-none-normal' | 'none' | 'full',
    border?: 'grey' | 'green' | 'grey-green' | 'orange',
    padding?: 'p-2' | 'p-2.5' | 'py-4' | 'px-12' | 'normal' | 'p-1' | 'px-2' | 'none' | 'py-3' | 'py-2 px-12',
    width?: 'none' | 'full' | 'w-sm',
    weight?: 'bold' | 'semi' | 'medium',
    margin?: 'top' | 'mx' | 'mr',
    flex?: 'flex',
    align?: 'left'
}
 
let getTypeClass = (type: Props['type']) => {
    switch (type) { 
    case 'grey':
        return 'text-getgrey-7' 
    case 'black':
        return 'text-black' 
    default:
        return 'text-white';
    }
}

let getBg = (bg: Props['bg']) => {
    switch (bg) {  
    case 'darkred':
        return 'bg-getred-4 hover:bg-red-700'; 
    case 'background-grey': 
        return 'bg-gray-400 hover:bg-gray-400'; 
    case 'bg-gray': 
        return 'bg-getgrey-14 hover:bg-getgrey-6';
    case 'none':
        return 'bg-none' 
    case 'grey':
        return 'bg-getgrey-13'
    case 'lightgrey':
        return 'bg-getgrey-1'
    case 'white-only':
        return 'bg-white-1'
    case 'white':
        return 'bg-white-1 hover:bg-white-2' 
    case 'orange':
        return 'bg-getorange-3'   
    case 'lightorange':
        return 'bg-getorange-2 hover:bg-getorange-3 btn-disabled' 
    default:
        return 'bg-getorange-2 hover:bg-getorange-3';
    }
}
 
let getRadius = (radius: Props['radius']) => {
    switch (radius) { 
    case 'full':
        return 'rounded-full'  
    case 'none':
        return 'rounded-none' 
    case 'l-none-normal':
        return 'rounded rounded-l-none'  
    case 'l-none':
        return 'rounded-md rounded-l-none'  
    case 'r-none':
        return 'rounded-md rounded-r-none' 
    default:
        return 'rounded-md';
    }
}

let getBorder = (border: Props['border']) => {
    switch (border) { 
    case 'grey-green':
        return 'border border-getgrey-1 hover:border-getgreen-1'
    case 'green':
        return 'border border-getgreen-1'
    case 'grey':
        return 'border border-getgrey-1'  
    default:
        return 'border-none';
    }
}

let getPadding = (padding: Props['padding']) => {
    switch (padding) { 
    case 'none':
        return ''
    case 'py-2 px-12':
        return 'py-3 px-12'; 
    case 'px-12':
        return 'py-3 px-12'; 
    case 'py-4':
        return 'py-4' 
    case 'p-2.5':
        return 'py-2.5 px-4'
    case 'p-2':
        return 'py-2 px-4'
    case 'p-1':
        return 'p-1' 
    case 'normal':
        return 'p-2' 
    case 'py-3':
        return 'py-3' 
    case 'px-2':
        return 'px-2' 
    default:
        return 'py-2';
    }
}

let getWidth = (width: Props['width']) => {
    switch (width) { 
    case 'none':
        return '' 
    case 'w-sm':
        return 'w-56' 
    case 'full':
        return 'w-full' 
    default:
        return 'w-100';
    }
}

let getFontWeight = (weight: Props['weight']) => {
    switch (weight) {  
        case 'bold':
            return 'font-bold';
        case 'semi':
            return 'font-semibold';
        case 'medium':
            return 'font-medium';
        default:
            return 'font-normal';
    }
  }

let getMargin = (margin: Props['margin']) => {
    switch (margin) {
    case 'mr':
        return 'mr-2'  
    case 'mx':
        return 'mx-2'  
    case 'top':
        return 'mt-6' 
    default:
        return '';
    }
}

let getFlex = (flex: Props['flex']) => {
    switch (flex) { 
    case 'flex':
        return 'flex' 
    default:
        return 'flex-none';
    }
}
 
let getAlign = (align: Props['align']) => {
  switch (align) {   
    case 'left':
      return 'text-left';
    default:
      return 'text-center';
  }
}   
 
export const Button = ({type, bg, button_type, radius, border, padding, width, align, weight, margin, flex, children, ...props}: Props) => { 
    return <button className={classnames('focus:outline-none whitespace-nowrap tracking-wider', 
        getTypeClass(type), getBg(bg), getRadius(radius), getBorder(border), getPadding(padding), 
        getWidth(width), getFontWeight(weight), getMargin(margin), getFlex(flex), getAlign(align), 
        )} type={button_type} { ...props }
        >{children}
        </button>
} 

export const ModalCloseButton = ({ type, padding, children, ...props}: Props) => {
    return <div className={classnames("absolute w-full")}>
      <button className={classnames("float-right leading-none font-semibold outline-none m-auto ml-auto py-1 px-3",
       )} {...props } >{children}
    </button></div>
  }