import { useState } from "react";
import MainContainer from "../../../components/MainContainer/MainContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import { Row, Col } from "react-bootstrap";
import Map from "../../../components/Map/Map";
import ItemDescription from "./Content/ItemDescription";
import "./RiderOrder.scss";
import { useHistory } from "react-router-dom";
import { Button } from "../../../common/Button";

import WalletBalance from "../../../components/WalletBalance/WalletBalance";

import PaymentMethod from "./Content/PaymentMethod";
import Loader from "../../../components/Loader/Loader";
import TermsAndConditionModal from "./Modal/TermsAndCondition";
import { Span } from "../../../common/Span";
import { GridContainer } from "../../../common/Container";
import { analyticEvent, Event } from "../../../customHooks/analytics";

const RiderOrder = () => {
  const dispatch = useDispatch<Dispatch>();

  const {
    isRequestOrderLoading,
    isValidatePromoCodeLoading,
    itemDescription,
    paymentMethod,
    isCheckedTermsAndCondition
  } = useSelector((state: RootState) => state.RiderforADayModel);

  const isLoading = isRequestOrderLoading || isValidatePromoCodeLoading;
  const history = useHistory();

  const handleRequestOrder = async () => {
    analyticEvent(Event.RentalConfirmation);
    dispatch.RiderforADayModel.updateState({
      isCheckedTermsAndCondition: true
    });
    const res = await dispatch.RiderforADayModel.requestOrder();
    if (res?.isSuccess) {
      setTimeout(() => {
        history.push("/order-history-rental");
        dispatch.Locations.resetState();
      }, 1000);
    }
  };

  const [termsAndConditionModal, setTermsAndConditionModal] = useState(false);
  let handleTermsAndConditionModal = async (e: React.MouseEvent) => {
    e.preventDefault();
    setTermsAndConditionModal(true);
  };

  return (
    <>
      <MainContainer>
        {isLoading && <Loader />}
        {/* <WalletBalance /> */}

        <Row className="h-screen ml-100">
          <Col
            lg="4"
            className="position-relative left-column border-right px-0"
          >
            <div className="order-container scrollable-container">
              <ItemDescription
                itemDescription={itemDescription}
                onDescriptionChange={(e) =>
                  dispatch.RiderforADayModel.updateState({
                    itemDescription: e.target.value
                  })
                }
                onNotesChange={(e) =>
                  dispatch.RiderforADayModel.updateState({
                    deliveryInstruction: e.target.value //notes
                  })
                }
              />

              <PaymentMethod />

              <div className="flex pt-1 pb-3 px-6">
                <div className="checkmark-container">
                  <input
                    type="checkbox"
                    id="topping"
                    name="topping"
                    value="Paneer"
                    checked={isCheckedTermsAndCondition}
                    className="rental-checkbox"
                    disabled
                  />
                  <span className="checkmark"></span>
                </div>
                <Button
                  bg="none"
                  width="none"
                  flex="flex"
                  padding="none"
                  onClick={handleTermsAndConditionModal}
                >
                  <Span padding="normal">I've read and accept the</Span>
                  <Span weight="semi" color="orange" padding="none">
                    Terms and Condition
                  </Span>
                </Button>
              </div>
            </div>
            <div className="p-3 absolute inset-x-0 bottom-0">
              <GridContainer grid="two">
                <Button
                  padding="py-3"
                  onClick={() => {
                    history.push("/rental");
                  }}
                >
                  <span className="font-weight-bold">Back</span>
                </Button>

                <Button
                  padding="py-3"
                  disabled={paymentMethod === "" ? true : false}
                  onClick={handleRequestOrder}
                >
                  <span className="font-weight-bold">Confirm Order</span>
                </Button>
              </GridContainer>
            </div>
          </Col>
          <Col lg="8" className="p-0 position-relative map-section">
            <div className="map-view">
              <Map />
            </div>
          </Col>
        </Row>
      </MainContainer>

      {termsAndConditionModal && (
        <TermsAndConditionModal
          setTermsAndConditionModal={setTermsAndConditionModal}
        />
      )}
    </>
  );
};

export default RiderOrder;
