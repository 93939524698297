/* eslint-disable import/no-anonymous-default-export */
import { ModalContainer } from "../../../../common/Container";
import { Span } from "../../../../common/Span";
import moment from "moment";
import { IoMdCloseCircle } from "react-icons/io";
import { RENTALSTATUS } from "../../../../constants";

type Props = {
  setHistoryModal: Function;
  rentals: any;
};

export default ({ setHistoryModal, rentals }: Props) => {
  return (
    <>
      <ModalContainer width="modal-xs">
        <div className="modal_rental_header">
          <div>
            <Span size="2xl" color="orange" padding="none">
              Transaction History
            </Span>
          </div>

          <div className="my-auto">
            <IoMdCloseCircle
              onClick={() => setHistoryModal(false)}
              className="modal_rental_close"
            />
          </div>
        </div>
        <div className="modal_rental_body">
          <ul className="timeline">
            {rentals?.statusHistory
              ?.sort((a, b) => (a.sequenceNo > b.sequenceNo ? 1 : -1))
              .map((sched, index: number) => (
                <li key={index}>
                  <ul>
                    <li>
                      <p>
                        <Span
                          transform="capitalize"
                          padding="none"
                          size="base"
                          weight="semi"
                        >
                          {RENTALSTATUS[sched?.status]}
                        </Span>
                      </p>
                      <Span padding="none" color="lightgrey">
                        {moment(sched?.createdAt?.toDate()).format(
                          "MMMM DD, YYYY, h:mm:ss A"
                        )}
                      </Span>
                    </li>
                  </ul>
                </li>
              ))}
          </ul>
        </div>

        <div></div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
