import "firebase/analytics";
import firebase from "../Firebase"
export const Event = {
  Signup: 'signup', //Cannot sign up Web
  Login: 'login', //Done
  UpdateProfile: 'update_profile', //Cannot be updated
  ClickOnFoodIcon: 'click_food_icon', //No Food Web
  ClickOnExpressIcon: 'click_express_icon', //Done
  ClickOnRewardIcon: 'click_reward_icon',
  ClickOnMartIcon: 'click_mart_icon',
  ClickOnRentalIcon: 'click_rental_icon',
  RentalSearchedPickup: 'rental_searched_pickup',
  RentalSelectedPickup: 'rental_selected_pickup',
  RentalConfirmation: 'rental_confirmation',

  ApplyVoucher: 'apply_voucher',
  CreateExpressOrder: 'create_express_order',
  CreateEZBuyOrder: 'create_ez_buy_order',
  CreateFoodOrder: 'create_food_order',
  EnterReferralCode: 'enter_referral_code',
  FoodAddToCart: 'food_add_to_cart',

  ExpressSearchedPickup: 'express_searched_pickup',
  ExpressSearchedDropoff: 'express_searched_dropoff',
  ExpressSelectedPickup: 'express_selected_pickup',
  ExpressSelectedDropoff: 'express_selected_dropoff',
  ExpressConfirmation: 'express_confirmation',

  ShopSelectRestaurant: 'shop_select_restaurant',
  FoodSearchedLocation: 'food_searched_location',
  FoodSelectLocation: 'food_select_location',
  FoodConfirmation: 'food_confirmation',

  ShopClick: 'shop_click',
  ShopSearchedLocation: 'shop_searched_location',
  ShopSelectLocation: 'shop_select_location',
  ShopSelectShop: 'shop_select_shop',
  ShopAddToCart: 'shop_add_to_cart',
  ShopConfirmation: 'shop_confirmation',
  
  EzbuyClick: 'ezbuy_click',
  EzbuySearchedMerchant: 'ezbuy_searched_merchant',
  EzbuySelectMerchant: 'ezbuy_select_merchant', 
  EzbuySearchedLocation: 'ezbuy_searched_location',
  EzbuySelectLocation: 'ezbuy_select_location',
  EzbuyConfirmation: 'ezbuy_confirmation',
  EzbuyOrder: 'ezbuy_order',

}

export const analyticEvent = async (eventName: string, valueObject?: any) => {
  try {
    firebase.analytics().logEvent(eventName,valueObject)
  } catch (error) {
    console.log('analyticEvent Error:', error)
  }
}
export const setAnalyticUserId = async (userId: string, phoneNumber: string) => {
  try {
    const userIdStr = `${userId}|${phoneNumber}`
    firebase.analytics().setUserId(userIdStr)
  } catch (error) {
    console.log('setAnalyticUserId Error:', error)
  }
}
