/* eslint-disable import/no-anonymous-default-export */
import { ModalContainer } from "../../../common/Container";
import { Button } from "../../../common/Button";
import { Span } from "../../../common/Span";
import { DATA_TYPE } from "../../../constants";
import { formatToDate } from "../../../common/utils/helpers";

type Props = {
  setHistoryModal: Function;
  order: any;
  delivery: any;
  datatype: string;
};

export default ({ setHistoryModal, order, delivery, datatype }: Props) => {
  return (
    <>
      <ModalContainer width="modal-xs">
        <div className="px-20 py-4 whitespace-normal text-center ">
          <Span size="2xl" weight="bold" color="orange">
            Transaction History
          </Span>
        </div>

        <div>
          <div className="m-2">
            <p>
              <Span transform="capitalize" weight="semi">
                Created
              </Span>
            </p>
            <p>
              <Span>
                {datatype === DATA_TYPE.open
                  ? formatToDate(order?.createdAt?.toDate())
                  : formatToDate(order?.createdAt)}
              </Span>
            </p>
          </div>

          {delivery?.acceptedAt ? (
            <>
              <div className="m-2">
                <p>
                  <Span transform="capitalize" weight="semi">
                    Accepted
                  </Span>
                </p>
                <p>
                  <Span>
                    {datatype === DATA_TYPE.open
                      ? formatToDate(delivery?.acceptedAt?.toDate())
                      : formatToDate(delivery?.acceptedAt)}
                  </Span>
                </p>
              </div>
            </>
          ) : (
            <></>
          )}

          {delivery?.pickedUpAt ? (
            <>
              <div className="m-2">
                <p>
                  <Span transform="capitalize" weight="semi">
                    Ongoing
                  </Span>
                </p>
                <p>
                  <Span>
                    {datatype === DATA_TYPE.open
                      ? formatToDate(delivery?.pickedUpAt?.toDate())
                      : formatToDate(delivery?.pickedUpAt)}
                  </Span>
                </p>
              </div>
            </>
          ) : (
            <></>
          )}

          {order?.status === "delivered" && (
            <>
              <div className="m-2">
                <p>
                  <Span transform="capitalize" weight="semi">
                    Delivered
                  </Span>
                </p>
                <p>
                  <Span>
                    {datatype === DATA_TYPE.open
                      ? formatToDate(delivery?.deliveredAt?.toDate())
                      : formatToDate(delivery?.deliveredAt)}
                  </Span>
                </p>
              </div>
            </>
          )}

          {order?.status === "canceled" && (
            <>
              <div className="m-2">
                <p>
                  <Span transform="capitalize" weight="semi">
                    Canceled
                  </Span>
                </p>
                <p>
                  <Span>
                    {datatype === DATA_TYPE.open
                      ? formatToDate(order?.canceledAt?.toDate())
                      : formatToDate(order?.canceledAt)}
                  </Span>
                </p>
              </div>
            </>
          )}
        </div>

        <Button
          width="none"
          bg="white-only"
          padding="p-2"
          margin="mx"
          type="grey"
          weight="semi"
          onClick={() => setHistoryModal(false)}
        >
          Cancel
        </Button>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
