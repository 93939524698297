/* eslint-disable eqeqeq */
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store";
import { useHistory } from "react-router-dom";
import "./Price.scss";
import moment from "moment";
import { Button } from "../../../common/Button";

const PriceMobileResponsive = () => {
  const history = useHistory();
  const { Order, OrderConfirmation } = useDispatch<Dispatch>();
  const { scheduleType, isScheduledSelected } = useSelector(
    (state: RootState) => state.Order
  );
  const { locations, dropOff, stops, pickup } = useSelector(
    (state: RootState) => state.Locations
  );
  const { confirmedLocations } = useSelector(
    (state: RootState) => state.OrderConfirmation
  );

  const { vehicleDescription } = useSelector<RootState>(
    (state: RootState) => state.Order
  ) as any;

  let hasInvalidPickup =
    [pickup].filter(
      (e) =>
        (e.lat === 0 && e.lng === 0) ||
        e.locationAddress === "" ||
        e.contactPhone.length != 10 ||
        !e.isSelected ||
        !e.valid
    ).length > 0;

  let hasInvalidDestination =
    [dropOff, ...stops].filter(
      (e) =>
        (e.lat === 0 && e.lng === 0) ||
        e.locationAddress === "" ||
        e.deliveryInstruction === "" ||
        e.itemCategory === "" ||
        e.contactPhone.length != 10 ||
        !e.isSelected ||
        !e.valid
    ).length > 0;

  return (
    <div className="p-3 justify-end mobile-visible">
      <Button
        disabled={
          hasInvalidDestination ||
          hasInvalidPickup ||
          !vehicleDescription ||
          !isScheduledSelected
        }
        padding="px-12"
        width="none"
        onClick={() => {
          if (scheduleType === "2") {
            Order.updateState({
              schedule: moment(moment.now()).format("LLLL")
            });
          }
          if (locations?.length !== confirmedLocations?.length) {
            Order.updateState({
              itemDescription: ""
            });
          } else {
            let results: boolean[] = [];
            locations.forEach((elem, index) => {
              results.push(
                elem.lat === confirmedLocations[index].lat &&
                  elem.lng === confirmedLocations[index].lng
              );
            });

            if (results.includes(false)) {
              Order.updateState({
                itemDescription: ""
              });
            }
          }
          OrderConfirmation.updateState({
            confirmedLocations: locations.map((location) => ({
              ...location
            }))
          });
          Order.calculateFare();
          history.push("/order-confirmation");
        }}
      >
        <span className="font-weight-bold">Next</span>
      </Button>
    </div>
  );
};

export default PriceMobileResponsive;
