/* eslint-disable import/no-anonymous-default-export */
import { ModalContainer } from "../../../common/Container";
import { Button } from "../../../common/Button";
import { Span } from "../../../common/Span";
import { Input } from "../../../common/Input";
import useCopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";

type Props = {
  setShareLinkModal: any;
  referenceNumber: any;
};

export default ({ setShareLinkModal, referenceNumber }: Props) => {
  const [value, copy] = useCopyToClipboard();

  return (
    <>
      <ModalContainer width="modal-s">
        <div className="p-10 whitespace-normal">
          <div className="my-2.5">
            <Span size="2xl" weight="bold" padding="none" color="orange">
              Copy and Share
            </Span>
          </div>

          <div className="flex">
            <Input
              width="full"
              value={`${window.location.origin}/share/${referenceNumber}/${referenceNumber}`}
              readOnly
            />

            <span
              className="base whitespace-nowrap my-auto cursor-pointer mx-2"
              onClick={() =>
                copy(
                  `${window.location.origin}/share/${referenceNumber}/${referenceNumber}`
                )
              }
            >
              Copy Link
            </span>
          </div>

          {value ? <span style={{ color: "red" }}>Copied!</span> : null}

          <div className="text-right my-3">
            <Button
              width="none"
              padding="p-2"
              weight="semi"
              onClick={() => setShareLinkModal(false)}
            >
              Done
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
