import { ILocations } from "../../types/ILocations";

interface Coordinates {
  lat: number;
  lng: number;
}

export const isWithinTheRadius = (checkPoint: Coordinates, centerPoint: Coordinates, kilometer: number) => {
  var kilometerLat = 40000 / 360;
  var kilometerLng = Math.cos(Math.PI * centerPoint.lat / 180.0) * kilometerLat;
  var degreeLat = Math.abs(centerPoint.lat - checkPoint.lat) * kilometerLat;
  var degreeLng = Math.abs(centerPoint.lng - checkPoint.lng) * kilometerLng;

  return Math.sqrt(degreeLng * degreeLng + degreeLat * degreeLat) <= kilometer;
}

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) => {
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = lon1 - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist = dist * 1.609344;
  }
  if (unit === "N") {
    dist = dist * 0.8684;
  }
  return dist;
};

export const optimizeRoutes = (d: ILocations, oD: ILocations = []) => {
  let destinations = [...d]
  let optimizedDestinations = [...oD]
  if (destinations.length > 0) {

    if (optimizedDestinations.length === 0) {
      // @ts-ignore
      optimizedDestinations.push(destinations.shift())
    }

    for (let i = 0; i < destinations.length; i++) {
      destinations[i]["distance"] = calculateDistance(
        optimizedDestinations[optimizedDestinations.length-1]["lat"],
        optimizedDestinations[optimizedDestinations.length-1]["lng"],
        destinations[i]["lat"],
        destinations[i]["lng"],
        "K"
      );
    }

    destinations = destinations.sort((a: any, b: any) => {
      return a.distance - b.distance;
    });

    // @ts-ignore
    optimizedDestinations.push(destinations.shift())
    return optimizeRoutes(destinations, optimizedDestinations)
  } 
    
  return optimizedDestinations;
}

export const checkLocationIsInRegion = (regionLat: number, regionLng: number, lat: number, lng: number) => {
  return (regionLat - 1 < lat && regionLat + 1 >= lat) && (regionLng  - 1 < lng && regionLng + 1 > lng)
}

export const checkLocationIsAllowed = (lat: number, lng: number) => {

  let coordinates = { lat: 12.8797, lng: 121.7740 };
  let radius = 5000;

  const checkPoint = { lat, lng };
  
  return isWithinTheRadius(checkPoint, coordinates, Number(radius));
}