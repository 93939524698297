/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import { ModalContainer } from "../../common/Container";
import { ModalCloseButton, Button } from "../../common/Button";
import { Span } from "../../common/Span";
import { IoClose } from "react-icons/io5";

import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { currencyCommaFormat } from "../../common/utils";
import walletviolet from "../../assets/img/Alleasy_walletviolet.png";
import card from "../../assets/img/web/cardalleasy.png";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

type Props = {
  setBalanceWalletModal: Function;
};

export default ({ setBalanceWalletModal }: Props) => {
  const { totalFee } = useSelector((state: RootState) => state.Booking);

  const balance: any = useSelector<RootState>(
    (state: RootState) => state.Wallet.balance
  );

  const [TopUp, setTopUp] = useState(false);
  const [PaymentMethod, setPaymentMethod] = useState(false);

  return (
    <>
      <ModalContainer width="primary" position="center">
        <div className="flex pt-1.5">
          <ModalCloseButton onClick={() => setBalanceWalletModal(false)}>
            <IoClose className="text-2xl" />
          </ModalCloseButton>

          <div className="p-5">
            <Span weight="semi" size="lg" padding="none" color="darkred">
              You have to pay
            </Span>
            <p className="relative">
              <Span
                margin="none"
                padding="none"
                color="darkgrey"
                position="top"
              >
                ₱
              </Span>
              <Span
                margin="none"
                padding="p-2.5"
                size="3xl"
                color="darkred"
                weight="semi"
              >
                {currencyCommaFormat(totalFee, 2)}
              </Span>
            </p>
          </div>
        </div>

        <div
          className="mx-5 mb-40 cursor-pointer"
          onClick={() => setTopUp(true)}
        >
          <div className="flex justify-between p-1 border-b border-solid border-getgrey-1 my-2">
            <div className="flex">
              <img className="check-icon" src={walletviolet} alt="" />
              <div>
                <Span size="base" color="darkred">
                  Wallet
                </Span>
                <p>
                  <Span>Balance ₱ {balance != null ? balance : "0"}</Span>
                </p>
              </div>
            </div>
            <IoIosArrowForward className="my-auto" />
          </div>
        </div>

        {TopUp && (
          <div className="bg-white-1 mt-8 text-center w-full px-4 absolute mx-auto">
            <Span size="2xl" weight="semi">
              Top Up
            </Span>

            <Button
              bg="background-grey"
              padding="p-2"
              align="left"
              margin="top"
            >
              <Span size="4xl" color="darkred">
                ₱300.00
              </Span>
            </Button>
            <Button
              bg="background-grey"
              padding="p-2"
              align="left"
              margin="top"
            >
              <Span size="4xl" color="darkred">
                ₱500.00
              </Span>
            </Button>
            <Button
              bg="background-grey"
              padding="p-2"
              align="left"
              margin="top"
            >
              <Span size="4xl" color="darkred">
                ₱1000.00
              </Span>
            </Button>
            <Button
              radius="full"
              margin="top"
              onClick={() => setPaymentMethod(true)}
            >
              Top up
            </Button>
          </div>
        )}

        {PaymentMethod && (
          <div className="bg-white-1 mt-8 text-center w-full px-4 absolute mx-auto">
            <Span size="2xl" weight="semi">
              Top Up
            </Span>
            <p>
              <Span size="4xl" color="darkred">
                ₱300.00
              </Span>
            </p>

            <div className="text-left mt-14 mb-36">
              <Span size="base" weight="semi" padding="none">
                Payment Method
              </Span>
              <div className="flex border-b border-solid border-getgrey-1 py-2 my-2">
                <Link to="./topupsummary">
                  <img
                    className="h-12 hover:opacity-50 cursor-pointer"
                    src={card}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        )}
      </ModalContainer>
      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
