/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import { Col, Form } from "react-bootstrap";
import { IVehicle } from "../../../types/IVehicle";
import "./AddOns.scss";
import { useEffect, useState } from "react";

import { Span } from "../../../common/Span";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import CashHandlingContent from "./CashHandlingContent";
import moment from "moment";

import { getDefaultRates } from "../../../common/api/endpoints/price";

let fare_table = {
  motorcycle: {
    baseFare: 50,
    insulatedBag: 0,
    premiumService: 50,
    standardPricePerKM: 6,
    additionalStopPrice: 40,
    cashHandling: 30,
    remittance: 30,
    purchaseService: 50,
    queueingService: 70,
    overweightHandling: 0,
    driverTip: 50,
    commission: 0.15
  },
  mpv200: {
    baseFare: 100,
    standardPricePerKM: 17,
    additionalStopPrice: 45,
    premiumService: 105,
    cashHandling: 30,
    remittance: 30,
    purchaseService: 100,
    queueingService: 60,
    overweightHandling: 80,
    driverTip: 50,
    commission: 0.15
  },
  mpv300: {
    baseFare: 120,
    standardPricePerKM: 20,
    additionalStopPrice: 45,
    premiumService: 50,
    cashHandling: 30,
    remittance: 30,
    purchaseService: 100,
    queueingService: 60,
    overweightHandling: 80,
    driverTip: 50,
    commission: 0.15
  },
  mpv600: {
    baseFare: 310,
    standardPricePerKM: 25,
    additionalStopPrice: 50,
    premiumService: 50,
    cashHandling: 30,
    remittance: 30,
    purchaseService: 100,
    queueingService: 60,
    overweightHandling: 80,
    driverTip: 50,
    commission: 0.15,
    helper: 90
  },
  lt1000: {
    baseFare: 390,
    standardPricePerKM: 25,
    additionalStopPrice: 50,
    premiumService: 50,
    cashHandling: 30,
    remittance: 30,
    purchaseService: 100,
    queueingService: 60,
    overweightHandling: 80,
    driverTip: 50,
    commission: 0.15,
    helper: 90
  },
  truck: {
    baseFare: 1800,
    standardPricePerKM: 45,
    additionalStopPrice: 50,
    premiumService: 0,
    cashHandling: 30,
    remittance: 30,
    purchaseService: 100,
    queueingService: 60,
    overweightHandling: 80,
    commission: 0.18,
    insulatedBox: 0
  }
};

let matchFare = (addons) => {
  switch (addons) {
    case "insulatedBag":
      return "insulatedBox";
    case "queueingService":
      return "queueingFee";
    case "premiumService":
      return "premiumServiceFee";
    case "cashHandling":
      return "cashHandlingFee";
    case "remittance":
      return "remittanceFee";
    case "purchaseService":
      return "purchaseServiceFee";
    case "overweightHandling":
      return "overweightHandlingFee";
    case "helper":
      return "helper";
    default:
      return addons;
  }
};

const AddOns = () => {
  const [rate, setRate] = useState(fare_table);
  const dispatch = useDispatch<Dispatch>();

  const selectedVehicle = useSelector<RootState>((state: RootState) =>
    state.Order.vehicles.find((vehicle: IVehicle) => vehicle.isSelected)
  ) as IVehicle;

  const rates = useSelector<RootState>(
    (state: RootState) => state.Order.rates
  ) as any;

  const addOns = useSelector<RootState>(
    (state: RootState) => state.Order.addOns
  ) as any;

  const {
    driverTip,
    amountToBeCollected,
    isRemitOrCollect,
    schedule,
    collectAmountFrom
  } = useSelector<RootState>((state: RootState) => state.Order) as any;

  const isCashHandlingModal: any = useSelector(
    (state: RootState) => state.Order.isCashHandlingModal
  );

  // Rate Fare
  const defaultRate = async () => {
    let rate = await getDefaultRates();
    dispatch.Order.setRates(rate);
  };

  useEffect(() => {
    defaultRate();
  }, []);

  useEffect(() => {
    let selected_rate = rates.find(
      (r) => r.vehicleType == selectedVehicle.vehicleType
    );
    setRate({
      premiumService100: 100,
      driverTip: driverTip ?? 0,
      helper: selected_rate?.helperFee?.min,
      ...selected_rate
    });
  }, [selectedVehicle, rates, driverTip]);

  const { isAddOnsLoading } = useSelector<RootState>(
    (state: RootState) => state.Order
  ) as any;

  if (!selectedVehicle && (rates ?? []).length < 1) {
    return null;
  }

  const onSelect = (elem) => {
    {
      let isChecked = !addOns[elem].isChecked;

      if (elem === "driverTip") {
        if (!isChecked) {
          dispatch.Order.updateState({
            driverTip: 0
          });
          dispatch.Order.setAddOnsIsChecked({
            target: elem,
            value: isChecked ? true : false
          });
        } else {
          Swal.fire({
            title: "Driver Tip",
            input: "text",
            inputLabel: "Please enter amount",
            inputValue: driverTip,
            showCancelButton: true,
            inputValidator: (value) => {
              if (!/^[1-9]\d*$/.test(value)) {
                return "Please enter valid amount!";
              }
              return null;
            }
          }).then(({ value }) => {
            if (!value) {
              return;
            }
            dispatch.Order.updateState({
              driverTip: parseInt(value)
            });
            dispatch.Order.setAddOnsIsChecked({
              target: elem,
              value: isChecked ? true : false
            });
          });
        }

        return;
      }

      if (elem === "cashHandling") {
        if (!isChecked) {
          dispatch.Order.updateState({
            amountToBeCollected: 0,
            remittanceFee: 0,
            cashHandling: 0,
            isRemitOrCollect: false,
            collectAmountFrom: "sender"
          });
          dispatch.Order.setAddOnsIsChecked({
            target: elem,
            value: isChecked ? true : false
          });
        } else {
          dispatch.Order.updateState({
            isCashHandlingModal: true
          });
        }
        return;
      }

      if (elem === "helper") {
        if (!isChecked) {
          dispatch.Order.updateState({
            isHelperSelected: false
          });
          dispatch.Order.setAddOnsIsChecked({
            target: elem,
            value: isChecked ? true : false
          });
        } else {
          dispatch.Order.updateState({
            isHelperSelected: true
          });
          dispatch.Order.setAddOnsIsChecked({
            target: elem,
            value: isChecked ? true : false
          });
        }
        return;
      }

      dispatch.Order.setAddOnsIsChecked({
        target: elem,
        value: isChecked ? true : false
      });
    }
  };

  const { availableAddOns } = selectedVehicle;

  // const isQouteValid = scheduleType === "2" && isHelperSelected;
  let date_today = moment(moment.now()).format("LL");
  let schedule_date = moment(schedule).format("LL");

  const helperValidation = date_today === schedule_date;

  return (
    <div className="p-3">
      {isAddOnsLoading ? (
        <>
          <div className="flex justify-content-center">
            <Spinner
              variant="primary"
              animation="border"
              className="justify-self-center mx-auto my-auto spinner"
            />
          </div>
        </>
      ) : (
        <>
          <Span padding="py-2" color="lightgrey" size="base" weight="semi">
            ADDITIONAL SERVICES
          </Span>
          {availableAddOns.map((elem: any, index: number) => {
            return (
              <div className="my-3" key={index}>
                <Col className="px-0 h-100 flex justify-between">
                  <div>
                    <Form.Check
                      key={index}
                      className="addOns-container-checkbox"
                    >
                      <Form.Check.Input
                        type={"checkbox"}
                        checked={addOns[elem].isChecked}
                        onChange={() => onSelect(elem)}
                        // disabled={elem === "helper" && helperValidation}
                      />
                      <Form.Check.Label className="text-getgrey-2">
                        <span>{addOns[elem].name}</span>
                        {elem === "cashHandling" && amountToBeCollected > 0 && (
                          <div>
                            <span className="text-xs">{`Amount To Be Collected : ₱${amountToBeCollected} from ${collectAmountFrom}`}</span>
                            {isRemitOrCollect ? (
                              <p>
                                <span className="text-xs text-getorange-1">
                                  Amount will be remitted electronically
                                </span>
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}

                        {/* {elem === "helper" && helperValidation && (
                          <div>
                            <span className="text-xs text-getorange-1">
                              This add-on is for scheduled bookings only. <br />
                              Schedule should be at least 24 hours from now.
                            </span>
                          </div>
                        )} */}
                      </Form.Check.Label>
                    </Form.Check>
                  </div>

                  <div>
                    <Span>+₱ {rate[matchFare(elem)]}</Span>
                  </div>
                </Col>
              </div>
            );
          })}
        </>
      )}

      {isCashHandlingModal && <CashHandlingContent />}
    </div>
  );
};

export default AddOns;
