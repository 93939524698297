import { Span } from "../../../common/Span";
import { Card } from "../../../common/Container";
import { ORDERSTATUS, VEHICLE } from "../../../constants";
import { useHistory } from "react-router";

const Content = ({ orderList }: any) => {
  const history = useHistory();

  return (
    <div className="responsive-content">
      {orderList?.length > 0 ? (
        <>
          {orderList?.map((item) => (
            <>
              <div
                onClick={() => {
                  history.push("/order-list/" + item?.orderId);
                }}
                className="cursor-pointer mx-2"
              >
                <Card margin="top">
                  <div className="flex justify-between">
                    <Span
                      margin="none"
                      size="base"
                      color="grey"
                      whitespace="nowrap"
                    >
                      Order No.
                    </Span>
                    <Span margin="none" size="base" align="right">
                      {item?.orderCode}
                    </Span>
                  </div>
                  <div className="flex justify-between">
                    <Span
                      margin="none"
                      size="base"
                      color="grey"
                      whitespace="nowrap"
                    >
                      Vehicle Type
                    </Span>
                    <Span
                      margin="none"
                      size="base"
                      align="right"
                      transform="uppercase"
                    >
                      {VEHICLE[item?.vehicleType]}
                    </Span>
                  </div>
                  <div className="flex justify-between">
                    <Span
                      margin="none"
                      size="base"
                      color="grey"
                      whitespace="nowrap"
                    >
                      Status
                    </Span>
                    <Span margin="none" size="base" align="right">
                      {ORDERSTATUS[item?.status]}
                    </Span>
                  </div>
                  <div className="flex justify-between">
                    <Span
                      margin="none"
                      size="base"
                      color="grey"
                      whitespace="nowrap"
                    >
                      Pickup Location
                    </Span>
                    <Span margin="none" size="base" align="right">
                      {item?.pickup?.locationAddress}
                    </Span>
                  </div>

                  <div className="flex justify-between">
                    <Span
                      margin="none"
                      size="base"
                      color="grey"
                      whitespace="nowrap"
                    >
                      Stops
                    </Span>
                    <Span margin="none" size="base" align="right">
                      {item?.additionalStops}
                    </Span>
                  </div>

                  <div className="flex justify-between">
                    <Span
                      margin="none"
                      size="base"
                      color="grey"
                      whitespace="nowrap"
                    >
                      Destination
                    </Span>
                    <Span margin="none" size="base" align="right">
                      {item?.dropOff?.locationAddress}
                    </Span>
                  </div>

                  <div className="flex justify-between">
                    <Span
                      margin="none"
                      size="base"
                      color="grey"
                      whitespace="nowrap"
                    >
                      Item Description
                    </Span>
                    <Span margin="none" size="base" align="right">
                      {item?.itemDescription}
                    </Span>
                  </div>

                  <div className="flex justify-between">
                    <Span margin="none" size="base" color="grey">
                      Total Charge
                    </Span>
                    <Span
                      margin="none"
                      size="base"
                      align="right"
                    >{`₱${parseFloat(item?.totalCharge)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</Span>
                  </div>
                </Card>
              </div>
            </>
          ))}
        </>
      ) : (
        <>
          <div className="w-full mx-auto text-center bg-palette-lightblue p-2">
            <Span size="base">No Bookings Yet!</Span>
          </div>
        </>
      )}
    </div>
  );
};

export default Content;
