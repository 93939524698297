/* eslint-disable no-sequences */
/* eslint-disable eqeqeq */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store";
import "./PaymentMethod.scss";
import payment from "../../assets/img/rental/paymentmethod_icon.png";

import swal from "sweetalert2";

import WalletModal from "./WalletModal";
import BalanceWalletModal from "./BalanceWalletModal";

const PaymentMethod = () => {
  const handleChange = (e: any) => {
    if (e.target.value === "cash_on_delivery") {
      dispatch.Order.updateState({
        paymentMethod: "cash_on_delivery",
        billTo: "pickup",
        orderModified: true
      });
    } else if (e.target.value === "billing") {
      if (!billingEnable) {
        swal.fire(
          "Billing is not enabled",
          "Please contact admin to enable your billing"
        );
        return;
      }

      if (billings.length === 1) {
        dispatch.Order.updateState({
          paymentMethod: "billing",
          billTo: billings[0].billingUid,
          orderModified: true
        });
        return;
      }

      swal
        .fire({
          title: "Billing",
          text: "Please select billing account",
          input: "select",
          inputOptions: (billings ?? []).reduce(
            (obj, item) => ((obj[item.billingUid] = item.name), obj),
            {}
          )
        })
        .then(({ value }) => {
          if (value) {
            dispatch.Order.updateState({
              paymentMethod: "billing",
              billTo: value,
              orderModified: true
            });
          }
        });
    }

    if (e.target.value === "all_easy") {
      if (typeof walletBalance === "undefined") {
        dispatch.User.updateState({
          isAllEasyModalOpen: true
        });
        return;
      } else if (
        (totalFee ?? 0) > parseFloat(walletBalance.replace(",", "") ?? "0")
      ) {
        swal.fire(
          "Low Balance",
          "Please top-up on you alleasy app to continue"
        );
        return;
      }
      dispatch.Order.updateState({
        paymentMethod: "all_easy",
        orderModified: true
      });
      // dispatch.Wallet.getWalletBalance();
    }
  };

  const dispatch = useDispatch<Dispatch>();

  const walletBalance = useSelector<RootState>(
    (state: RootState) => state.Wallet.balance
  ) as string | undefined;

  const billings = useSelector<RootState>(
    (state: RootState) => state.User.userInfo?.billings ?? []
  ) as Array<any>;

  const billingEnable = (billings ?? []).length > 0;

  const {
    totalFee,
    paymentMethod,
    billTo: billToUid
  } = useSelector((state: RootState) => state.Order);

  const [modalWallet, setWalletModal] = useState(false);
  const [modalBalanceWallet, setBalanceWalletModal] = useState(false);

  // Billing - name
  const billName = (billings ?? []).find(
    (b) => b.billingUid == billToUid
  )?.name;

  return (
    <>
      <div className="px-4">
        <div className="flex">
          <img src={payment} alt={payment} className="payment-icon" />
          <h6 className="my-auto">Payment Method</h6>
        </div>
        <div className="payment-method__container">
          <select
            className="dropdownArrow p-2.5"
            onChange={(e: any) => handleChange(e)}
            value={paymentMethod}
          >
            <option value="" selected disabled hidden>
              Select Payment Method
            </option>
            <option value="cash_on_delivery"> Cash </option>
            <option value="billing"> Billing {billName}</option>
            {/* <option value="all_easy"> All Easy Wallet </option> */}
          </select>
        </div>

        {modalWallet && <WalletModal setWalletModal={setWalletModal} />}

        {modalBalanceWallet && (
          <BalanceWalletModal setBalanceWalletModal={setBalanceWalletModal} />
        )}
      </div>
    </>
  );
};

export default PaymentMethod;
