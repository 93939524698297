import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store";
import Datetime from "react-datetime";

import { Button } from "../../../common/Button";
import moment from "moment";
import { useState } from "react";
import TimePicker from "react-time-picker";

const SchedulePicker = ({ setIsDatePickerShown }: any) => {
  const dispatch = useDispatch<Dispatch>();

  const isHelperSelected: any = useSelector(
    (state: RootState) => state.Order.isHelperSelected
  );

  const yesterday = isHelperSelected ? new Date() : moment().subtract(1, "day");
  const disablePastDates = (current: any) => {
    return current?.isAfter(yesterday);
  };

  const minDate = isHelperSelected
    ? moment(moment.now()).add(1, "day").format("LL")
    : moment(moment.now()).format("LL");
  // 30 mins will be remove if Helper Fee is selected
  const minTime = isHelperSelected
    ? moment(moment.now()).format("HH:mm")
    : moment(moment.now()).add(30, "m").format("HH:mm");
  const [date, setDate] = useState<string>(minDate);
  const [time, setTime] = useState<string>(minTime);

  const DateWithTimeComponent = (viewMode, renderDefault) => {
    return (
      <div>
        {renderDefault()}
        <TimePicker
          onChange={(e: any) => {
            setTime(e);
          }}
          value={time}
          format={"HH:mm"}
          disableClock
          clearIcon={null}
          className="w-100 text-center h4 my-2"
        />
        {date === minDate && time < minTime && (
          <p
            className="text-danger text-center py-2 px-3"
            style={{ fontSize: 14 }}
          >
            <>
              Schedule of order must be atleast 30 minutes before the delivery
              time
            </>
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="position-absolute date-picker d-flex flex-column justify-content-center align-items-center">
      <Datetime
        locale="ph"
        open={true}
        isValidDate={disablePastDates}
        initialValue={moment(minDate)}
        input={false}
        timeFormat={false}
        closeOnSelect
        renderView={DateWithTimeComponent}
        closeOnClickOutside
        className="schedule-selection__dateTime"
        onChange={(e: any) => {
          if (moment(e).isValid()) {
            setDate(
              isHelperSelected
                ? moment(e).add(1, "day").format("LL")
                : moment(e).format("LL")
            );
          }
        }}
      />

      <div className="flex justify-end w-250 p-2.5 bg-white-1 rounded-b-lg">
        <Button
          bg="lightgrey"
          width="none"
          type="black"
          style={{ fontSize: "1.2rem", width: "6rem" }}
          onClick={(e) => {
            setIsDatePickerShown(false);
            dispatch.Order.updateState({
              isScheduledSelected: false
            });
          }}
        >
          Cancel
        </Button>
        <div className="p-2"></div>
        <Button
          width="none"
          style={{ fontSize: "1.2rem", width: "6rem" }}
          disabled={date === minDate && time < minTime}
          onClick={() => {
            setIsDatePickerShown(false);
            dispatch.Order.updateState({
              scheduleType: "1",
              schedule: date.concat(` ${time}`)
            });
          }}
        >
          Set
        </Button>
      </div>
    </div>
  );
};

export default SchedulePicker;
