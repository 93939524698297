import { Span } from "../../../../common/Span";

import Location from "../../../../assets/img/rental/pickup_icon.png";
import info from "../../../../assets/img/rental/view_icon.png";

import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

import Info from "./Info";
import PickupLocationAutocomplete from "./PickupLocationAutocomplete";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IVehicles } from "../../../../types/IVehicle";
const PickupLocation = () => {

  const { vehicleDescription } = useSelector<RootState>(
    (state: RootState) => state.RiderforADayModel
  ) as any;

  const vehicles = useSelector<RootState>(
    (state: RootState) => state.RiderforADayModel.vehicles
  ) as IVehicles as any;

  const vehicleSelected = vehicles?.find((v) => v?.isSelected);
 
  return (
    <>
      <div className="px-3">
        <div className="flex">
          <Span color="orange" padding="none" width="none" margin="none">
            Pick-up Location
          </Span>
          {vehicleDescription && (<>
          {["motorcycle", "mpv300", "mpv600", "lt1000"].includes(
            vehicleSelected?.vehicleType
          ) && (
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <button {...bindTrigger(popupState)}>
                    <img src={info} alt={info} className="info" />
                  </button>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      <Info />
                    </Typography>
                  </Popover>
                </div>
              )}
            </PopupState>
          )}
        </>)}
          
        </div>

        <div className="flex">
          <img className="my-auto mx-2 h-5" src={Location} alt={Location} />
          <PickupLocationAutocomplete />
        </div>
      </div>
    </>
  );
};

export default PickupLocation;
