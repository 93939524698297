import { Col, Spinner } from "react-bootstrap";
import "./Price.scss"; 
import { Button } from '../../../../common/Button' 
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../../../store';

const PriceRental = () => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory(); 

  const { 
    isFareDetailsLoading, 
    schedule,
    pickup,
    completeName,
    location,
    phoneNumber,
    isScheduledSelected
  } : any = useSelector((state: RootState) => state.RiderforADayModel);

  const isNextDisabled =  
    schedule[0]?.startDate === "" ||
    pickup === null ||
    [null, ""].includes(completeName) ||
    [null, ""].includes(location) ||
    phoneNumber === "" ||
    phoneNumber?.length !== 10 ? true : false;

  const handleNext = async() => {  
    const res = await dispatch.RiderforADayModel.calculateFare();
    if(res?.isSuccess){
      history.push("/rental-order")
    }  
  } 

  return (
    <div className="flex p-3 absolute inset-x-0 bottom-0 bg-white-1">
      <Col sm="6" className="text-left my-auto">
        <div className="w-100 d-flex align-items-center">
          <h5 className="order-price__amount">
            <div className="flex">
              {isFareDetailsLoading && (
                <Spinner animation="border" className="spinner text-green-500 h-6 w-6 mr-2 z-50" />
              )} 
            </div>
          </h5>
        </div>
      </Col>
      <Col sm="6" className="text-right">
        <Button
          width='none' padding='px-12'
          disabled={isFareDetailsLoading || isNextDisabled ? true : false || !isScheduledSelected}
          onClick={handleNext}>
          <span className="font-weight-bold text-center">Next</span>
        </Button>
      </Col>
    </div>
  );
};

export default PriceRental;
