import { createModel } from "@rematch/core";
import moment from "moment";
import { IDestinations } from "../types";
import { RootModel } from ".";
import { IVehicles } from "../types/IVehicle";
import { getDefaultRates } from "../common/api/endpoints/price";
import { ILocations } from "../types/ILocations";
import { getQuoteDetails, requestRentalOrder } from "../common/api"; 

export interface DriverDetailsProps {
  driverId?: string | null;
  bikerId?: string | null;
  driverName?: string | null;
}

type MapType = "GOOGLE" | "LEAFLET";
type MapPermission = "READ" | "WRITE";

interface Schedule {
  sequence: number;
  startDate: any;
  endDate: any;
}

export interface State {
  numberDrivers: number;
  quoteResult: object | null;
  completeName: string | null;
  phoneNumber: string;
  location: any;
  
  scheduleType: string | null;
  termsAndCondition: string | null;

  promoVoucher: string | null;
  pickup: any;

  rates: Array<any>;
  amountToBeCollected: number;
  driverTip: number;
  description: any;
  isEditMode: boolean;
  isModalShown: boolean;
  isOptimizeLocation: boolean;
  lastUpdate: number;
  optimizeBasedLocations: ILocations | null;
  recentSources: ILocations;
  recentDestinations: ILocations;
  schedule: Array<Schedule>;
  datePickerRanges: Array<any>;
  totalFee: number | null;
  vehicles: any;

  itemDescription: string | null;
  deliveryInstruction: string | null;
  isRequestOrderLoading: boolean;
  requestOrderResponse: any;
  isFareDetailsLoading: boolean;
  orderModified: boolean; // Attribute used to distinguish if need to recalculate fare
  isValidatePromoCodeLoading: boolean;
  isRequestingAddressByLatLng: boolean;
  isLoading: boolean;
  promoCode: string | null;
  isValidPromoCode: boolean | null;
  vehicleType: string | null;
  /**
   * Payment Modes
   * 1 - Cash
   * 2 - GetAll
   */
  paymentMethod: string;
  billTo?: string;
  isValidateDriverIdLoading?: boolean;

   // Vehicle 
   kgCapacity?: number | null;
   cmCapacity?: number | null;
   vehicleDescription?: string;
   vehicleName?: string; 
   isScheduledSelected: boolean,


  /**
   * Validate driver status
   * 0 | null - no processing yet
   * 1 - success
   * 2 - fail
   * 3 - not found
   */
  validateDriverStatus?: number;
  validateDriverError?: string;
  driverDetails?: DriverDetailsProps | null;
  isSavingLocationToFavorite: boolean;
  merchantReference?: string | null;
  mapType?: MapType;
  mapPermission?: MapPermission;
  orderDetails: any;
  isCheckedTermsAndCondition: boolean; 
  scheduleId:  string | null;
  scheduleMethod: "0" | "1" | "2";
}

export const initialState = {
  numberDrivers: 0,
  completeName: null,
  location: null,
  promoVoucher: null,
  pickup: null,
  scheduleType: null, 
  vehicleDescription: "",
  kgCapacity: null,
  cmCapacity: null, 
  isScheduledSelected: false,

  termsAndCondition: null,

  rates: [],
  driverTip: 0,
  amountToBeCollected: 0,
  description: {
    motorcycle: {
      name: 'Motorcyle',
      description: '20kg'
    },
    mpv300: {
      name: 'MPV300',
      description: '300kg'
    },
    mpv600: {
      name: 'MPV600',
      description: '600kg'
    },
    lt1000: {
      name: 'LT1000',
      description: '1000kg'
    },
    truck: {
      name: 'Truck',
      description: '2000kg'
    },
    truck6w: {
      name: '6-Wheeler Truck',
      description: '2000kg'
    },
    truck10w: {
      name: '10-Wheeler Truck',
      description: '2000kg'
    }
  },
  isEditMode: false,
  isModalShown: false,
  isOneWay: true,
  isOptimizeLocation: false,
  isRequestingAddressByLatLng: false,
  isSavingLocationToFavorite: false,
  lastUpdate: moment.now(),
  optimizedDestinations: [],
  recentDestinations: [],
  recentSources: [],
  vehicles: [
    {
      vehicleType: "motorcycle",
      isSelected: true,
      vehicleDescription: "motorcycle",
      description: "20kg",
      availableAddOns: ["insulatedBag", "premiumService", "cashHandling", "purchaseService", "queueingService", "driverTip"],
      location: "Manila"
    }, 
    {
      vehicleType: "mpv300",
      vehicleDescription: "MPV300",
      availableAddOns: ["premiumService", "cashHandling", "overweightHandling", "purchaseService", "queueingService", "driverTip"],
      description: "300kg",
      location: "Manila"
    },
    {
      vehicleType: "mpv600",
      vehicleDescription: "MPV600",
      availableAddOns: ["overweightHandling", "premiumService", "cashHandling", "queueingService", "driverTip"],
      description: "600kg",
      location: "Manila"
    },
    {
      vehicleType: "lt1000",
      vehicleDescription: "LT1000",
      availableAddOns: ["overweightHandling", "premiumService", "cashHandling", "queueingService", "driverTip"],
      description: "1000kg",
      location: "Manila"
    },
    {
      vehicleType: "truck",
      vehicleDescription: "Truck",
      availableAddOns: ["overweightHandling", "premiumService", "cashHandling", "queueingService", "driverTip"],
      description: "2000kg",
      location: "Manila"
    },
    {
      vehicleType: "truck6w",
      vehicleDescription: "6-Wheeler Truck",
      availableAddOns: ["overweightHandling", "premiumService", "cashHandling", "queueingService", "driverTip"],
      description: "3000kg",
      location: "Manila"
     },
    {
      vehicleType: "truck10w",
      vehicleDescription: "10-Wheeler Truck", 
      availableAddOns: ["overweightHandling", "premiumService", "cashHandling", "queueingService", "driverTip"],
      description: "4000kg",
      location: "Manila"
    }
  ],
  vehicleType: "",
  schedule: [
    {
      sequence: 0,
      startDate: moment(moment.now()).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(moment.now()).add(9, 'hours').format("YYYY-MM-DD HH:mm:ss"),
    }
  ],
  datePickerRanges: [
    {
      sequence: 0,
      startDate: moment(moment.now()).format("YYYY-MM-DD HH:mm:ss"),
      endDate: new Date(""),
      key: 'selection'
    }
  ],
  totalFee: null,
  reference: null,
  itemDescription: null,
  deliveryInstruction: null,
  isRequestOrderLoading: false,
  requestOrderResponse: null,
  isFareDetailsLoading: false,
  orderDetails: null,
  orderModified: false,
  isValidatePromoCodeLoading: false,
  isValidPromoCode: null,
  isLoading: false,
  optimizeBasedLocations: null,
  promoCode: null,
  paymentMethod: '',
  billTo: '',
  driverDetails: null,
  merchantReference: null,
  mapType: "GOOGLE",
  mapPermission: "WRITE",
  phoneNumber: '',
  quoteResult: null, 
  isCheckedTermsAndCondition: true, 
  scheduleId: '',
  scheduleMethod: "0",
} as State;

export const RiderforADayModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    /**
     * @name resetState
     * @desc Reset order attributes
     */
    resetState(state) {
      // making sure that pop over were close
      return { ...state, ...initialState };
    },
    /**
     * @name setTripType
     * @desc Sets state.tripType to be 1 | 2
     * @param {1|2} payload { 1 | 2 }
     */
    setTripType(state, payload: 1 | 2) {
      return { ...state, tripType: payload, orderModified: true };
    },
    setRates(state, payload: Array<any>) {
      return { ...state, rates: payload, orderModified: true };
    },
    setIsEditMode(state, payload: boolean) {
      return { ...state, isEditMode: payload };
    },
    setIsModalShown(state, payload: boolean) {
      return { ...state, isModalShown: payload };
    },
    setDestinations(state, payload: IDestinations) {
      return { ...state, destinations: payload };
    },
    setIsOptimizedLocation(state, payload: boolean) {
      return { ...state, isOptimizeLocation: payload, orderModified: true };
    },
    setLastUpdate(state, payload: number) {
      return { ...state, lastUpdate: payload };
    },
    setScheduleAndRange(state, payload: Schedule) {
      return {
        ...state,
        orderModified: true,
        schedule: [...state.schedule, payload],
        datePickerRanges: [...state.datePickerRanges, { ...payload, key: 'selection' }],
      };
    },
    updateSchedule(state, payload: Schedule) {
      return {
        ...state,
        orderModified: true,
        schedule: [...state.schedule.map(sched => sched.sequence === payload.sequence ? payload : sched)],
      };
    },
    deleteSchedule(state, payload: number) {
      return {
        ...state,
        orderModified: true,
        schedule: [...state.schedule.filter(sched => sched.sequence !== payload)],
        datePickerRanges: [...state.datePickerRanges.filter(sched => sched.sequence !== payload)]
      };
    },
    /**
     * @name setVehicles
     * @desc Sets vehicles state
     * @param {IVehicles} payload
     *
     **/
    setVehicles(state, payload: IVehicles) {
      return { ...state, vehicles: [...payload], riderForADayModified: true };
    },
    setPromoCode(state, payload: string | null) {
      return { ...state, promoCode: payload, riderForADayModified: true };
    },
    toggleMap(state) {
      let mapType: MapType;
      if (state.mapType === "LEAFLET") mapType = "GOOGLE";
      else mapType = "GOOGLE";

      return { ...state, mapType };
    },
    setMapPermission(state, permission: MapPermission) {
      return { ...state, mapPermission: permission };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState };
    }
  },
  effects: (dispatch) => ({
    /**
     * @name calculateFare
     * @desc get trip price based on the set parameters
     *
     */
    async calculateFare(payload, rootState) {
      if (rootState.RiderforADayModel.isFareDetailsLoading) return; // Prevent from multiple loading of http

      const { userId }: any = rootState.User.userInfo;
      const { schedule, completeName, phoneNumber, location, pickup, vehicles, scheduleType, termsAndCondition, scheduleMethod } = rootState.RiderforADayModel;
      const vehicle = vehicles?.find((v => v?.isSelected));

      const constructObject = schedule?.map(sched => {   
        const startDay = new Date(moment(sched?.startDate).format('YYYY-MM-DD HH:mm:ss'));
        const endDay = new Date(moment(sched?.endDate).format('YYYY-MM-DD HH:mm:ss')); 

        const getDates = () => {
          const date = new Date(startDay.getTime());

          const dates: Array<any> = [];

          while (date <= endDay) {
            dates.push(moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss'));
            date.setDate(date.getDate() + 1);
          }

          return dates;
        }

        

        const obj = {
          schedule: getDates(), 
        }

        return obj;
      })

      const detailsForQuatation = {
        customer: userId,
        vehicleType: vehicle?.vehicleType,
        scheduleStartTime: constructObject,
        client: {
          locationDetails: location,
          name: completeName,
          contactNumber: `+63${phoneNumber}`
        },
        pickup,
        scheduleType,
        termsAndCondition, 
        orderType: scheduleMethod === "1" ? 'scheduled' : 'immediate',
      }

      try {
        dispatch.RiderforADayModel.updateState({ isFareDetailsLoading: true });
        const res = await getQuoteDetails(detailsForQuatation); 
        if (res) {
          dispatch.RiderforADayModel.updateState({
            isFareDetailsLoading: false,
            totalFee: res.fare.total,
            quoteResult: { ...res, payload: detailsForQuatation }
          });
          return { isSuccess: true }
        }
      } catch (err: any) {  
        dispatch.UI.setAlert({ message: typeof err === 'string' ? err: "", type: "error" });

        dispatch.RiderforADayModel.updateState({ isFareDetailsLoading: false }); 
      }
    },
    /**
     * @name requestOrder
     * @desc send request for order
     *
     */
    async requestOrder(payload, rootState) {

      const { quoteResult, paymentMethod, itemDescription, deliveryInstruction, billTo }: any = rootState.RiderforADayModel;
      
      delete quoteResult.payload.customer;

      let request = {
        ...quoteResult,
        paymentMethod,
        itemDescription,
        notes: deliveryInstruction
      }

      if (paymentMethod === 'billing') {
        request = {
          ...request,
          billTo
        }
      }

      try {
        dispatch.RiderforADayModel.updateState({ isRequestOrderLoading: true });
        const res = await requestRentalOrder(request); 
        if (res) {
          dispatch.UI.setAlert({ type: 'success', message: 'Request order' });
          dispatch.RiderforADayModel.updateState({ isRequestOrderLoading: false });
          dispatch.RiderforADayModel.resetState();
          return { isSuccess: true }
        }
      } catch (err: any) {
        console.log(err);
        dispatch.RiderforADayModel.updateState({ isRequestOrderLoading: false });
        const errMsg = err ?? 'Unable to request order';
        dispatch.UI.setAlert({ type: 'error', message: errMsg });
      }
    },

    async getDefaultRates(payload, rootState) {
      let rates = await getDefaultRates();

      if ((rates ?? []).length > 1) {
        dispatch.RiderforADay.setRates(rates)
      }
    },

    async updateAddress(payload: Location, state) {
      dispatch.RiderforADay.setLocation(payload);
    },

    async clearAddress(payload: Location, state) {
      dispatch.RiderforADay.updateAddress(payload);
    },
  })
});
