/* eslint-disable import/no-anonymous-default-export */
import { ModalContainer } from "../../../common/Container";
import { Button } from "../../../common/Button";
import { Span } from "../../../common/Span";
import { cancelOrder } from "../../../common/api/endpoints/orderlist";

import { useState } from "react";
import { CANCEL_REASON } from "../../../constants";
import "../OrderDetails.scss";

type Props = {
  orderId: string;
  setCancelModal: Function;
};

export default ({ setCancelModal, orderId }: Props) => {
  const [cancelReason, setReason] = useState("");

  let triggerCancelOrder = async () => {
    cancelOrder(orderId, cancelReason);
    setCancelModal(false);
  };

  const handleCancelReason = (e: any) => {
    setReason(e.target.value);
  };

  return (
    <>
      <ModalContainer width="modal-xs">
        <div className="p-10 whitespace-normal text-center ">
          <div className="my-2.5">
            <Span size="2xl" weight="bold" color="orange">
              Wait!
            </Span>
          </div>
          <Span size="base" color="darkgrey">
            Are you sure you want to cancel your order?
          </Span>

          <select
            onChange={(e: any) => handleCancelReason(e)}
            value={cancelReason}
            className="selectreason"
            required
          >
            <option value="" selected disabled hidden>
              {" "}
              Please select reason{" "}
            </option>
            {CANCEL_REASON?.map((reason) => (
              <option key={reason} value={reason}>
                {reason}
              </option>
            ))}
          </select>

          <div className="text-center my-3">
            <Button
              width="none"
              bg="white-only"
              padding="p-2"
              margin="mx"
              type="grey"
              weight="semi"
              onClick={() => setCancelModal(false)}
            >
              Cancel
            </Button>

            <Button
              onClick={() => triggerCancelOrder()}
              width="none"
              padding="p-2"
              margin="mx"
              weight="semi"
              disabled={!cancelReason}
            >
              Confirm
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
