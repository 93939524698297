import {
  Column,
  useTable,
  useSortBy,
  usePagination,
  useExpanded
} from "react-table";
import "../../components/Table/Table.scss";
import BTable from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import React from "react";
import { Dispatch } from "../../store";
import { Skeleton } from "@material-ui/lab";
import { Button } from "../../common/Button";

interface TableProps<D extends object> {
  columns: Column<D>[];
  data: D[];
  controlledPageCount: number;
  currentPage: number;
  renderRowSubComponent?: any;
  fetchData: any;
  isLoading?: boolean;
  totalCount?: number;
  hideFooterControl?: boolean;
  showRentalDetails?: boolean;
  isDateFilter?: boolean;
  dataType?: any;
}

export function generateBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function RTable<D extends object>({
  columns,
  data,
  fetchData,
  isLoading,
  isDateFilter,
  totalCount = 0,
  showRentalDetails = false,
  controlledPageCount,
  dataType,
  hideFooterControl = false
}: TableProps<D>) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        pageCount: controlledPageCount,
        initialState: {
          pageIndex: 0,
          sortBy: [
            {
              id: "createdAt",
              desc: true
            }
          ]
        },
        manualPagination: true
      },
      useSortBy,
      useExpanded,
      usePagination
    );

  const [count, setCount] = React.useState(0);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const getFinalRange = (value: any) => {
    if (value >= totalCount) {
      return totalCount;
    } else {
      return value;
    }
  };

  const handleRowClick = (e: any, row: any) => {
    switch (dataType) {
      case "rental-order":
        dispatch.UI.setCurrentRoute("/order-history-rental");
        history.push(`/order-history-rental/${row?.original?.orderCode}`);
        break;
      case "rental-order-completed":
        dispatch.UI.setCurrentRoute("/order-history-rental-completed");
        history.push(
          `/order-history-rental-completed/${row?.original?.orderCode}`
        );
        break;
      default:
        return "";
    }
  };

  function renderPreloader(preloaderCount: number) {
    return new Array(preloaderCount).fill(null).map((_, index) => (
      <tr key={`row-preloader-${index}`}>
        {columns.map((col, i) => (
          <td
            key={`col-preloader-${i}`}
            className={`pr-6 py-4 bg-white-default ${
              i === 0 ? "pl-6" : ""
            } border-b border-gray-200`}
          >
            <Skeleton height={12} width={`${generateBetween(50, 100)}%`} />
          </td>
        ))}
      </tr>
    ));
  }

  return (
    <>
      <BTable
        striped
        bordered
        hover
        size="lg"
        className="table-list"
        responsive
        {...getTableProps()}
      >
        <thead className="table-list-header">
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-list-header__row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className="table-list-header__row-cell"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {isLoading && (
          <tbody className="table-list-body" {...getTableBodyProps()}>
            {renderPreloader(generateBetween(2, 6))}
          </tbody>
        )}

        {!isLoading && (
          <tbody className="table-list-body" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const lastRow = row?.cells?.length - 1;
              return (
                <>
                  <tr
                    className="table-list-body__row"
                    {...row.getRowProps()}
                    style={{ cursor: showRentalDetails ? "pointer" : "unset" }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          className="table-list-body__row-cell"
                          // onClick={() => {
                          //   history.push("/order-history-rental/"+cell?.row?.original?.orderCode);

                          // }}
                          onClick={(e) =>
                            lastRow !== index ? handleRowClick(e, row) : ""
                          }
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        )}
      </BTable>

      {!hideFooterControl && (
        <div className="table-list-pagination mr-2.5">
          <p>
            {!isDateFilter &&
              `Page ${count * 10 + 1} - ${getFinalRange(
                count * 10 + 10
              )} of ${totalCount}`}
          </p>
          <div className="flex mr-100">
            <Button
              width="none"
              margin="mr"
              disabled={count < 1 || isLoading || isDateFilter}
              onClick={() => {
                fetchData(count - 1);
                setCount((prev) => prev - 1);
              }}
            >
              Previous
            </Button>
            <Button
              width="none"
              disabled={
                isLoading || count * 10 + 10 >= totalCount || isDateFilter
              }
              onClick={() => {
                fetchData(count + 1);
                setCount((prev) => prev + 1);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

function Table(props: any) {
  return <RTable {...props}> </RTable>;
}

export default Table;
