import { Spinner } from "react-bootstrap";
import { FC } from "react";
import "./Loader.scss";

interface ILoaderProps {
  ultra?: boolean;
}

const Loader: FC<ILoaderProps> = ({ ultra }) => {
  return (
    <div className={`loader ${ultra ? "bg-white" : ""}`}>
      <Spinner animation="border" className="spinner" />
    </div>
  );
};

export default Loader;
