import { post,get } from "../core";

export const getDefaultRates = async () => {
  try {
    let res = await get(`/rate-cards/default/fare`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getFare = async (body: any) => {
  try {
    let res = await post(`/express-orders/quote`, body);
    return res.data;
  } catch (err) {
    throw err;
  }
};