import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../../store";

import { Span } from "../../../../common/Span";
import { Input } from "../../../../common/Input";
import { InputContainer } from "../../../../common/Container";
import { onlyNumberKey } from "../../../../common/utils";

const ClientInformation = () => {
  const dispatch = useDispatch<Dispatch>();
  const { completeName, phoneNumber, location }: any = useSelector(
    (state: RootState) => state.RiderforADayModel
  );

  return (
    <>
      <div className="px-3 pt-6 pb-10">
        <Span color="orange" padding="none">
          Client Information
        </Span>
        <Input
          placeholder="Complete Name/ Business Name"
          radius="md"
          width="full"
          value={completeName}
          onChange={(e: any) =>
            dispatch.RiderforADayModel.updateState({
              completeName: e.target?.value
            })
          }
        />

        <Input
          placeholder="Location (unit, floor, building, landmark)"
          radius="md"
          width="full"
          value={location}
          onChange={(e: any) =>
            dispatch.RiderforADayModel.updateState({
              location: e.target?.value
            })
          }
        />

        <InputContainer flex="flex" border="grey" padding="none" margin="mt-1">
          <Span width="none" margin="none">
            +63
          </Span>
          <Input
            placeholder="Contact Number"
            border="none"
            width="full"
            margin="none"
            value={phoneNumber}
            onKeyPress={onlyNumberKey}
            maxLength={10}
            onChange={(e: any) =>
              dispatch.RiderforADayModel.updateState({
                phoneNumber: e.target?.value
              })
            }
          />
        </InputContainer>
      </div>
    </>
  );
};

export default ClientInformation;
