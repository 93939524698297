import { useHistory } from "react-router";
import { useEffect, useState } from "react";

import { Span } from "../../../common/Span";

import "./OrderInfo.scss";

import back from "../../../assets/img/web/back-icon.png";
import pickup from "../../../assets/img/web/pickupdetails.png";
import dropdown from "../../../assets/img/web/orderlistdropoff-icon.png";
import Stop from "../../../assets/img/web/stopdetails.png";
import placeholder from "../../../assets/img/placeholder.png";

import CopyandPaste from "../../../containers/ShareRoute/CopyandPaste";
import { IoIosArrowForward } from "react-icons/io";
import {
  DATA_TYPE,
  PAYMENTMETHOD,
  PODSKIPREASONS,
  VEHICLE
} from "../../../constants";

import History from "../Modal/HistoryModal";
import { formatToDate } from "../../../common/utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getAddsOnFeeById } from "../../../common/api/endpoints/orderlist";

const OrderInfo = ({
  referenceNumber,
  order,
  delivery,
  datatype = "express-orders",
  ...props
}: any) => {
  const history = useHistory();

  const [addsOnFee, setAddsOnFee] = useState([] as any);
  const pathname = useSelector<RootState>(
    (state: RootState) => state.UI.currentRoute
  );
  const [historyModal, setHistoryModal] = useState(false);
  const handleHistory = (e) => {
    e.preventDefault();
    setHistoryModal(true);
  };

  useEffect(() => {
    const addsOnFeeById = async () => {
      try {
        const res = await getAddsOnFeeById(referenceNumber);
        setAddsOnFee(res);
      } catch (err) {
        console.log(err);
      }
    };
    addsOnFeeById();
  }, [referenceNumber]);

  return (
    <>
      <div className="flex pt-5 pb-3 px-3 border-solid border-b border-getgrey-1">
        <img
          src={back}
          className="my-auto p-2 h-14 cursor-pointer"
          onClick={() => history.push(`${pathname}`)}
        />
        <div className="m-auto text-center">
          <Span weight="semi" size="base">
            {VEHICLE[delivery?.vehicleType]} | {delivery?.orderCode}
          </Span>
          <div className="flex py-1">
            <div>
              <p>
                <Span size="xs" padding="pr">
                  {datatype === DATA_TYPE.open
                    ? delivery?.scheduleStartTime?.toDate()
                      ? formatToDate(delivery?.scheduleStartTime?.toDate())
                      : formatToDate(delivery?.createdAt?.toDate())
                    : delivery?.scheduleStartTime
                    ? formatToDate(delivery?.scheduleStartTime)
                    : formatToDate(delivery?.createdAt)}
                </Span>
              </p>
            </div>

            <div
              className={`status_details_${order?.status?.toLowerCase()} status_details`}
            >
              {order?.status?.toUpperCase().replace("_", " ")}
            </div>
          </div>
          <div
            className="flex w-full justify-center cursor-pointer"
            onClick={handleHistory}
          >
            <Span
              weight="semi"
              color="orange"
              whitespace="nowrap"
              padding="none"
              width="none"
              margin="my-auto"
            >
              Transaction History
            </Span>
            <IoIosArrowForward className="my-auto text-getorange-2" />
          </div>
        </div>
      </div>

      {delivery?.driver?.driverProfile?.firstName ? (
        <div className="p-3">
          <Span color="grey" weight="semi" size="base">
            Driver Information
          </Span>

          <figure className="flex rounded-xl p-2">
            <img
              className="w-auto h-24 rounded-xl"
              src={delivery?.driver?.driverProfile?.picture ?? placeholder}
              alt=""
              width="384"
              height="512"
            />
            <div className="w-full text-center my-auto">
              <Span
                weight="semi"
                size="base"
                padding="none"
                transform="capitalize"
              >
                {delivery?.driver?.driverProfile?.firstName +
                  " " +
                  delivery?.driver?.driverProfile?.lastName}
              </Span>
              <div className="w-full text-center">
                <Span size="xs" padding="none">
                  {delivery?.driver?.phoneNumber +
                    " | " +
                    delivery?.driver?.driverId}
                </Span>
              </div>
              {props.showViewMap &&
                (order?.status === "canceled" ||
                order?.status === "delivered" ? (
                  <></>
                ) : (
                  <div className="flex text-center">
                    <a
                      href=""
                      className="mx-auto text-xs font-bold"
                      onClick={() =>
                        history.push(
                          "/order-details/" + delivery.referenceNumber
                        )
                      }
                    >
                      {"View on Map"}
                    </a>
                  </div>
                ))}
            </div>
          </figure>
        </div>
      ) : (
        <></>
      )}

      {delivery?.thirdPartyDriver?.contactName ? (
        <div className="p-3">
          <Span color="grey" weight="semi" size="base">
            Driver Information
          </Span>

          <figure className="flex rounded-xl p-2">
            <img
              className="w-24 h-24 rounded-xl object-cover"
              src={placeholder}
              alt=""
              width="384"
              height="512"
            />
            <div className="w-full text-center my-auto">
              <Span
                weight="semi"
                size="base"
                transform="capitalize"
                padding="none"
              >
                {delivery?.thirdPartyDriver?.contactName}
              </Span>
              <div className="w-full text-center">
                <Span size="xs" padding="none">
                  {delivery?.thirdPartyDriver?.contactNumber}
                </Span>
              </div>
              {props.showViewMap &&
                (order?.status === "canceled" ||
                order?.status === "delivered" ? (
                  <></>
                ) : (
                  <div className="w-full text-center">
                    <a
                      href=""
                      className="mx-auto text-xs font-bold"
                      onClick={() =>
                        history.push(
                          "/order-details/" + delivery.referenceNumber
                        )
                      }
                    >
                      {"View on Map"}
                    </a>
                  </div>
                ))}
            </div>
          </figure>
        </div>
      ) : (
        <></>
      )}

      <div className="justify-between">
        <div className="p-3">
          <div className="py-3">
            <Span color="grey" weight="semi" size="base">
              Route
            </Span>
            <Span color="grey" size="base">
              {" "}
              {Math.round(delivery.distance)} KM{" "}
            </Span>

            <div className="flex mx-auto">
              <div className="">
                <img src={pickup} className="" />
              </div>
              <div className="mx-2">
                <Span weight="semi" padding="none">
                  {delivery.pickup?.locationAddress}
                </Span>
                {delivery.pickup?.location.address ? (
                  <p>
                    <Span
                      color="grey"
                      padding="none"
                    >{`${delivery.pickup?.location.address}`}</Span>
                  </p>
                ) : (
                  <></>
                )}
                {delivery.pickup?.contactName ||
                delivery.pickup?.contactPhone ? (
                  <p>
                    <Span
                      color="grey"
                      padding="none"
                    >{`${delivery.pickup?.contactName} | ${delivery.pickup?.contactPhone}`}</Span>
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>

            {delivery?.stops
              ?.sort((a, b) => (a.sequenceNo > b.sequenceNo ? 1 : -1))
              .map((stp, index: number) => (
                <div className="flex mx-auto">
                  <div className="order-details-icon" key={index}>
                    <img src={Stop} className="" />
                    <strong className="order-details-text">
                      {stp?.sequenceNo > 1 ? stp?.sequenceNo - 1 : 1}
                    </strong>
                  </div>
                  <div className="mx-2">
                    <Span weight="semi" padding="none">
                      {stp?.locationAddress}
                    </Span>
                    {stp?.location.address ? (
                      <p>
                        <Span
                          color="grey"
                          padding="none"
                        >{`${stp?.location.address}`}</Span>
                      </p>
                    ) : (
                      <></>
                    )}

                    {stp?.contactName || stp?.contactPhone ? (
                      <p>
                        <Span
                          color="grey"
                          padding="none"
                        >{`${stp?.contactName} | ${stp?.contactPhone}`}</Span>
                      </p>
                    ) : (
                      <></>
                    )}
                    {stp?.deliveryInstruction ? (
                      <p>
                        <Span
                          color="grey"
                          padding="none"
                        >{`${stp?.deliveryInstruction}`}</Span>
                      </p>
                    ) : (
                      <></>
                    )}
                    {stp?.itemCategory ? (
                      <p>
                        <Span
                          color="grey"
                          padding="none"
                        >{`${stp?.itemCategory}`}</Span>
                      </p>
                    ) : (
                      <></>
                    )}
                    {stp?.proofImage ? (
                      <p>
                        <a href={stp?.proofImage} target="_blank">
                          <Span color="orange" weight="bold">
                            View Proof of Delivery
                          </Span>
                        </a>
                      </p>
                    ) : (
                      <></>
                    )}
                    {stp?.skipReason ? (
                      <p>
                        <Span weight="semi" color="darkred">
                          NO POD:
                        </Span>
                        <Span padding="none" transform="capitalize">
                          {stp?.skipOtherReason
                            ? PODSKIPREASONS[stp?.skipReason]
                            : "No reason"}
                        </Span>
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}

            <div className="flex mx-auto">
              <div className="">
                <img src={dropdown} />
              </div>
              <div className="mx-2 my-auto">
                <Span weight="semi" padding="none">
                  {delivery.dropoff?.locationAddress}
                </Span>
                {delivery.dropoff?.location.address ? (
                  <p>
                    <Span
                      color="grey"
                      padding="none"
                    >{`${delivery.dropoff?.location.address}`}</Span>
                  </p>
                ) : (
                  <></>
                )}
                {delivery.dropoff?.contactName ||
                delivery.dropoff?.contactPhone ? (
                  <p>
                    <Span
                      color="grey"
                      padding="none"
                    >{`${delivery.dropoff?.contactName} | ${delivery.dropoff?.contactPhone}`}</Span>
                  </p>
                ) : (
                  <></>
                )}
                {delivery.dropoff?.deliveryInstruction ? (
                  <p>
                    <Span
                      color="grey"
                      padding="none"
                    >{`${delivery.dropoff?.deliveryInstruction}`}</Span>
                  </p>
                ) : (
                  <></>
                )}
                {delivery.dropoff?.itemCategory ? (
                  <p>
                    <Span
                      color="grey"
                      padding="none"
                    >{`${delivery.dropoff?.itemCategory}`}</Span>
                  </p>
                ) : (
                  <></>
                )}
                {delivery.dropoff?.proofImage ? (
                  <p>
                    <a href={delivery.dropoff?.proofImage} target="_blank">
                      <Span color="orange" weight="bold" padding="none">
                        View Proof of Delivery
                      </Span>
                    </a>
                  </p>
                ) : (
                  <></>
                )}
                {delivery.dropoff?.skipReason ? (
                  <p>
                    <Span weight="semi" color="darkred">
                      POD Skipped:
                    </Span>
                    <Span padding="none" transform="capitalize">
                      {delivery.dropoff?.skipOtherReason
                        ? PODSKIPREASONS[delivery.dropoff?.skipReason]
                        : `other reason (${delivery.dropoff?.skipOtherReason})`}
                    </Span>
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {order?.status != "canceled" && (
            <CopyandPaste
              order={delivery}
              referenceNumber={delivery.referenceNumber}
            />
          )}

          {order?.cancelReason ? (
            <div className="my-4">
              <Span color="lightgrey" weight="semi" uppercase>
                Cancel Reason
              </Span>
              <p>
                <Span>{order?.cancelReason}</Span>
              </p>
            </div>
          ) : (
            <></>
          )}

          {order?.itemDescription ? (
            <div className="my-4">
              <Span color="lightgrey" weight="semi" uppercase>
                Item Description
              </Span>
              <p>
                <Span>{order?.itemDescription}</Span>
              </p>
            </div>
          ) : (
            <></>
          )}

          {order?.notes ? (
            <div className="my-4">
              <Span color="lightgrey" weight="semi" uppercase>
                Driver Instruction
              </Span>
              <p>
                <Span>{order?.notes}</Span>
              </p>
            </div>
          ) : (
            <></>
          )}

          <div className="my-4">
            <Span color="lightgrey" weight="semi" uppercase>
              Price
            </Span>


            {/* Firebase */}
            {Object.entries(order ?? (order || {}))
              ?.filter((v: any) => v[0]?.endsWith("Fee") && v[1] !== 0)
              ?.map((detail, index) =>
                (
                  <div key={index}>
                    <div className="flex justify-between">
                      <Span padding="px-2" margin="none" transform="capitalize">
                      {detail[0] === "holidaySurchargeFee" && addsOnFee.length && addsOnFee.filter(item => item.type === 'holiday_surcharge_fee')
                        ? addsOnFee.filter(item => item.type === 'holiday_surcharge_fee')[0].particular   
                        : detail[0] === "afterHoursSurchargeFee" ? "Peak Hours Surcharge Fee"   
                        : detail[0] === "customAddOnsFee" ? null : detail[0].replace(/([a-z])([A-Z])/g, "$1 $2") 
                      }
                      </Span> 
                      <Span padding="px-2" margin="none" align="right">
                        {detail[0] != "customAddOnsFee" && `₱ ${detail[1]}`}
                      </Span>
                    </div> 
                    
                    {detail[0] === "customAddOnsFee" && addsOnFee.filter(item=> item.isCustomAddOns).map(item => (
                      <div className="flex justify-between">
                        <Span padding="px-2" margin="none" transform="capitalize">
                          {item.particular}
                        </Span>
                        <Span padding="px-2" margin="none" transform="capitalize" align="right">
                        {`₱ ${item.amount}`}
                        </Span>
                    </div>
                    ))}
                  </div>
 
                ) 
              )} 

            {order?.voucherCode && (
              <div className="flex justify-between">
                <Span padding="px-2" margin="none" transform="capitalize">
                  {`Discount (Voucher Code: ${order?.voucherCode})`}
                </Span>
                <Span padding="px-2" margin="none" align="right">
                  ₱ -{order?.discountAmount}
                </Span>
              </div>
            )}

            {order?.customAddOnsDetail?.length ? (
              <>
                {order?.customAddOnsDetail?.map((c) => (
                  <>
                    <div className="flex justify-between">
                      {c?.filePath ? (
                        <Span color="orange" padding="px-2">
                          <a href={c?.filePath} target="_blank">
                            {c?.particular}
                          </a>
                        </Span>
                      ) : (
                        <Span>{c?.particular}</Span>
                      )}

                      <Span padding="px-2" margin="none" align="right">
                        {`₱ ${parseFloat(c?.amount)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                      </Span>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}

            <div className="flex justify-between">
              <Span weight="semi" margin="none" size="base">
                Total (Incl. VAT)
              </Span>
              <Span
                weight="semi"
                margin="none"
                size="base"
                align="right"
                whitespace="nowrap"
              >
                {`₱ ${parseFloat(order?.totalCharge)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              </Span>
            </div>
            <div className="flex justify-between">
              <Span padding="px-2" margin="none">
                Payment Method
              </Span>
              <Span padding="px-2" margin="none" align="right">
                {PAYMENTMETHOD[order?.paymentMethod]}
              </Span>
            </div>

            {order?.paymentDetails?.branch && (
              <div className="flex justify-between">
                <Span padding="px-2" margin="none">
                  Branch
                </Span>
                <Span padding="px-2" margin="none" align="right">
                  {order?.paymentDetails?.branch}
                </Span>
              </div>
            )}

            {order?.paymentDetails?.brand && (
              <div className="flex justify-between">
                <Span padding="px-2" margin="none">
                  Brand
                </Span>
                <Span padding="px-2" margin="none" align="right">
                  {order?.paymentDetails?.brand}
                </Span>
              </div>
            )}

            {order?.paymentDetails?.chargeTo && (
              <div className="flex justify-between">
                <Span padding="px-2" margin="none">
                  Charge to
                </Span>
                <Span padding="px-2" margin="none" align="right">
                  {order?.paymentDetails?.chargeTo}
                </Span>
              </div>
            )}

            {order?.paymentDetails?.corporateBillingName && (
              <div className="flex justify-between">
                <Span padding="px-2" margin="none">
                  Corporate Billing Name
                </Span>
                <Span padding="px-2" margin="none" align="right">
                  {order?.paymentDetails?.corporateBillingName}
                </Span>
              </div>
            )}

            {order?.paymentDetails?.email && (
              <div className="flex justify-between">
                <Span padding="px-2" margin="none">
                  Email
                </Span>
                <Span padding="px-2" margin="none" align="right">
                  {order?.paymentDetails?.email}
                </Span>
              </div>
            )}

            {order?.paymentDetails?.name && (
              <div className="flex justify-between">
                <Span padding="px-2" margin="none">
                  Name
                </Span>
                <Span padding="px-2" margin="none" align="right">
                  {order?.paymentDetails?.name}
                </Span>
              </div>
            )}
          </div>
        </div>
      </div>

      {historyModal && (
        <History
          setHistoryModal={setHistoryModal}
          order={order}
          delivery={delivery}
          datatype={datatype}
        />
      )}
    </>
  );
};

export default OrderInfo;
