import { FC, useCallback, useEffect, useState } from "react";
import "./Autocomplete.scss";
import LocationIcon from "../../assets/img/web/pinlocation_map.png";
import PowerByGoogleImg from "../../assets/img/poweredbygoogle.png";
import { debounce } from "lodash";
import { getAutocompleteSuggestions, getPlaceDetails } from "../../common/api";
import ReactAutocomplete from "react-autocomplete";
import { Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { rowCount } from "../../common/utils";
import { analyticEvent, Event } from "../../customHooks/analytics";
interface IProps {
  handleSelectedIndex: () => void;
  handleOnChange: (e: any) => void;
  handleSelectAddedFunc: (
    address: any,
    finRes: any,
    placeId?: any,
    matchedSubstrings?: any
  ) => void;
  value: string;
  placeholder: string;
  className?: string;
  showSuggestion?: boolean;
  isFavorites?: boolean;
  isDisable?: boolean;
}

const Autocomplete: FC<IProps> = ({
  handleSelectedIndex,
  handleOnChange,
  handleSelectAddedFunc,
  placeholder,
  value,
  isFavorites,
  isDisable
}) => {
  const [suggestions, setSuggestions] = useState<any>([]);
  const lowerDevice = useMediaQuery({ maxWidth: 1400 });
  // eslint-disable-next-line
  const deb = useCallback(
    debounce(async (text) => {
      if (text.length > 2) {
        try {
          if (placeholder === "Pick Up Location") {
            analyticEvent(Event.ExpressSearchedPickup, {
              searched_location: text
            });
          } else {
            analyticEvent(Event.ExpressSearchedDropoff, {
              searched_location: text
            });
          }
          let { predictions } = await getAutocompleteSuggestions(text);

          if (!predictions) {
            return;
          }

          if (predictions?.length === 0) {
            predictions.push({
              place_id: "NO_RESULT",
              description: "No result found.",
              isNoResultFound: true
            });
          }

          if (!isFavorites)
            predictions.push({
              place_id: "GOOGLE",
              description: "GOOGLE",
              isItemPoweredByGoogle: true
            });

          predictions.map((elem: any, index: number) => {
            return { elem, key: index };
          });
          setSuggestions([...predictions]);
        } catch (err) {
          throw err;
        }
      }
    }, 1000),
    []
  );

  const analyticEventSelectionAddress = (location: string, type: string) => {
    if (type === "Pick Up Location") {
      analyticEvent(Event.ExpressSelectedPickup, {
        selected_location: location
      });
    } else {
      analyticEvent(Event.ExpressSelectedDropoff, {
        selected_location: location
      });
    }
  };

  const onSuggestionClick = async (placeId: string) => {
    try {
      let res = await getPlaceDetails(placeId);

      let suggestion = suggestions.find(
        (elem: any) => elem.place_id === placeId
      );

      if (suggestion && isFavorites) {
        handleSelectAddedFunc(
          suggestion.description,
          res.result.geometry.location,
          suggestion.place_id,
          suggestion.matched_substrings
        );
        analyticEventSelectionAddress(suggestion, placeholder);
      } else {
        handleSelectAddedFunc(
          suggestion.description,
          res.result.geometry.location
        );
        analyticEventSelectionAddress(suggestion.description, placeholder);
      }
      setSuggestions([]);
    } catch (err) {
      throw err;
    }
  };

  const renderInputComponent = (props: any) => {
    if (isFavorites) {
      return (
        <textarea
          {...props}
          className={`form-control ${isFavorites ? "" : "border-0"} mb-1`}
          placeholder={placeholder}
          rows={3}
          disabled={isDisable ? isDisable : false}
        ></textarea>
      );
    }

    return (
      <textarea
        {...props}
        onClick={!value ? handleSelectedIndex : null}
        rows={rowCount(props, lowerDevice)}
        style={{ resize: "none", overflow: "hidden" }}
        className={` ${isFavorites ? "" : "border-0"} p-0 autocomplete-input `}
        placeholder={placeholder}
        disabled={isDisable ? isDisable : false}
      />
    );
  };

  const renderItem = (item: any) => {
    if (item.isItemPoweredByGoogle) {
      return (
        <div className="d-flex align-content-center flex-fill justify-content-end p-2">
          <img src={PowerByGoogleImg} alt="" />
        </div>
      );
    }

    if (item.isNoResultFound) {
      return (
        <div className="d-flex align-content-center flex-fill p-3">
          <strong>No result found.</strong>
        </div>
      );
    }

    return (
      <>
        <div className="align-self-center ml-2 p-3 d-flex justify-content-center">
          <img src={LocationIcon} alt="" />
        </div>
        <div className="p-3  text-left">
          <strong>{item.description}</strong>
        </div>
      </>
    );
  };

  const renderItemComponent = (item: any, isHighlighted: boolean) => {
    return (
      <div
        className={`d-flex align-content-center pointer suggestion-items ${
          isHighlighted ? "bg-highlighted" : ""
        }`}
        key={item.key}
      >
        {renderItem(item)}
      </div>
    );
  };

  const renderMenuComponent = (items: any) => {
    return (
      <Col
        lg={isFavorites ? "12" : "12"}
        className={`${
          !isFavorites ? "autocomplete-menu" : "autocomplete-favorite p-0"
        } ${value ? "" : "d-none"}`}
        children={items}
      />
    );
  };

  useEffect(() => {
    if (value) {
      deb(value);
    }
  }, [value, deb]);

  return (
    <>
      <div className="ac-wrapper">
        <ReactAutocomplete
          inputProps={{ className: "suggestions-container" }}
          renderInput={(props) => renderInputComponent(props)}
          renderMenu={(items: any) => renderMenuComponent(items)}
          renderItem={(item: any, isHighlighted) =>
            renderItemComponent(item, isHighlighted)
          }
          getItemValue={(item) => item.place_id}
          items={suggestions}
          isItemSelectable={(item) =>
            item.isItemPoweredByGoogle || item.isNoResultFound ? false : true
          }
          value={value}
          onChange={(e: any) => {
            handleOnChange(e);
          }}
          onSelect={(val) => {
            onSuggestionClick(val);
          }}
          wrapperStyle={{
            width: "100%"
          }}
        />
      </div>
    </>
  );
};

export default Autocomplete;
