import { get } from "../core"; 

 export const getWalletBalance = async () => {
  try {
    let res = await get(`/customers/wallet/balance`); 
    return res.data.balance;
  } catch (err) {
    throw err;
  }
}; 

export const getRecentTransactions = async (pageIndex: any, pageSize: any)  => {
  try {
    let res = await get(`/customers/wallet/recent-transactions?page=${pageIndex}&pageSize=${pageSize}`); 
    return res.data;
  } catch (err) {
    throw err;
  }
};
