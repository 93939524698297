/* eslint-disable import/no-anonymous-default-export */
import { ModalContainer } from "../../../../common/Container";
import { Button } from "../../../../common/Button";
import { Span } from "../../../../common/Span";
import { cancelRental } from "../../../../common/api/endpoints/rental";
import { useAlert } from "react-alert";

type Props = {
  rentalId: string;
  setCancelModal: Function;
};

export default ({ setCancelModal, rentalId }: Props) => {
  const alertHook = useAlert();

  const triggerCancelRental = async () => {
    try {
      await cancelRental(rentalId);
      window.location.reload();
    } catch (err: any) {
      alertHook.show(err);
    }
    setCancelModal(false);
  };

  return (
    <>
      <ModalContainer width="modal-xs">
        <div className="p-10 whitespace-normal text-center ">
          <div className="my-2.5">
            <Span size="2xl" weight="bold" color="orange">
              Wait!
            </Span>
          </div>
          <Span size="base" color="darkgrey">
            Are you sure you want to cancel your order?
          </Span>
          <p>
            <label className="text-getgrey-12 text-sm">
              Note: You cannot undo this action
            </label>
          </p>

          <div className="text-center my-3">
            <Button
              width="none"
              bg="white-only"
              padding="p-2"
              margin="mx"
              type="grey"
              weight="semi"
              onClick={() => setCancelModal(false)}
            >
              Cancel
            </Button>

            <Button
              onClick={triggerCancelRental}
              width="none"
              padding="p-2"
              margin="mx"
              weight="semi"
            >
              Confirm
            </Button>
          </div>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
