import { get, post, put } from '../core';

export const getQuoteDetails = async(payload) => {
    try{
        const res = await post('/rental/quote', payload)
        return res.data;
    }catch(err){
        throw err;
    }
}

export const requestRentalOrder = async(payload) => {
    try{
        const res = await post('/rental', payload)
        return res.data;
    }catch(err){
        throw err;
    }
}

export const cancelRental = async (rentalId:string) => {
    try {
      let res = await put(`/rental/${rentalId}/cancel`);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  export const getAttachmentView = async (id:string, scheduleId:string) => {
    try {
      let res = await get(`/rental/${id}/item/${scheduleId}/get-delivery-attachment`);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  export const getRentalListCompleteOrder = async() => {
    try{
      const res = await get('/rental?status=completed&status=canceled')  
      return res.data;
    }catch(err){
        throw err;
    }
  } 