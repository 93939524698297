/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router";
import { Col } from "react-bootstrap";
import firebase from "../../../Firebase";

import { Span } from "../../../common/Span";

import "./RentalDetails.scss";

import RentalInfo from "./RentalInfo";
import { useEffect, useState } from "react";
import CancelModal from "./Modal/CancelModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const RentalDetails = () => {
  const { orderCode }: any = useParams();
  const [rentals, setRentals] = useState({} as any);
  const [rentalItems, setRentalItems] = useState({} as any);

  const { thirdPartyDriver } = rentals !== undefined && rentals;

  const listenToRentalList = async () => {
    let db = firebase.firestore();
    db.collection("rental")
      .where("orderCode", "==", orderCode)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          setRentals(doc.data());
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  const listenToRentalItemList = async () => {
    let db = firebase.firestore();
    db.collection("rental_items")
      .where("orderCode", "==", orderCode)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          setRentalItems(doc.data());
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  useEffect(() => {
    listenToRentalList();
    listenToRentalItemList();
  }, [orderCode]);

  // Cancel
  const [cancelModal, setCancelModal] = useState(false);
  const handleCancelModal = (e: any) => {
    e.preventDefault();
    setCancelModal(true);
  };

  const pathname = useSelector<RootState>(
    (state: RootState) => state.UI.currentRoute
  );

  return (
    <>
      <Col
        lg="4"
        className="order-details-side absolute inset-y-0 right-0 px-0"
      >
        <RentalInfo
          rentals={rentals}
          thirdPartyDriver={thirdPartyDriver}
          orderCode={orderCode}
          rentalItems={rentalItems}
          pathname={pathname}
        />

        <div className="p-3 mb-2 flex justify-between inset-x-0 bottom-0">
          {rentals?.status === "canceled" || rentals?.status === "completed" ? (
            <></>
          ) : (
            <>
              <div
                className="cursor-pointer p-2 order-details-border"
                onClick={handleCancelModal}
              >
                <Span weight="semi">Cancel Order</Span>
              </div>
            </>
          )}
        </div>

        {cancelModal && (
          <CancelModal setCancelModal={setCancelModal} rentalId={rentals.id} />
        )}
      </Col>
    </>
  );
};

export default RentalDetails;
