import { lazy, Suspense } from "react";

const GoogleMap = lazy(() => import("./Google"));

export default function Map({ locations = [], driver_location = {} }: any) {
  return (
    <Suspense fallback={<></>}>
      <GoogleMap locations={locations} driver_location={driver_location} />
    </Suspense>
  );
}
