/* eslint-disable jsx-a11y/alt-text */
import { Span } from "../../common/Span";
import { Button } from "../../common/Button";
import izzy from "../../assets/img/web/config/izzy.png";
import warning403 from "../../assets/img/web/config/warning403.png";
import "./ConfigService.scss";
import Loader from "../Loader/Loader";
import { GridContainer } from "../../common/Container";

const ConfigService = ({ loading }) => {
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="config">
          <div className="config-main">
            <div className="config-container">
              <GridContainer grid="two">
                <div className="config-container-right">
                  <img src={izzy} className="config-container-right-img" />
                  <div className="text-center mx-4">
                    <p>
                      <Span color="orange-two" weight="semi" size="lg">
                        Oops! Page not Found
                      </Span>
                    </p>
                    <p>
                      <Span color="lightgrey" size="base">
                        Something went wrong.
                      </Span>
                    </p>
                    <p>
                      <Span color="lightgrey" size="base">
                        This service is not available at this moment!
                      </Span>
                    </p>
                    <Button
                      bg="darkred"
                      radius="full"
                      margin="top"
                      onClick={() => window.location.reload()}
                    >
                      Return Home
                    </Button>
                  </div>
                </div>
                <div className="flex justify-center text-center items-center my-4">
                  <div>
                    <img src={warning403} className="h-20 my-4 mx-auto" />
                    <p className="mt-8">
                      <span className="config-container-403">403</span>
                    </p>
                  </div>
                </div>
              </GridContainer>
            </div>
          </div>

          <div className="config-bottom">
            <span>© AllEasy 2022. All Rights Reserved.</span>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfigService;
