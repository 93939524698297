import { get, post } from "../core";

export const requestOrder = async (data: any) => {
  try {
    let res = await post(`/express-orders`, data);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getConfiguresConstant = async () => { 
  try {
    const res = await get(`/configures/constants`);  
     return res.data;
  } catch (err) {
      throw err;
  }
};

export const getVehicleType = async () => { 
  try {
    const res = await get(`/configures/vehicle-type`);    
    return res.data;
  } catch (err) {
      throw err;
  }
};