import { createModel } from "@rematch/core";
import { RootModel } from ".";

import {
  IDataRental,
  IRentalList,
  IRentalFilters
} from "../types/IDataRentals";

let data: IDataRental = {
  data: [],
  currentPage: 1
};

const initialState = {
  page: 1,
  maxPage: 0,
  isLoading: false,
  dataRental: data,
  dataRentalCompleted: data,
  options: [""],
  rentalFilters: null
} as IRentalList;

export const RentalList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    setDataOrder(state, payload: IDataRental) {
      state.dataRental = payload;
      return { ...state };
    },
    setDataOrderCompleted(state, payload: IDataRental) {
      state.dataRentalCompleted = payload;
      return { ...state };
    },
    setState(state, newState: Partial<IRentalList>) {
      return { ...state, ...newState };
    },
    handleNext: (_) => ({ ..._, page: _.page + 1 }),
    handlePrevious: (_) => ({ ..._, page: _.page - 1 }),
    setIsLoading: (_, isLoading: boolean) => ({ ..._, isLoading }),
    setMaxPage: (_, maxPage: number) => ({ ..._, maxPage }),
    setFilters(state, filters: IRentalFilters) {
      const rentalFilters = { ...state?.rentalFilters, ...filters };
      return { ...state, rentalFilters };
    }
  }
});
