/* eslint-disable eqeqeq */
import { Span } from "../../../../common/Span";

import "./ScheduleSelection.scss";
import calendar from "../../../../assets/img/rental/calendar_icon.png";

import Info from "./Info";
import info from "../../../../assets/img/rental/view_icon.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { getfinalStartEndDate } from "../../../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../../store";

const ScheduleSelection = ({
  setIsDatePickerShown,
  setSequence,
  sched
}: any) => {
  const dispatch = useDispatch<Dispatch>();
  const { schedule } = useSelector(
    (state: RootState) => state.RiderforADayModel
  );

  const {
    date: DATE,
    day: DAY,
    time: TIME
  } = getfinalStartEndDate(sched?.startDate, sched?.endDate);

  return (
    <>
      <div className="px-3">
        {sched?.sequence == 0 && (
          <div className="flex">
            <Span color="orange" padding="none" margin="none" width="none">
              Schedule
            </Span>
            <PopupState variant="popover" popupId="demo-popup-popover">
              {(popupState) => (
                <div>
                  <button {...bindTrigger(popupState)}>
                    <img src={info} alt={info} className="info" />
                  </button>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      <Info />
                    </Typography>
                  </Popover>
                </div>
              )}
            </PopupState>
          </div>
        )}

        <div className="flex py-1">
          <img
            src={calendar}
            className="my-auto mx-2"
            style={{ height: "auto", width: 20 }}
            alt=""
          />
          <div
            className="w-full p-2 rounded-md border border-solid border-getgrey-4 cursor-pointer"
            onClick={() => {
              setIsDatePickerShown(true);
              setSequence(sched.sequence);
            }}
          >
            <Span margin="none" padding="none">
              {`${DATE} ${DAY} ${TIME}`.search("Invalid date") !== -1
                ? "Date and Time"
                : `${DATE} ${DAY} ${TIME}`}
            </Span>
          </div>

          {schedule?.length > 1 && (
            <div
              className="pl-2 self-center cursor-pointer"
              onClick={() =>
                dispatch.RiderforADayModel.deleteSchedule(sched.sequence)
              }
            >
              <AiOutlineCloseCircle className=" w-6 h-6 text-red-500" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleSelection;
