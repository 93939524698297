import { get, put } from "../core";

export const getorderlist = async (
  skip: number = 0,
  limit: number = 10,
  page: number = 1
) => {
  try {
    let res = await get(
      `/getall-delivery/user/trip?_start=${skip}&_end=${skip + 10}`
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const cancelOrder = async (orderId: string, cancelReason: string) => {
  try {
    let res = await put(`/express-orders/${orderId}/cancel`, { cancelReason });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const findMyOrder = async (params?: any) => {
  let paramsString = '';

  if (params) {
    const paramEntries = Object.entries(params);

    if (paramEntries.length > 0) {
      paramsString = paramEntries
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
  }

  try {
    let res = await get(`/express-orders?${paramsString}`);
    return res.data;
  } catch (err) {
    throw err;
  }
}; 
 
export const getCompletedExpressOrders = async (pageIndex: any, pageSize: any, filter: any = {}) => { 
  try { 
    let filterString = ''
    if(filter){
      for(let f in filter){
        let value = filter[f];
        filterString = `${filterString}&${f}=${value}`
      }
    }
    const res = await get(`/express-orders?pageIndex=${pageIndex}&pageSize=${pageSize}${filterString}`);
       
    return res.data;
  } catch (err) {
      throw err;
  }
} 
export const getExpressOrdersById = async (orderId: string) => { 
  try {  
    const res = await get(`/express-orders?orderId=${orderId}`);
       
    return res.data.results;
  } catch (err) {
      throw err;
  }
} 

export const getAddsOnFeeById = async (orderId: string) => { 
  try {  
    const res = await get(`/express-orders/${orderId}/get-add-ons-fee`);
       
    return res.data;
  } catch (err) {
      throw err;
  }
} 



export const getCompletedExport = async (pageIndex:any, filter: any = {}) => { 
  try { 
    let filterString = ''
    if(filter){
      for(let f in filter){
        let value = filter[f];
        filterString = `${filterString}&${f}=${value}`
      }
    }
    const res = await get(`/express-orders?exportFile=1&pageIndex=${pageIndex}${filterString}`);
       
    // const res = await get(`/express-orders?exportFile=1${filterString}`);
    return res.data;
  } catch (err) {
      throw err;
  }
} 