import { useSelector } from "react-redux";
import { RootState } from "../../store";
import "./ItemDescription.scss";
import category from "../../assets/img/web/itemcategory-icon.png";
import message from "../../assets/img/web/drivermessage-icon.png";
import { Textarea } from "../../common/Textarea";

interface ItemDescriptionProps {
  onDescriptionChange: (e: any) => void;
  onNotesChange: (e: any) => void;
  itemDescription: any;
}

const ItemDescription = ({
  onDescriptionChange,
  onNotesChange,
  itemDescription
}: ItemDescriptionProps) => {
  const notes = useSelector(
    (state: RootState) => state.Order.deliveryInstruction
  );

  return (
    <div className="item-description px-4">
      <div className="flex">
        <img src={category} alt="" className="item-category" />
        <h6>Item Description</h6>
      </div>
      <Textarea
        placeholder="Document, Food, Things"
        onChange={(e) => {
          if (onDescriptionChange) onDescriptionChange(e);
        }}
        value={itemDescription}
      />

      <div className="flex">
        <img src={message} alt="" className="item-category" />
        <h6>Notes to Driver</h6>
      </div>
      <Textarea
        placeholder="Landmarks or any special instruction"
        value={notes || ""}
        onChange={(e) => {
          if (onNotesChange) onNotesChange(e);
        }}
      />
    </div>
  );
};

export default ItemDescription;
