/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { GridContainer, ModalContainer } from "../../../../common/Container";
import { Span } from "../../../../common/Span";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IoMdCloseCircle } from "react-icons/io";
import { getAttachmentView } from "../../../../common/api";
import "./Content.scss";
import { formatDate } from "../../../../common/utils/helpers";

type Props = {
  setViewAttachmentModal: Function;
  id: any;
};

export default ({ setViewAttachmentModal, id }: Props) => {
  const { scheduleId }: any = useSelector(
    (state: RootState) => state.RiderforADayModel
  );

  const [data, setData] = useState([] as any);
  const getData = async () => {
    try {
      const res = await getAttachmentView(id, scheduleId);
      setData(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <ModalContainer width="large">
        <div className="modal_rental_header">
          <div>
            <Span size="2xl" color="orange" padding="none">
              Attachment Reference ID:
            </Span>
            <Span size="2xl" color="orange" weight="semi">
              {scheduleId}
            </Span>
          </div>

          <div className="my-auto">
            <IoMdCloseCircle
              onClick={() => setViewAttachmentModal(false)}
              className="modal_rental_close"
            />
          </div>
        </div>
        <div className="modal_rental_body">
          <GridContainer grid="two">
            {data
              ?.sort((a, b) => a?.time - b?.time)
              .map((d) => (
                <>
                  <div>
                    <Span color="grey" padding="none">
                      {formatDate(d?.time)}
                    </Span>

                    <GridContainer grid="two" gap="none">
                      <div className="card-image">
                        <a
                          href={d?.url}
                          target="_blank"
                          className="card-image-link"
                        >
                          <img
                            alt={d?.url}
                            src={d?.url}
                            height={100}
                            onClick={d?.url}
                          />
                        </a>
                      </div>
                      <div className="ant-card-body">
                        <div>
                          <Span padding="none" weight="medium">
                            Lat and Lng:
                          </Span>
                          <p>
                            <Span color="grey" padding="none">
                              <a
                                href={`https://www.google.com/maps/place/${
                                  d.lat + "," + d.lng
                                }`}
                                target="_blank"
                                className="link-attachment"
                              >
                                <span className="text-center">
                                  {d.lat + " - " + d.lng}
                                </span>
                              </a>
                            </Span>
                          </p>
                        </div>

                        <div>
                          <Span padding="none" weight="medium">
                            Payment Remarks:
                          </Span>
                          <p>
                            <Span color="grey" padding="none">
                              {d.paymentRemarks ? d.paymentRemarks : "No Data"}
                            </Span>
                          </p>
                        </div>

                        <div>
                          <Span padding="none" weight="medium">
                            Description:
                          </Span>
                          <p>
                            <Span color="grey" padding="none">
                              {d.description ? d.description : "No Data"}
                            </Span>
                          </p>
                        </div>

                        <div>
                          <Span padding="none" weight="medium">
                            Customer Reference:
                          </Span>
                          <p>
                            <Span color="grey" padding="none">
                              {d.customerReference
                                ? d.customerReference
                                : "No Data"}
                            </Span>
                          </p>
                        </div>

                        <div>
                          <Span padding="none" weight="medium">
                            Reference Order Number:
                          </Span>
                          <p>
                            <Span color="grey" padding="none">
                              {d.referenceOrderNumber
                                ? d.referenceOrderNumber
                                : "No Data"}
                            </Span>
                          </p>
                        </div>

                        <div>
                          <Span padding="none" weight="medium">
                            Tags:
                          </Span>
                          <p>
                            <Span color="grey" padding="none">
                              {d.tags
                                ? d?.tags?.filter(Boolean).join(", ")
                                : "No Data"}
                            </Span>
                          </p>
                        </div>
                      </div>
                    </GridContainer>
                  </div>
                </>
              ))}
          </GridContainer>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
