import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../store";
import "./VehicleSelection.scss";
import { IVehicles } from "../../../types/IVehicle";

import { Span } from "../../../common/Span";

import moment from "moment";

const VehicleSelection = ({ vehicleDetails, selectedVehicleType }: any) => {
  const dispatch = useDispatch<Dispatch>();
  const vehicles = useSelector<RootState>(
    (state: RootState) => state.Order.vehicles
  ) as IVehicles;

  const { kgCapacity, cmCapacity, vehicleDescription } = useSelector<RootState>(
    (state: RootState) => state.Order
  ) as any;

  return (
    <>
      <div className="h-40 bg-getblue-1">
        <div className="justify-center text-center pt-3">
          {vehicleDescription ? (
            <>
              <Span size="xl" color="black" weight="semi" transform="uppercase">
                {vehicleDescription}
              </Span>
              <p>
                <Span size="lg" color="black">
                  up to {kgCapacity}kg or {cmCapacity}cm
                </Span>
              </p>
            </>
          ) : (
            <>
              <Span size="xl" color="black" weight="semi" transform="uppercase">
                Please select Vehicle
              </Span>
            </>
          )}

          <div className="vehicle-selection-container">
            {vehicleDetails
              ?.sort((a, b) => a.kgCapacity - b.kgCapacity)
              .map((vehicle) => {
                return (
                  <>
                    <img
                      src={
                        selectedVehicleType === vehicle.code &&
                        vehicleDescription
                          ? vehicle?.defaultImage
                          : vehicle?.inactiveImage
                      }
                      className="vehicle_img"
                      onClick={() => {
                        dispatch.Order.setVehicles(
                          vehicles.map((v: any) => {
                            v.isSelected = v.vehicleType === vehicle.code;
                            return v;
                          })
                        );

                        dispatch.Order.updateState({
                          kgCapacity: vehicle.kgCapacity,
                          cmCapacity: vehicle.cmCapacity,
                          vehicleDescription: vehicle.name,
                          isScheduledSelected: false,
                          isHelperSelected: false,
                          schedule: moment(moment.now()).format("LL")
                        });

                        dispatch.Order.setAddOnsIsChecked({
                          target: "helper",
                          value: false
                        });
                      }}
                      alt=""
                    />
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleSelection;
