/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer/MainContainer";
import Table from "../../components/Table/Table";
import { Column } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../store";
import "react-datetime/css/react-datetime.css";
import "./OrderList.scss";
import { IDataOrder, IOrderList } from "../../types/IDataOrders";
import { IUserInfo } from "../../types";

import firebase from "../../Firebase";
import moment from "moment";

import { Link, useParams } from "react-router-dom";

import { Span } from "../../common/Span";
import { Button } from "../../common/Button";
import { Input } from "../../common/Input";
import { Select } from "../../common/Select";

import { FiSearch } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";

import "react-datepicker/dist/react-datepicker.css";

import NoOrder from "../../assets/img/web/noactivebooking-icon.png";
import NoData from "../../assets/img/Nodata_icon.png";

import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import Content from "./Responsive/Content";

import OrderDetails from "../OrderDetails/OrderDetails";
import { OPEN_ORDERS, VEHICLE } from "../../constants";
import { DeliveryTypeEnum } from "../../types/enum";

let ORDERLIST_UNSUBSCRIBE: any = null;
const ROW_COUNT = 10;

function OrderList() {
  let { referenceNumber }: any = useParams();

  const dispatch = useDispatch<Dispatch>();

  const page: number = useSelector<RootState>(
    ((state) => state.OrderList.page) || 1
  ) as number;

  const isLoading = useSelector<RootState>(
    ((state) => state.OrderList.isLoading) || 1
  ) as number;

  const orderListModel = useSelector<RootState>(
    (state: RootState) => state.OrderList
  ) as IOrderList;

  const { dataOrder: orderList }: any = orderListModel || {};

  const userInfo = useSelector<RootState>(
    (state: RootState) => state.User.userInfo
  ) as IUserInfo;

  const [orderData, setOrderData] = React.useState([] as any);
  const [filteredData, setFilteredData] = React.useState([] as any);
  const [search, setSearch] = useState("");

  const columnOrderList = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: "Order No.",
        accessor: (data) => (
          <p className="order-list-order_number">{data?.orderCode}</p>
        )
      },
      {
        Header: "Vehicle Type",
        accessor: (data) => {
          return <p>{VEHICLE[data?.vehicleType]}</p>;
        }
      },
      {
        Header: "Status",
        accessor: (data) => {
          return (
            <p className={`status_${data?.status?.toLowerCase()} status_label`}>
              {data?.status?.replace("_", " ").toUpperCase()}
            </p>
          );
        }
      },
      {
        Header: "Date Delivered",
        accessor: (data) => {
          return (
            <>
              {data?.status === "delivered" && data?.deliveredAt ? (
                `${moment(data?.deliveredAt?.seconds * 1000).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}`
              ) : (
                <>No Data</>
              )}
            </>
          );
        }
      },
      {
        Header: "Pickup Location",
        accessor: (data) => {
          return (
            <p>
              {data?.pickup?.locationAddress
                ? data?.pickup?.locationAddress
                : data?.pickup}
            </p>
          );
        }
      },
      {
        Header: "Stops",
        accessor: (data) => {
          return <p>{data?.additionalStops}</p>;
        }
      },
      {
        Header: "Destination",
        accessor: (data) => {
          return (
            <p>
              {data?.dropOff?.locationAddress
                ? data?.dropOff?.locationAddress
                : data?.dropOff}
            </p>
          );
        }
      },
      {
        Header: "Item Description",
        accessor: (data) => {
          return <p>{data?.itemDescription}</p>;
        }
      },
      {
        Header: "Total Charge",
        accessor: (data) => {
          return (
            <p>
              {`₱${parseFloat(data?.totalCharge)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </p>
          );
        }
      }
    ],
    []
  );

  const listenToOrderList = async (isLoadMore?: boolean) => {
    if (ORDERLIST_UNSUBSCRIBE) ORDERLIST_UNSUBSCRIBE();

    dispatch.OrderList.setIsLoading(true);

    let tripRef = firebase
      .firestore()
      .collection("orders")
      .where("customerId", "==", userInfo?.userId || "")
      .where("status", "in", OPEN_ORDERS)
      .where("type", "==", DeliveryTypeEnum.EXPRESS)
      .orderBy("createdAt", "desc");

    ORDERLIST_UNSUBSCRIBE = tripRef.onSnapshot((querySnapshot) => {
      let orderList: any = {};

      querySnapshot.forEach((doc) => {
        let data: any = doc.data();

        if (data.stops) {
          data["dropOff"] = data.stops.pop();
          data["additionalStops"] = data.stops.length;
        }

        orderList[data.orderId] = data;
      });

      setOrderData(Object.values(orderList));
    });
  };

  const [statusFilter, setStatusFilter] = useState("all");

  const isDefaultSearchValue = search?.length === 0 && statusFilter === "all";

  const setOrderTable = () => {
    const finalData = !isDefaultSearchValue
      ? orderData.filter((d: any) => {
          if (search.length > 0) {
            return (
              JSON.stringify(Object.values(d)).search(new RegExp(search, "i")) >
              -1
            );
          }
          if (statusFilter != "all") {
            return d?.status.includes(statusFilter);
          }
        })
      : orderData;

    const totalItems = finalData.length;

    dispatch.OrderList.setMaxPage(Math.ceil(totalItems / ROW_COUNT));

    dispatch.OrderList.setState({ page: 1 });
    dispatch.OrderList.setDataOrder({
      currentPage: 1,
      data: finalData.slice(1, ROW_COUNT)
    } as IDataOrder);

    setFilteredData(finalData);
    dispatch.OrderList.setIsLoading(false);
  };

  const setFinalOrderTable = () => {
    const totalItems = filteredData.length;
    dispatch.OrderList.setMaxPage(Math.ceil(totalItems / ROW_COUNT));

    const startIndex = page * ROW_COUNT - ROW_COUNT;
    const endIndex = page * ROW_COUNT;

    dispatch.OrderList.setDataOrder({
      currentPage: 1,
      data: filteredData.slice(startIndex, endIndex)
    } as IDataOrder);

    dispatch.OrderList.setIsLoading(false);
  };

  useEffect(() => {
    setOrderTable();
  }, [orderData, search, statusFilter]);

  useEffect(() => {
    setFinalOrderTable();
  }, [page, filteredData]);

  // Apply filters
  useEffect(() => {
    listenToOrderList(); // reload triplist with new snapshot
  }, []);

  return (
    <>
      <MainContainer className="order-list">
        <div className="order">
          <div className="mt-10 flex">
            <Link to="/order-list">
              <div className="line">
                <div className="bottom">
                  <Span size="xl" weight="semi" color="orange">
                    Express List
                  </Span>
                </div>
              </div>
            </Link>

            <Link to="/order-history-rental">
              <div className="line">
                <div>
                  <Span size="xl" weight="medium">
                    Rental List
                  </Span>
                </div>
              </div>
            </Link>
          </div>

          <div className="order-list-sub-categories">
            <Link to="/order-list">
              <div className="line">
                <div className="bottom">
                  <Span size="base" weight="semi" color="orange">
                    Open Order
                  </Span>
                </div>
              </div>
            </Link>

            <Link to="/order-list-completed">
              <div className="line">
                <div>
                  <Span size="base">Complete Order</Span>
                </div>
              </div>
            </Link>
          </div>

          <div className="order__grid7">
            <div className="filter">
              <div className="status">
                <div className="my-auto relative flex-none w-full p-1.5">
                  <Span
                    position="absolute"
                    size="xl"
                    width="none"
                    margin="none"
                    padding="none"
                  >
                    <FaFilter />
                  </Span>
                  <Select
                    className="w-11/12 ml-4"
                    onChange={(e: any) => {
                      setStatusFilter(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      Select Status{" "}
                    </option>
                    <option value="all"> All Status </option>
                    <option value="pending"> Pending </option>
                    <option value="assigned"> Assigned </option>
                    <option value="for_pickup"> For Pickup </option>
                    <option value="picked_up"> Picked Up </option>
                    <option value="on_going"> On Going </option>
                  </Select>
                </div>
              </div>

              <div className="search">
                <div className="my-auto relative flex-none w-full p-1.5">
                  <Span position="absolute" size="xl" width="none">
                    <FiSearch className="my-auto" />
                  </Span>
                  <Input
                    padding="pl-10"
                    border="none"
                    type="text"
                    radius="md"
                    width="full"
                    value={search}
                    onChange={(e: any) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search anything"
                  />
                </div>
              </div>
            </div>

            {/* <div className="datefilter">
              <div className="px-2.5 py-1.5">
                <div className="my-auto relative flex-none w-full">
                  <Span
                    position="absolute"
                    size="xl"
                    width="none"
                    margin="none"
                    padding="none"
                  >
                    <BsCalendar className="my-auto" />
                  </Span>
                </div>

                <div className="px-10">
                  <div className="flex justify-between">
                    <DatePicker
                      className="text-base focus:outline-none w-full"
                      selected={startDate}
                      onChange={(date: any) => {
                        setStartDate(date);
                        setisFilterChange(true);
                      }}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Date From"
                      dateFormat="MMMM d, yyyy"
                    />

                    <DatePicker
                      className="text-base focus:outline-none w-full"
                      selected={endDate}
                      onChange={(date: any) => {
                        setEndDate(date);
                        setisFilterChange(true);
                      }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="Date to"
                      dateFormat="MMMM d, yyyy"
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          {!orderList?.data?.length && !filteredData.length ? (
            !filteredData.length ? (
              <div className="flex min-h-screen justify-center items-center">
                <div>
                  <img src={NoData} alt="" className="mt-3 h-24 mx-auto" />
                  <div className="text-center my-6">
                    <Span size="xl" color="grey">
                      No Data Found
                    </Span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex min-h-screen justify-center items-center">
                <div>
                  <img src={NoOrder} style={{ width: 250 }} />
                  <div className="text-center my-6">
                    <Span size="xl" weight="semi">
                      You don't have any active Order
                    </Span>
                    <p className="my-1">
                      <Span size="lg">Here you'll see all your Order.</Span>
                    </p>
                    <Link to="./order">
                      <Button weight="semi" margin="top">
                        Book Order
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div>
              {referenceNumber && (
                <>
                  <OrderDetails datatype="express-orders" />
                </>
              )}

              <div className="maintable">
                <Table
                  columns={columnOrderList}
                  data={orderList?.data}
                  currentPage={orderList?.currentPage || 0}
                  hideFooterControl={true}
                  showOrderDetails={true}
                  isLoading={isLoading}
                  dataType="express-orders"
                />

                <Pagination />
              </div>

              <Content orderList={orderList.data} />
            </div>
          )}
        </div>
      </MainContainer>
    </>
  );
}

export default OrderList;

function Pagination() {
  const dispatch = useDispatch<Dispatch>();

  const currentPage =
    useSelector((state: RootState) => state.OrderList.page) || 1;
  const maxPage = useSelector((state: RootState) => state.OrderList.maxPage);
  const isLoading = useSelector(
    (state: RootState) => state.OrderList.isLoading
  );

  const handlePrevious = () => {
    dispatch.OrderList.handlePrevious();
  };

  const handleNext = () => {
    dispatch.OrderList.handleNext();
  };

  if (!maxPage) return null;

  return (
    <section className="flex">
      <Button
        width="none"
        padding="normal"
        bg="lightorange"
        disabled={isLoading || currentPage === 1}
        onClick={handlePrevious}
      >
        <div className="flex text-center">
          <CgChevronLeft size={20} />
          <span>Previous</span>
        </div>
      </Button>
      <div className="my-auto mx-2">
        Page {currentPage} of {maxPage}
      </div>
      <Button
        width="none"
        padding="normal"
        bg="lightorange"
        disabled={isLoading || currentPage === maxPage}
        onClick={handleNext}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Next</span>
          <CgChevronRight size={20} />
        </div>
      </Button>
    </section>
  );
}
