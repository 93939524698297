/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useMemo, useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer/MainContainer";
import NoData from "../../assets/img/Nodata_icon.png";

import Table from "../../components/Table/Table";
import { Column } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import "./WalletLedger.scss";
import WalletIcon from "../../assets/img/web/AEPLogo.png";

import { Button } from "../../common/Button";
import { Span } from "../../common/Span";
import Content from "./Responsive/Content";

import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { IDataWallet, IWalletList } from "../../types/IDataWallet";
import { getRecentTransactions } from "../../common/api";
import { FiSearch } from "react-icons/fi";
import { Input } from "../../common/Input";
import { Spinner } from "react-bootstrap";
import NoWallet from "../../assets/img/web/nowallettransaction-icon.png";

const ROW_COUNT = 10;

function WalletLedger() {
  const balance: any = useSelector<RootState>(
    (state: RootState) => state.Wallet.balance
  );

  const page: number = useSelector<RootState>(
    ((state) => state.WalletList.page) || 1
  ) as number;

  const isLoading: any = useSelector<RootState>(
    (state: RootState) => state.WalletList.isLoading
  );

  const walletListModel = useSelector<RootState>(
    (state: RootState) => state.WalletList
  ) as IWalletList;

  const { dataWallet: walletList }: any = walletListModel || {};

  const dispatch = useDispatch<Dispatch>();

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: "Transaction ID.",
        accessor: "id",
        sortType: "basic"
      },

      {
        Header: "Payment Type",
        accessor: "typeDescription",
        sortType: "basic"
      },
      {
        Header: "Amount",
        accessor: "totalAmountDescription",
        sortType: "basic"
      },
      {
        Header: "Date",
        accessor: "dateDescription",
        sortType: "basic"
      }
    ],
    []
  );

  const [walletData, setWalletData] = useState([] as any); //row data
  const [filteredData, setFilteredData] = useState([] as any);

  const [totalRecord, setTotalRecord] = useState(1); //TotalRecord

  const [search, setSearch] = useState("");
  const isDefaultSearchValue = search?.length === 0;

  const listenToWallet = async (page, size) => {
    dispatch.WalletList.setIsLoading(true);
    try {
      const res = await getRecentTransactions(page, size);
      setTotalRecord(res?.meta?.totalItems);
      setWalletData(res?.items || []);
    } catch (err) {
      console.log(err);
      throw err;
    }
    dispatch.WalletList.setIsLoading(false);
  };

  useEffect(() => {
    listenToWallet(page, ROW_COUNT);
  }, [page]);

  const setWalletTable = () => {
    const finalData = !isDefaultSearchValue
      ? walletData.filter((d: any) => {
          if (search.length > 0) {
            return (
              JSON.stringify(Object.values(d)).search(new RegExp(search, "i")) >
              -1
            );
          }
        })
      : walletData;

    dispatch.WalletList.setMaxPage(Math.ceil(totalRecord / ROW_COUNT));

    //   dispatch.WalletList.setState({ page: 1 });

    dispatch.WalletList.setDataWallet({
      currentPage: 1,
      data: finalData
    } as IDataWallet);

    setFilteredData(walletData);

    dispatch.WalletList.setIsLoading(false);
  };
  const setFinalWalletTable = () => {
    dispatch.WalletList.setMaxPage(Math.ceil(totalRecord / ROW_COUNT));

    dispatch.WalletList.setDataWallet({
      currentPage: 1,
      data: filteredData
    } as IDataWallet);

    dispatch.WalletList.setIsLoading(false);
  };

  useEffect(() => {
    setWalletTable();
  }, [walletData, search]);

  useEffect(() => {
    setFinalWalletTable();
  }, [page, filteredData]);

  const renderLoading = () => {
    return (
      <div className="d-flex justify-content-center align-items-center h-screen w-full">
        <Spinner animation="border" role="status" className="spinner">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  };

  return (
    <>
      <MainContainer className="wallet">
        <div className="wallet__list">
          <div className="justify-between flex py-2">
            <div className="my-auto">
              <Span size="lg" weight="semi">
                My Wallet
              </Span>
            </div>

            <div className="flex">
              <div className="wallet__balance">
                <img src={WalletIcon} alt="" width={75} />
                <Span weight="semi" color="orange">
                  PHP {balance ?? 0}
                </Span>
              </div>
            </div>
          </div>
          {!balance ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 80
              }}
            >
              <img src={NoWallet} style={{ width: 250 }} />
              <div className="text-center my-6">
                <Span size="xl" weight="semi">
                  You don't have any Transaction
                </Span>
                <p className="my-1">
                  <Span size="lg">
                    Here you'll see all your Transaction History.
                  </Span>
                </p>
              </div>
            </div>
          ) : (
            <>
              {!isLoading && walletData?.length ? (
                <>
                  <div className="wallet_table maintable">
                    {/* Search */}
                    <div className="wallet__search">
                      <Span position="absolute" size="xl" width="none">
                        <FiSearch className="my-auto" />
                      </Span>
                      <Input
                        padding="pl-10"
                        border="none"
                        type="text"
                        radius="md"
                        width="full"
                        value={search}
                        onChange={(e: any) => {
                          setSearch(e.target.value);
                        }}
                        placeholder="Search anything"
                      />
                    </div>

                    {walletList?.data?.length ? (
                      <>
                        <div className="maintable">
                          <Table
                            columns={columns}
                            data={walletList?.data}
                            currentPage={walletList?.currentPage || 0}
                            // hideFooterControl={true}
                            // showOrderDetails={true}
                            isLoading={isLoading}
                          />

                          <Pagination />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex min-h-screen justify-center items-center">
                          <div>
                            <img
                              src={NoData}
                              alt=""
                              className="mt-3 h-24 mx-auto"
                            />
                            <div className="text-center my-6">
                              <Span size="xl" color="grey">
                                No Data Found
                              </Span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <Content data={walletList?.data} />
                </>
              ) : (
                renderLoading()
              )}
            </>
          )}
        </div>
      </MainContainer>
    </>
  );
}

export default WalletLedger;

function Pagination() {
  const dispatch = useDispatch<Dispatch>();

  const currentPage =
    useSelector((state: RootState) => state.WalletList.page) || 1;
  const maxPage = useSelector((state: RootState) => state.WalletList.maxPage);
  const isLoading = useSelector(
    (state: RootState) => state.WalletList.isLoading
  );

  const handlePrevious = () => {
    dispatch.WalletList.handlePrevious();
  };

  const handleNext = () => {
    dispatch.WalletList.handleNext();
  };

  if (!maxPage) return null;

  return (
    <section className="flex">
      <Button
        width="none"
        padding="normal"
        bg="lightorange"
        disabled={isLoading || currentPage === 1}
        onClick={handlePrevious}
      >
        <div className="flex text-center">
          <CgChevronLeft size={20} />
          <span>Previous</span>
        </div>
      </Button>
      <div className="my-auto mx-2">
        Page {currentPage} of {maxPage}
      </div>
      <Button
        width="none"
        padding="normal"
        bg="lightorange"
        disabled={isLoading || currentPage === maxPage}
        onClick={handleNext}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Next</span>
          <CgChevronRight size={20} />
        </div>
      </Button>
    </section>
  );
}
