import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../../../store";

import { Col } from "react-bootstrap";
import { Button } from "../../../../common/Button";

import CalendarIconActive from "../../../../assets/img/web/scheduled-icon-black.png";
import ClockIconActive from "../../../../assets/img/web/pickupnow-icon-black.png";
import moment from "moment";

const ImmediateScheduled = ({
  setIsDatePickerShown,
  setSequence, 
  setImmediate,
  setScheduled,
  isInstant,
  isScheduled
}: any) => {
  const dispatch = useDispatch<Dispatch>();

  const { scheduleMethod, scheduleType, isScheduledSelected, vehicleDescription }: any =
    useSelector((state: RootState) => state.RiderforADayModel);

  return (
    <>
      <div
        className="mb-3 mx-3"
        style={{ backgroundColor: "#DDDDDD", borderRadius: 5 }}
      >
        <div className="d-flex flex-row">
          <Col lg="6" className="px-0">
            <Button
              radius="r-none"
              border="grey"
              bg={
                scheduleMethod === "2" && isScheduledSelected
                  ? "orange"
                  : "grey"
              }
              onClick={() => {
                setImmediate(true);
                setScheduled(false);

                scheduleType === "halfday"
                  ? dispatch.RiderforADayModel.updateSchedule({
                      sequence: 0,
                      startDate: moment(moment.now()).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      endDate: moment(moment.now())
                        .add(5, "hours")
                        .format("YYYY-MM-DD HH:mm:ss")
                    })
                  : dispatch.RiderforADayModel.updateSchedule({
                      sequence: 0,
                      startDate: moment(moment.now()).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      endDate: moment(moment.now())
                        .add(9, "hours")
                        .format("YYYY-MM-DD HH:mm:ss")
                    });

                dispatch.RiderforADayModel.updateState({
                  scheduleMethod: "2",
                  isScheduledSelected: true
                });
              }}
              disabled={!isInstant || !vehicleDescription || !scheduleType }
            >
              <img
                src={ClockIconActive}
                className="m-auto py-1"
                style={{ height: "auto", width: 20 }}
                alt=""
              />
              <span className="text-getblack-1">Immediate</span>
            </Button>
          </Col>
          <Col lg="6" className="px-0">
            <Button
              radius="l-none"
              bg={
                scheduleMethod === "1" && isScheduledSelected
                  ? "orange"
                  : "grey"
              }
              border="grey"
              onClick={() => {
                setIsDatePickerShown(true);
                setSequence(0);
                setImmediate(false);
                setScheduled(true);
                dispatch.RiderforADayModel.updateState({
                  scheduleMethod: "1",
                  isScheduledSelected: true
                });
              }} 
              disabled={!isScheduled || !vehicleDescription || !scheduleType}
            >
              <img
                src={CalendarIconActive}
                className="m-auto py-1"
                style={{ height: "auto", width: 18 }}
                alt=""
              />
              <span className="text-getblack-1">Scheduled</span>
            </Button>
          </Col>
        </div>
      </div>
    </>
  );
};

export default ImmediateScheduled;
