/* eslint-disable eqeqeq */
import { createModel } from "@rematch/core";
import moment from "moment";
import { IDestinations } from "../types";
import { RootModel } from ".";
import {
  requestOrder,
} from "../common/api/endpoints/order";
import { IVehicles } from "../types/IVehicle";
import { getFare,getDefaultRates } from "../common/api/endpoints/price"; 
import { ILocations } from "../types/ILocations";

interface ISetAddOnsIsCheckedPayload {
  target: string;
  value: boolean;
} 

interface ISetSubAddOnsIsSelected extends ISetAddOnsIsCheckedPayload {
  categoryValue: string;
}

export interface DriverDetailsProps {
  driverId?: string | null;
  bikerId?: string | null;
  driverName?: string | null;
}

type MapType = "GOOGLE" | "LEAFLET";
type MapPermission = "READ" | "WRITE";

export interface State {
  rates: Array<any>;
  amountToBeCollected: number;
  amountCollectedRemitted: number,
  amountToBeRemitted: number; 
  collectAmountFrom?: string;
  isRemitOrCollect: boolean;
  driverTip: number;
  remittanceFee: number;
  cashHandling: number;
  addOns: any;
  description: any;
  isEditMode: boolean;
  isModalShown: boolean;
  isOptimizeLocation: boolean;
  lastUpdate: number;
  optimizeBasedLocations: ILocations | null;
  recentSources: ILocations;
  recentDestinations: ILocations;
  schedule: string;
  scheduleType: "1" | "2";
  totalFee: number | null;
  vehicles: any;
  // Trip Type
  // 1 - one way
  // 2 - round trip
  tripType: 1 | 2;
  reference: string | null; // reference generated when getting trip fare
  itemDescription: string | null;
  deliveryInstruction: string | null;
  isRequestOrderLoading: boolean;
  requestOrderResponse: any;
  isFareDetailsLoading: boolean;
  orderModified: boolean; // Attribute used to distinguish if need to recalculate fare
  isValidatePromoCodeLoading: boolean;
  isRequestingAddressByLatLng: boolean;
  isAddOnsLoading: boolean;
  isLoading: boolean;
  promoCode: string | null;
  isValidPromoCode: boolean | null;
  vehicleType: string | null;
  /**
   * Payment Modes
   * 1 - Cash
   * 2 - GetAll
   */
  paymentMethod: string;
  billTo?: string;
  isValidateDriverIdLoading?: boolean;

  // Vehicle 
  kgCapacity?: number | null;
  cmCapacity?: number | null;
  vehicleDescription?: string;
  vehicleName?: string;


  /**
   * Validate driver status
   * 0 | null - no processing yet
   * 1 - success
   * 2 - fail
   * 3 - not found
   */
  validateDriverStatus?: number;
  validateDriverError?: string;
  driverDetails?: DriverDetailsProps | null;

  /*
    ALL: 1,
    FLEET: 2,
    ASSIGN: 3
  */
  isSavingLocationToFavorite: boolean;
  merchantReference?: string | null;
  mapType?: MapType;
  mapPermission?: MapPermission;
  orderDetails: any;
  isCashHandlingModal: boolean; 
  isScheduledSelected: boolean,
  isHelperSelected: boolean,
}

export const initialState = {
  rates: [],
  driverTip: 0,
  remittanceFee: 0,
  cashHandling: 0,
  amountCollectedRemitted: 0,
  amountToBeCollected: 0,
  amountToBeRemitted: 0,
  collectAmountFrom: '', 
  isRemitOrCollect: false,
  description:{
    motorcycle:{
      name: 'Motorcyle',
      description: '20kg'
    },
    mpv300: {
      name: 'MPV300', 
      description: '300kg'
    },
    mpv600: {
      name: 'MPV600',
      description: '600kg'
    },
    lt1000: {
      name: 'LT1000', 
      description: '1000kg'
    },
    truck: {
      name: 'Truck',
      description: '2000kg'
    }
  },
  addOns: {
    insulatedBag: {
      name: "Insulated Bag",
      isChecked: false,
      price: 0
    },
    premiumService: {
      name: "Premium Service",
      isChecked: false,
      price: 50
    },
    premiumService100: {
      name: "Additional Premium Service",
      isChecked: false,
      price: 100
    },
    cashHandling: {
      name: "Cash Handling",
      isChecked: false,
      description: '*COD-Max of ₱5,000',
      price: 30
    },       
    queueingService: {
      name: "Queuing Service",
      isChecked: false,
      price: 70
    },
    purchaseService: { 
      name: "Purchase Service",
      isChecked: false,
      price: 50
    },
    overweightHandling: {
      name: "Driver Carries", 
      isChecked: false,
      price: 30
    }, 
    helper: {
      name: "Helper Fee", 
      isChecked: false,
      price: 30
    }
  },
  isEditMode: false,
  isModalShown: false,
  isOneWay: true,
  isOptimizeLocation: false,
  isRequestingAddressByLatLng: false,
  isSavingLocationToFavorite: false,
  lastUpdate: moment.now(),
  optimizedDestinations: [],
  recentDestinations: [],
  recentSources: [],
  vehicles: [
    {
      vehicleType: "motorcycle",
      isSelected: true,
      vehicleDescription: "motorcycle",
      description: "20kg",
      availableAddOns: ["insulatedBag","premiumService", "cashHandling",  "purchaseService", "queueingService"],
      location: "Manila"
    },
    {
      vehicleType: "mpv200",
      vehicleDescription: "MPV200",
      availableAddOns: ["premiumService", "cashHandling", "overweightHandling", "purchaseService", "queueingService"],
      description: "200kg",
      location: "Manila"
    },
    {
      vehicleType: "mpv300",
      vehicleDescription: "MPV300",
      availableAddOns: ["premiumService", "cashHandling", "overweightHandling", "purchaseService", "queueingService", "helper"],
      description: "300kg",
      location: "Manila"
    },
    {
      vehicleType: "mpv600",
      vehicleDescription: "MPV600",
      availableAddOns: ["overweightHandling","premiumService", "cashHandling",  "queueingService", "helper"],
      description: "600kg",
      location: "Manila"
    },
    {
      vehicleType: "lt1000",
      vehicleDescription: "LT1000",
      availableAddOns: ["insulatedBag", "overweightHandling","premiumService", "cashHandling",   "queueingService", "helper"],
      description: "1000kg",
      location: "Manila"
    },
    {
      vehicleType: "truck",
      vehicleDescription: "Truck",
      availableAddOns: ["overweightHandling", "premiumService", "cashHandling", "queueingService", "purchaseService", "helper"],
      description: "2000kg",
      location: "Manila"
    }
  ],
  scheduleType: "2",
  vehicleType: "",
  vehicleDescription: "",
  kgCapacity: null,
  cmCapacity: null,
  schedule: moment(moment.now()).format("LLLL"),
  tripType: 1,
  serviceType: 1,
  totalFee: null,
  reference: null,
  itemDescription: null,
  deliveryInstruction: null,
  isRequestOrderLoading: false,
  requestOrderResponse: null,
  isFareDetailsLoading: false,
  orderDetails: null,
  orderModified: false,
  isValidatePromoCodeLoading: false,
  isValidPromoCode: null,
  isAddOnsLoading: false, 
  isLoading: false,
  optimizeBasedLocations: null,
  promoCode: null,
  paymentMethod: '',
  billTo: '',  
  driverDetails: null,
  merchantReference: null,
  mapType: "GOOGLE",
  mapPermission: "WRITE",
  isCashHandlingModal: false,
  isScheduledSelected: false, 
  isHelperSelected: false,
} as State;

export const Order = createModel<RootModel>()({
  state: initialState,
  reducers: {
    /**
     * @name resetState
     * @desc Reset order attributes
     */
    resetState(state) {
      // making sure that pop over were close
      return { ...state, ...initialState };
    },
    /**
     * @name resetAddOns
     * @desc Sets addOns to its original state
     */
    resetAddOns(state) {
      return {
        ...state,
        addOns: { ...initialState.addOns }
      };
    },
    /**
     * @name setAddOnsIsChecked
     * @desc Sets isChecked attributes of addOns to true or false
     * @param {object} payload that has attributes of payload.target and payload.value
     */
    setAddOnsIsChecked(state, payload: ISetAddOnsIsCheckedPayload) {
      const { target, value } = payload;

      let additionalFields = {};
      if(target == "driverTip"){
        additionalFields["driverTip"] = value ? 50 : 0;
      } 

      return {
        ...state,
        addOns: {
          ...state.addOns,
          [target]: { ...state.addOns[target], isChecked: value }
        },
        orderModified: true
      };
    },
    /**
     * @name setSubAddOnsIsSelected
     * @desc Sets isSelected attributes of add ons subcategory to true or false
     * @param {object} payload that has attributes of payload.target, payload.categoryValue and payload.value
     */
    setSubAddOnsIsSelected(state, payload: ISetSubAddOnsIsSelected) {
      const { target, categoryValue, value } = payload;

      return {
        ...state,
        addOns: {
          ...state.addOns,
          [target]: {
            ...state.addOns[target],
            subAddOns: state.addOns[target].subAddOns.map((elem: any) => {
              if (elem.value === categoryValue) {
                elem.isSelected = value;
              } else {
                elem.isSelected = !value;
              }
              return elem;
            })
          }
        },
        orderModified: true
      };
    },
    /**
     * @name setTripType
     * @desc Sets state.tripType to be 1 | 2
     * @param {1|2} payload { 1 | 2 }
     */
    setTripType(state, payload: 1 | 2) {
      return { ...state, tripType: payload, orderModified: true };
    },
    setRates(state, payload: Array<any>) {
      return { ...state, rates: payload, orderModified: true };
    },
    setIsEditMode(state, payload: boolean) {
      return { ...state, isEditMode: payload };
    },
    setIsModalShown(state, payload: boolean) {
      return { ...state, isModalShown: payload };
    },
    setDestinations(state, payload: IDestinations) {
      return { ...state, destinations: payload };
    },
    setIsOptimizedLocation(state, payload: boolean) {
      return { ...state, isOptimizeLocation: payload, orderModified: true };
    },
    setLastUpdate(state, payload: number) {
      return { ...state, lastUpdate: payload };
    },
    setSchedule(state, payload: string) {
      return { ...state, schedule: payload, orderModified: true };
    },
    /**
     * @name setVehicles
     * @desc Sets vehicles state
     * @param {IVehicles} payload
     *
     **/
    setVehicles(state, payload: IVehicles) {
      return { ...state, vehicles: [...payload], orderModified: true };
    },
    setPromoCode(state, payload: string | null) {
      return { ...state, promoCode: payload, orderModified: true };
    },
    toggleMap(state) {
      let mapType: MapType;
      if (state.mapType === "LEAFLET") mapType = "GOOGLE";
      else mapType = "GOOGLE";

      return { ...state, mapType };
    },
    setMapPermission(state, permission: MapPermission) {
      return { ...state, mapPermission: permission };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState };
    }
  },
  effects: (dispatch) => ({
    /**
     * @name calculateFare
     * @desc get trip price based on the set parameters
     *
     */
    async calculateFare(payload, rootState) {
      if (rootState.Order.isFareDetailsLoading) return; // Prevent from multiple loading of http

      if(rootState.Locations?.dropOff?.lat === 0){
        return;
      }
      let response, totalFee, reference, request; 
      const selectedVehicle = (rootState.Order.vehicles || []).find(
        (vehicle: any) => vehicle?.isSelected
      );


      try {
        // let option = Object.keys(rootState.Order.addOns).filter((key) => 
        // key == "cashHandling" ? rootState.Order.remittanceFee > 0 || rootState.Order.collectAmountFrom ?
        //   false : rootState.Order.addOns[key].isChecked : rootState.Order.addOns[key].isChecked)


        // if(rootState.Order.remittanceFee > 0) {
        //   option.push("remittanceService")  
        // }

        let option = Object.keys(rootState.Order.addOns).filter((key) => 
        Boolean(rootState.Order.addOns[key].isChecked) === true)

        request = {
          "vehicleType": selectedVehicle.vehicleType,
          "paymentMethod": rootState.Order.paymentMethod,
          "orderType": rootState.Order.scheduleType === "1" ? 'scheduled' : 'immediate',
          "pickup": rootState.Locations.pickup,
          "stops": [...rootState.Locations.stops, rootState.Locations.dropOff],
          "options": option,
          "driverTip": rootState.Order.driverTip,
          "amountToBeCollected": rootState.Order.amountToBeCollected, 
          "collectAmountFrom": rootState.Order.collectAmountFrom ? rootState.Order.collectAmountFrom : null, 
          "isElectronicRemittance": rootState.Order.isRemitOrCollect, 
          "voucherCode": rootState.Order.promoCode
        };

        if(rootState.Order.scheduleType === "1" ){
          request["scheduleStartTime"] = moment(rootState.Order.schedule).format('YYYY-MM-DD HH:mm:ss')
        }

        dispatch.Order.updateState({ isFareDetailsLoading: true, totalFee: null});
        response = await getFare(request);
        totalFee = response.fare.net;
        reference = response.token;

        
        dispatch.Order.updateState({
          isFareDetailsLoading: false,
          totalFee,
          reference,
          orderModified: false,
          isValidPromoCode: rootState.Order.promoCode ? true : null,
          orderDetails: {...response, payload: request} 
        });
      } catch (err) {

        dispatch.UI.setAlert({ message: typeof err === 'string' ? err: "", type: "error" }); 

        dispatch.Order.updateState({
          isFareDetailsLoading: false,
          isValidPromoCode: rootState.Order.promoCode ? false : null,
          promoCode: null
        });
        
        return err;
      } finally {
      }
    },
    /**
     * @name requestOrder
     * @desc send request for order
     *
     */
    async requestOrder(payload, rootState) {
      // @ts-ignore
      let userInfo = {
        ...rootState.User.userInfo
      };
      dispatch.User.updateState({
        // @ts-ignore
        userInfo
      });

      let recentDestinations = [...rootState.Order.recentDestinations];
      let recentSources = [...rootState.Order.recentSources];

      if (rootState.Order.isRequestOrderLoading) return; // Prevent from multiple loading of http

      
      dispatch.Order.updateState({
        // merchantReference: null,
        isRequestOrderLoading: true
      });


      let response;
      try {
        let body = {
          ...rootState.Order.orderDetails,
          itemDescription: rootState.Order.itemDescription,
          notes: rootState.Order.deliveryInstruction,
          paymentMethod: rootState.Order.paymentMethod,
          billTo: rootState.Order.billTo
        };

        response = await requestOrder(body);

        if(response.order?.orderCode){
          dispatch.Order.resetState();
          dispatch.Order.updateState({
            recentSources,
            recentDestinations
          });
          dispatch.Locations.resetState();
          dispatch.OrderConfirmation.resetState();
        }else{
          response = {
            message: "error creating booking"
          }
        }


      } catch (err: any) { 
        response = err?.response?.data;
      } finally { 
        dispatch.Order.updateState({ 
          isRequestOrderLoading: false,
          requestOrderResponse: response
        });
      }
    },
    async getDefaultRates(payload, rootState) {
      let rates = await getDefaultRates();

      if((rates ?? []).length > 1){
        dispatch.Order.setRates(rates)
      }

    }
    
  })
});
