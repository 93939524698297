import { Span } from "../../../../common/Span";
import { PAYMENTMETHOD } from "../../../../constants";

const PaymentMethod = ({rentals}: any) => { 
  return (<> 
  <div className="my-4"> 
      <Span color="lightgrey" weight="semi" size="base">Price</Span>
      
      <div className="flex justify-between">
        <Span weight="semi" margin='none' size="base">Total (Incl. VAT)</Span>
        <Span weight="semi" margin='none' size="base" align="right" whitespace="nowrap" color="orange">
          {`₱ ${parseFloat(rentals?.originTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        </Span>
      </div> 
        
      <div className="flex justify-between"> 
        <Span padding='px-2' margin='none'>Payment Method</Span> 
        <Span padding='px-2' margin='none' align="right">{PAYMENTMETHOD[rentals?.paymentMethod]}
        </Span> 
      </div>

      {rentals?.paymentDetails?.branch &&
        <div className="flex justify-between"> 
          <Span padding='px-2' margin='none'>Branch</Span> 
          <Span padding='px-2' margin='none' align="right">{rentals?.paymentDetails?.branch}
          </Span> 
        </div>
      }

      {rentals?.paymentDetails?.brand &&
        <div className="flex justify-between"> 
          <Span padding='px-2' margin='none'>Brand</Span> 
          <Span padding='px-2' margin='none' align="right">{rentals?.paymentDetails?.brand}
          </Span> 
        </div>
      }

      {rentals?.paymentDetails?.chargeTo &&
        <div className="flex justify-between"> 
          <Span padding='px-2' margin='none'>Charge to</Span> 
          <Span padding='px-2' margin='none' align="right">{rentals?.paymentDetails?.chargeTo}
          </Span> 
        </div>
      }
      
      {rentals?.paymentDetails?.corporateBillingName &&
        <div className="flex justify-between"> 
          <Span padding='px-2' margin='none'>Corporate Billing Name</Span> 
          <Span padding='px-2' margin='none' align="right">{rentals?.paymentDetails?.corporateBillingName}
          </Span> 
        </div>
      }

      {rentals?.paymentDetails?.email &&
        <div className="flex justify-between"> 
          <Span padding='px-2' margin='none'>Email</Span> 
          <Span padding='px-2' margin='none' align="right">{rentals?.paymentDetails?.email}
          </Span> 
        </div>
      }

      {rentals?.paymentDetails?.name &&
        <div className="flex justify-between"> 
          <Span padding='px-2' margin='none'>Name</Span> 
          <Span padding='px-2' margin='none' align="right">{rentals?.paymentDetails?.name}
          </Span> 
        </div>
      }
       
      {rentals?.paymentDetails?.remarks &&
        <div className="flex justify-between"> 
          <Span padding='px-2' margin='none'>Remarks</Span> 
          <Span padding='px-2' margin='none' align="right">{rentals?.paymentDetails?.remarks}
          </Span> 
        </div>
      }
       
    </div>
   </>);
};

export default PaymentMethod;