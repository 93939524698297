import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { currencyCommaFormat } from "../../../common/utils";
import { Span } from "../../../common/Span";

const FeeBreakdown = () => {
  const orderDetails = useSelector<RootState>(
    (state: RootState) => state.Order.orderDetails
  ) as any;

  return (
    <>
      <div className="py-2 border-dashed border-b border-getgrey-1">
        <div className="flex justify-between">
          <div>
            <Span margin="none" padding="py-2">
              Delivery Fee
            </Span>
          </div>
          <div>
            <Span margin="none" padding="py-2">
              ₱ {currencyCommaFormat(orderDetails?.fare?.baseDeliveryFee, 2)}
            </Span>
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <Span margin="none" padding="py-2">
              Stop Charge
            </Span>
          </div>
          <div>
            <Span margin="none" padding="py-2">
              ₱ {currencyCommaFormat(orderDetails?.fare?.stopCharge, 2)}
            </Span>
          </div>
        </div>
        {orderDetails?.fare?.serviceFee ? (
          <div className="flex justify-between">
            <div>
              <Span margin="none" padding="py-2">
                Premium Fee
              </Span>
            </div>
            <div>
              <Span margin="none" padding="py-2">
                ₱ {currencyCommaFormat(orderDetails?.fare?.serviceFee, 2)}
              </Span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {Object.values(orderDetails?.fare?.details ?? {}).map((elem: any) => {
          return (
            <>
              <div className="flex justify-between">
                {elem.particular === "Stop Charge" &&
                elem.amount === 0 ? null : (
                  <>
                    <div>
                      <Span margin="none" padding="py-2" transform="capitalize">
                      {elem.particular === "After Hours Surcharge Fee"
                          ? "Peak Hours Surcharge Fee"
                          : elem.particular}
                      </Span>
                    </div>
                    <div>
                      <Span margin="none" padding="py-2">
                        ₱ {currencyCommaFormat(elem.amount, 2)}
                      </Span>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default FeeBreakdown;
