import { Span } from "../../common/Span";
import { formatDate } from "../../common/utils";
import "./RentalList.scss";

export const COLUMNS = [
  {
    Header: "Order No.",
    accessor: (data) => <Span weight="semi">{data?.orderCode}</Span>
  },
  {
    Header: "Name",
    accessor: (data) => (
      <Span transform="capitalize" weight="semi">
        {data?.client?.name}
      </Span>
    )
  },
  {
    Header: "Contact Number",
    accessor: (data) => <p>{data?.client?.contactNumber}</p>
  },
  {
    Header: "Location Details",
    accessor: (data) => {
      return <p>{data?.client?.locationDetails?.toUpperCase()}</p>;
    }
  },
  {
    id: "status",
    field: "status",
    accessor: "status",
    Header: "Status",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <p className={`status_${d?.status?.toLowerCase()} status_label`}>
          {d?.status?.replace("_", " ").toUpperCase()}
        </p>
      );
    }
  },
  {
    Header: "Created At",
    id: "createdAt",
    accessor: (data) => data?.createdAt?.seconds,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const date = !d?.createdAt ? false : formatDate(d?.createdAt);
      return <div className="flex justify-center">{date}</div>;
    }
  },
  {
    Header: "Origin Total",
    id: "originTotal",
    accessor: (data) => data?.originTotal,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          {`₱ ${parseFloat(d?.originTotal)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        </div>
      );
    }
  },
  {
    Header: "Running Total",
    id: "runningTotal",
    accessor: (data) => data?.originTotal,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          {`₱ ${parseFloat(d?.runningTotal)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        </div>
      );
    }
  },
  {
    Header: "Actual Total",
    id: "actualTotal",
    accessor: (data) => data?.originTotal,
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          {`₱ ${parseFloat(d?.actualTotal)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        </div>
      );
    }
  }
];
