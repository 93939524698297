import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { ILocations } from "../types/ILocations";

interface IOrderConfirmationState {
  confirmedLocations: ILocations;
}

const initialOrderConfirmationState = {
  confirmedLocations: []
} as IOrderConfirmationState
 
export const OrderConfirmation = createModel<RootModel>()({
  state: initialOrderConfirmationState,
  reducers: {
    resetState() {
      return { ...initialOrderConfirmationState, confirmedLocations: [] }
    },
    updateState(state, newState: Partial<IOrderConfirmationState>){
      return { ...state, ...newState }
    }
  },
  effects: (dispatch) => ({
  }),
});