import { useSelector } from 'react-redux';
import { Span } from '../../../../common/Span'  
import { RootState } from '../../../../store';
import { IVehicles } from '../../../../types/IVehicle';

const Info = () => {   

    const vehicles = useSelector<RootState>(
        (state: RootState) => state.RiderforADayModel.vehicles
    ) as IVehicles;

    const vehicleSelected = vehicles?.find((v) => v?.isSelected);

    return (<>  
        <p><Span padding='none'>
            This is your store location or a meet up place <br/>
            where the drivers will collect items for delivery.
        </Span></p>
        
        <br />
        
        <p><Span padding='none'>This is the startig point of the {vehicleSelected?.vehicleType === "motorcycle" ? 40 : 60} km reach limit</Span></p> 
    </>
    );

};

export default Info;