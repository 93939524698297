/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import MainContainer from "../../../components/MainContainer/MainContainer";
import Table from "../../../components/Table/Table";
import { Column } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import "react-datetime/css/react-datetime.css";
import "../OrderList.scss";
import { IDataOrder, IOrderList } from "../../../types/IDataOrders";
import moment from "moment";
import DatePicker from "react-datepicker";

import { Link, useParams } from "react-router-dom";

import { Span } from "../../../common/Span";
import { Button } from "../../../common/Button";
import { Input } from "../../../common/Input";
import { Select } from "../../../common/Select";

import { FiSearch } from "react-icons/fi";

import "react-datepicker/dist/react-datepicker.css";

import NoOrder from "../../../assets/img/web/noactivebooking-icon.png";
import NoData from "../../../assets/img/Nodata_icon.png";

import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import Content from "../Responsive/Content";

import OrderDetails from "../../OrderDetails/OrderDetails";
import {
  getCompletedExport,
  getCompletedExpressOrders
} from "../../../common/api/endpoints/orderlist";

import { BsCalendar } from "react-icons/bs";
import { downloadCSVFile } from "../../../common/utils";
import { VEHICLE } from "../../../constants";

const ROW_COUNT = 10;

function OrderListCompleted() {
  let { referenceNumber }: any = useParams();

  const dispatch = useDispatch<Dispatch>();

  const page: number = useSelector<RootState>(
    ((state) => state.OrderList.page) || 1
  ) as number;

  const isLoading = useSelector<RootState>(
    ((state) => state.OrderList.isLoading) || 1
  ) as number;

  const orderListModel = useSelector<RootState>(
    (state: RootState) => state.OrderList
  ) as IOrderList;

  const { dataOrderCompleted: orderList }: any = orderListModel || {};
  const [filteredData, setFilteredData] = useState([] as any);
  const [searchButton, setSearchButton] = useState(false);

  const [filterCategories, setFilterCategories] = useState("status");

  const [statusFilter, setStatusFilter] = useState("delivered&status=canceled");
  const [orderCodeFilter, setOrderCodeFilter] = useState("");

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const startD = new Date(startDate);
  const endD = new Date(endDate);

  const startDateFilter = moment(startD)
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .toDate()
    .toISOString();
  const endDateFilter = moment(endD)
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59)
    .toDate()
    .toISOString();

  const columnOrderList = useMemo<Column<any>[]>(
    () => [
      {
        Header: "Order No.",
        accessor: (data) => (
          <p className="order-list-order_number">{data?.orderCode}</p>
        )
      },
      {
        Header: "Vehicle Type",
        accessor: (data) => {
          return <p>{VEHICLE[data?.vehicleType]}</p>;
        }
      },
      {
        Header: "Status",
        accessor: (data) => {
          return (
            <p className={`status_${data?.status?.toLowerCase()} status_label`}>
              {data?.status?.replace("_", " ").toUpperCase()}
            </p>
          );
        }
      },
      {
        Header: "Date Created",
        accessor: (data) => {
          return (
            <>
              {data?.createdAt ? (
                `${moment(data?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}`
              ) : (
                <>No Data</>
              )}
            </>
          );
        }
      },
      {
        Header: "Date Delivered",
        accessor: (data) => {
          return (
            <>
              {data?.status === "delivered" && data?.deliveredAt ? (
                `${moment(data?.deliveredAt).format("MMMM Do YYYY, h:mm:ss a")}`
              ) : (
                <>No Data</>
              )}
            </>
          );
        }
      },
      {
        Header: "Pickup Location",
        accessor: (data) => {
          const pickup = data?.pickup?.locationAddress
            ? data?.pickup?.locationAddress
            : data?.pickup;
          return <p key={pickup}>{pickup}</p>;
        }
      },
      {
        Header: "Stops",
        accessor: (data) => {
          return <p>{data?.additionalStops}</p>;
        }
      },
      {
        Header: "Destination",
        accessor: (data) => {
          return (
            <p>
              {data?.dropOff?.locationAddress
                ? data?.dropOff?.locationAddress
                : data?.dropOff}
            </p>
          );
        }
      },
      {
        Header: "Item Description",
        accessor: (data) => {
          return <p>{data?.itemDescription}</p>;
        }
      },
      {
        Header: "Total Charge",
        accessor: (data) => {
          return (
            <p>
              {`₱${parseFloat(data?.totalCharge)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            </p>
          );
        }
      }
    ],
    []
  );

  const [orderDataCompleted, setOrderDataCompleted] = useState([] as any);
  const [totalRecord, setTotalRecord] = useState(1);

  const listenToOrderList = async (page, size, filter) => {
    dispatch.OrderList.setIsLoading(true);
    try {
      const res = await getCompletedExpressOrders(page, size, filter);
      setTotalRecord(res?.totalRecords);
      setOrderDataCompleted(res?.results);
    } catch (err) {
      console.log(err);
    }
    dispatch.OrderList.setIsLoading(false);
  };

  useEffect(() => {
    requestData();
  }, [totalRecord, page, startDateFilter, endDateFilter, searchButton]);

  useEffect(() => {
    if (page !== 1) {
      dispatch.OrderList.setState({ page: 1 });
    }
  }, [startDateFilter, endDateFilter]);

  const setOrderTable = () => {
    dispatch.OrderList.setMaxPage(Math.ceil(totalRecord / ROW_COUNT));

    dispatch.OrderList.setDataOrderCompleted({
      currentPage: 1,
      data: orderDataCompleted
    } as IDataOrder);
    setFilteredData(orderDataCompleted);

    dispatch.OrderList.setIsLoading(false);
  };

  const requestData = useCallback(() => {
    listenToOrderList(page, ROW_COUNT, {
      startTime: moment(startDateFilter).format("YYYY-MM-DD"),
      endTime: moment(endDateFilter).format("YYYY-MM-DD"),
      status: statusFilter,
      orderCode: orderCodeFilter
    });
  }, [page, startDateFilter, endDateFilter, statusFilter, orderCodeFilter]);

  const setFinalOrderTable = () => {
    dispatch.OrderList.setMaxPage(Math.ceil(totalRecord / ROW_COUNT));

    dispatch.OrderList.setDataOrderCompleted({
      currentPage: 1,
      data: filteredData
    } as IDataOrder);

    dispatch.OrderList.setIsLoading(false);
  };

  const handleSearch = () => {
    requestData();

    dispatch.OrderList.setState({ page: 1 });
  };

  const handleExport = async () => {
    const now = moment();
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const dateRangeInDays =
      (endDate.getTime() - startDate.getTime()) / millisecondsInADay;
    if (dateRangeInDays > 31) {
      dispatch.UI.setAlert({ message: "The date filter's maximum length is 31 days", type: "error" });
      return;
    }
    const dataExport = await getCompletedExport(1, {
      startTime: moment(startDateFilter).format("YYYY-MM-DD"),
      endTime: moment(endDateFilter).format("YYYY-MM-DD"),
      status: statusFilter,
      orderCode: orderCodeFilter
    });
    downloadCSVFile(
      dataExport as unknown as Blob,
      `Express-Order-Completed-${now.format("MMDDYYYY_hhmm")}.csv`
    );

    dispatch.OrderList.setIsLoading(false);
  };

  useEffect(() => {
    setOrderTable();
  }, [orderDataCompleted, orderCodeFilter, statusFilter]);

  useEffect(() => {
    setFinalOrderTable();
  }, [page, filteredData]);

  return (
    <>
      <MainContainer className="order-list">
        <div className="order">
          <div className="mt-10 flex">
            <Link to="/order-list">
              <div className="line">
                <div className="bottom">
                  <Span size="xl" weight="semi" color="orange">
                    Express List
                  </Span>
                </div>
              </div>
            </Link>

            <Link to="/order-history-rental">
              <div className="line">
                <div>
                  <Span size="xl" weight="medium">
                    Rental List
                  </Span>
                </div>
              </div>
            </Link>
          </div>

          <div className="order-list-sub-categories">
            <Link to="/order-list">
              <div className="line">
                <div>
                  <Span size="base">Open Order</Span>
                </div>
              </div>
            </Link>

            <Link to="/order-list-completed">
              <div className="line">
                <div className="bottom">
                  <Span size="base" weight="semi" color="orange">
                    Complete Order
                  </Span>
                </div>
              </div>
            </Link>
          </div>

          <div className="order__grid7 justify-between">
            <div className="filter">
              <div className="filter-categories">
                <div className="my-auto relative flex-none w-full p-1.5">
                  <Span
                    position="absolute"
                    size="xl"
                    width="none"
                    margin="none"
                    padding="none"
                  >
                    <FiSearch />
                  </Span>
                  <Select
                    className="w-11/12 ml-4"
                    onChange={(e: any) => {
                      setFilterCategories(e.target.value);
                      setSearchButton(false);
                      setOrderCodeFilter("");
                    }}
                  >
                    <option value="status"> Status </option>
                    <option value="orderCode"> Order Code </option>
                  </Select>
                </div>
              </div>

              {filterCategories === "status" ? (
                <div className="status-filter">
                  <div className="my-auto relative flex-none w-full p-1.5">
                    <Select
                      className="w-full"
                      onChange={(e: any) => {
                        setStatusFilter(e.target.value);
                        setSearchButton(false);
                      }}
                    >
                      <option value="delivered&status=canceled">
                        {" "}
                        All Status{" "}
                      </option>
                      <option value="delivered"> Delivered </option>
                      <option value="canceled"> Canceled </option>
                    </Select>
                  </div>
                </div>
              ) : (
                <div className="search-filter">
                  <div className="my-auto relative flex-none w-full px-1.5">
                    <Input
                      border="none"
                      type="text"
                      radius="md"
                      width="full"
                      value={orderCodeFilter}
                      onChange={(e: any) => {
                        setOrderCodeFilter(e.target.value);
                      }}
                      placeholder="Search order code"
                    />
                  </div>
                </div>
              )}
              <Button
                className="filter-date-btn"
                onClick={() => {
                  setSearchButton(true);
                  handleSearch();
                }}
              >
                Search
              </Button>
            </div>

            <div className="datefilter">
              <div className="flex justify-between items-center">
                <div className="px-2.5 py-1.5 datepicker-list">
                  <div className="my-auto relative flex-none w-full">
                    <Span
                      position="absolute"
                      size="xl"
                      width="none"
                      margin="none"
                      padding="py-2"
                    >
                      <BsCalendar className="my-auto" />
                    </Span>
                  </div>

                  <div className="pl-7 py-2">
                    <div className="flex justify-between">
                      <DatePicker
                        className="text-base focus:outline-none w-full mx-1"
                        selected={startDate}
                        onChange={(date: any) => {
                          setStartDate(date);
                        }}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Date From"
                        dateFormat="MMM d, yyyy"
                      />

                      <DatePicker
                        className="text-base focus:outline-none w-full mx-1"
                        selected={endDate}
                        onChange={(date: any) => {
                          setEndDate(date);
                        }}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        placeholderText="Date to"
                        dateFormat="MMM d, yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <Button
                    className="download-btn"
                    onClick={() => {
                      handleExport();
                    }}
                    disabled={!filteredData?.length}
                  >
                    EXPORT
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {!orderList?.data?.length && !filteredData.length ? (
            !filteredData.length ? (
              <div className="flex min-h-screen justify-center items-center">
                <div>
                  <img src={NoData} alt="" className="mt-3 h-24 mx-auto" />
                  <div className="text-center my-6">
                    <Span size="xl" color="grey">
                      No Data Found
                    </Span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex min-h-screen justify-center items-center">
                <div>
                  <img src={NoOrder} style={{ width: 250 }} />
                  <div className="text-center my-6">
                    <Span size="xl" weight="semi">
                      You don't have any active Order
                    </Span>
                    <p className="my-1">
                      <Span size="lg">Here you'll see all your Order.</Span>
                    </p>
                    <Link to="./order">
                      <Button weight="semi" margin="top">
                        Book Order
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div>
              {referenceNumber && (
                <>
                  <OrderDetails datatype="express-orders-completed" />
                </>
              )}

              <div className="maintable">
                <Table
                  columns={columnOrderList}
                  data={orderList?.data}
                  currentPage={orderList?.currentPage || 0}
                  hideFooterControl={true}
                  showOrderDetails={true}
                  isLoading={isLoading}
                  dataType="express-orders-completed"
                />

                <Pagination />
              </div>

              <Content orderList={orderList.data} />
            </div>
          )}
        </div>
      </MainContainer>
    </>
  );
}

export default OrderListCompleted;

function Pagination() {
  const dispatch = useDispatch<Dispatch>();

  const currentPage =
    useSelector((state: RootState) => state.OrderList.page) || 1;
  const maxPage = useSelector((state: RootState) => state.OrderList.maxPage);
  const isLoading = useSelector(
    (state: RootState) => state.OrderList.isLoading
  );

  const handlePrevious = () => {
    dispatch.OrderList.handlePrevious();
  };

  const handleNext = () => {
    dispatch.OrderList.handleNext();
  };

  if (!maxPage) return null;

  return (
    <section className="flex">
      <Button
        width="none"
        padding="normal"
        bg="lightorange"
        disabled={isLoading || currentPage === 1}
        onClick={handlePrevious}
      >
        <div className="flex text-center">
          <CgChevronLeft size={20} />
          <span>Previous</span>
        </div>
      </Button>
      <div className="my-auto mx-2">
        Page {currentPage} of {maxPage}
      </div>
      <Button
        width="none"
        padding="normal"
        bg="lightorange"
        disabled={isLoading || currentPage === maxPage}
        onClick={handleNext}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Next</span>
          <CgChevronRight size={20} />
        </div>
      </Button>
    </section>
  );
}
