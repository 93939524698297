import { useState } from "react";
import { Span } from "../../common/Span";
import CopyandPasteModal from "./Modal/CopyandPaste";

const CopyandPaste = ({ referenceNumber }: any) => {
  let handleShareLink = async (e: React.MouseEvent) => {
    e.preventDefault();
    setShareLinkModal(true);
  };

  const [sharelinkModal, setShareLinkModal] = useState(false);

  return (
    <>
      <div onClick={handleShareLink} className="cursor-pointer my-auto">
        <Span weight="semi" color="orange" uppercase>
          Copy and Share
        </Span>
      </div>

      {sharelinkModal && (
        <CopyandPasteModal
          setShareLinkModal={setShareLinkModal}
          referenceNumber={referenceNumber}
        />
      )}
    </>
  );
};

export default CopyandPaste;
