import DO_ICON from "../../src/assets/img/web/pb_dropofficon.png";
import DO_ICON_DRIVER from "../../src/assets/img/web/pb_drivericon.png";
import PU_ICON from "../../src/assets/img/web/pb_pickupicon.png";
import STOP_ICON_1 from "../../src/assets/img/web/stop_icons/icon_stop_1.png";
import STOP_ICON_2 from "../../src/assets/img/web/stop_icons/icon_stop_2.png";
import STOP_ICON_3 from "../../src/assets/img/web/stop_icons/icon_stop_3.png";
import STOP_ICON_4 from "../../src/assets/img/web/stop_icons/icon_stop_4.png";
import STOP_ICON_5 from "../../src/assets/img/web/stop_icons/icon_stop_5.png";
import STOP_ICON_6 from "../../src/assets/img/web/stop_icons/icon_stop_6.png";
import STOP_ICON_7 from "../../src/assets/img/web/stop_icons/icon_stop_7.png";
import STOP_ICON_8 from "../../src/assets/img/web/stop_icons/icon_stop_8.png";
import STOP_ICON_9 from "../../src/assets/img/web/stop_icons/icon_stop_9.png";
import STOP_ICON_10 from "../../src/assets/img/web/stop_icons/icon_stop_10.png";
import STOP_ICON_11 from "../../src/assets/img/web/stop_icons/icon_stop_11.png";
import STOP_ICON_12 from "../../src/assets/img/web/stop_icons/icon_stop_12.png";
import STOP_ICON_13 from "../../src/assets/img/web/stop_icons/icon_stop_13.png";
import STOP_ICON_14 from "../../src/assets/img/web/stop_icons/icon_stop_14.png";
import STOP_ICON_15 from "../../src/assets/img/web/stop_icons/icon_stop_15.png";
import STOP_ICON_16 from "../../src/assets/img/web/stop_icons/icon_stop_16.png";
import STOP_ICON_17 from "../../src/assets/img/web/stop_icons/icon_stop_17.png";
import STOP_ICON_18 from "../../src/assets/img/web/stop_icons/icon_stop_18.png";
import STOP_ICON_19 from "../../src/assets/img/web/stop_icons/icon_stop_19.png";
import STOP_ICON_20 from "../../src/assets/img/web/stop_icons/icon_stop_20.png";
import STOP_ICON_21 from "../../src/assets/img/web/stop_icons/icon_stop_21.png";
import STOP_ICON_22 from "../../src/assets/img/web/stop_icons/icon_stop_22.png";
import STOP_ICON_23 from "../../src/assets/img/web/stop_icons/icon_stop_23.png";
import STOP_ICON_24 from "../../src/assets/img/web/stop_icons/icon_stop_24.png";
import STOP_ICON_25 from "../../src/assets/img/web/stop_icons/icon_stop_25.png";
import STOP_ICON_26 from "../../src/assets/img/web/stop_icons/icon_stop_26.png";
import STOP_ICON_27 from "../../src/assets/img/web/stop_icons/icon_stop_27.png";
import STOP_ICON_28 from "../../src/assets/img/web/stop_icons/icon_stop_28.png";
import STOP_ICON_29 from "../../src/assets/img/web/stop_icons/icon_stop_29.png";
import STOP_ICON_30 from "../../src/assets/img/web/stop_icons/icon_stop_30.png";

import BRAND_LOGO from '../../src/assets/img/web/alleasygo.svg';

export const PAYMENT_METHODS = {
  CASH: 1,
  WALLET: 2,
  BILLING: 3,
};

export const DELIVERY_SCHEDULE_TYPE = {
  SCHEDULED: 1,
  IMMEDIATE: 2,
};

export const STATUS_LABEL = {
  SEARCHING: "SEARCHING",
  ASSIGNED: "ASSIGNED",
  DRIVER_ARRIVED: "ARRIVED",
  TRIP_STARTED: "TRIP STARTED",
  COMPLETED: "COMPLETED",
  REQUESTING: "REQUESTING",
  CANCELLED: "CANCELLED",
};

export const TRIP_STATUS = {
  RIDE_INITIATED: 0,
  SEARCHING_FOR_DRIVERS: 1,
  DRIVER_ASSIGNED: 2,
  DRIVER_ARRIVED: 3,
  TRIP_STARTED: 4,
  TRIP_CANCELLED_BY_RIDER: 5,
  TRIP_CANCELLED_EARLY_BY_RIDER: 6,
  TRIP_CANCELLED_BY_DRIVER: 7,
  TRIP_COMPLETED: 8,
  NO_DRIVERS_FOUND: 9,
  SERVER_ERROR: 10,
  TRIP_CANCELLED_BY_ADMIN: 11,
  TRIP_DRIVER_REQUEST: 13,
};

export const CASH_REMITTANCE = "Pabili";
export const MPV = "Require MPV / SUV Only";
export const DRIVER_HELPS = "Driver Helps";
export const EXTRA_HELP = "Extra Helper";
export const EXTRA_HELP_2ND = "Extra Helper (2nd)";

export const DEFAULT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const PICK_UP_ICON = PU_ICON 
export const DROF_OFF_ICON = DO_ICON 
export const DROF_OFF_ICON_DRIVER = DO_ICON_DRIVER  
export const STOP_ICONS = {
	STOP_ICON_1,
	STOP_ICON_2,
	STOP_ICON_3,
	STOP_ICON_4,
	STOP_ICON_5,
	STOP_ICON_6,
	STOP_ICON_7,
	STOP_ICON_8,
	STOP_ICON_9,
	STOP_ICON_10,
	STOP_ICON_11,
	STOP_ICON_12,
	STOP_ICON_13,
	STOP_ICON_14,
	STOP_ICON_15,
	STOP_ICON_16,
	STOP_ICON_17,
	STOP_ICON_18,
	STOP_ICON_19,
	STOP_ICON_20,
	STOP_ICON_21,
	STOP_ICON_22,
	STOP_ICON_23,
	STOP_ICON_24,
	STOP_ICON_25,
	STOP_ICON_26,
	STOP_ICON_27,
	STOP_ICON_28,
	STOP_ICON_29,
	STOP_ICON_30,
} as any;

export const DRIVER_ERROR = {
	ACCEPT_DRIVER_SERVICE_PERMISSION_ERROR : 'Driver permission not matched trip type.',
	ACCEPT_DRIVER_VEHICLE_NOT_MATCHED : 'Driver vehicle did not match trip request vehicle.',
	BadRequest : 'One or more fields in the request was invalid.',
	BAG_REQUIRED : 'Biker do not have a Insulated Bag.',
	DRIVER_TRIP_REGION_NOT_MATCHED: 'Driver is not allowed to assign trip in this region',
	MPV_ADDON_NOT_MATCHED : 'MPV add-on not matched.',
	TRIP_ALREADY_ASSIGNED: 'The trip has been assigned to another driver.',
} as any;

// trip type
export const TRIP_TYPE_ONE_WAY = 1;
export const TRIP_TYPE_ROUND_TRIP = 2;

//order status 
export const ORDERSTATUS = { 
    new: "New",
    pending: "Pending",  
    assigned: "Assigned",
    for_pickup: "For Pickup",
    picked_up: "Picked up",
    on_going: "Ongoing",
    failed_delivery: "Failed Delivery",
    delivered: "Delivered", 
    canceled: "Canceled",
    draft: "Draft"
}; 

export const ORDERSTATUSFILTER = { 
    created: "created",
    pending: "pending",  
    assigned: "assigned",
    for_pickup: "for_pickup",
    picked_up: "picked_up",
    on_going: "on_going",
    failed_delivery: "failed_delivery",
    delivered: "delivered", 
    canceled: "canceled"
}; 

export const RENTALSTATUS = {
  pending: "Pending",
  for_pickup: "For Pickup",
  picked_up: 'Picked Up',
  assigned: "Assigned", 
  accepted: 'Accepted',
  on_going: 'On Going',
  failed_delivery: "Failed Delivery",
  delivered: "Delivered", 
  canceled: "Canceled",
  time_in: "Time in",
  completed: "Completed"
} 


export const PAYMENTMETHOD = {
  cash_on_pickup: "Cash on Pickup",
  cash_on_delivery: "Cash on Delivery",
  billing: "Billing",
  all_easy: "AllEasy"
}

export const PODSKIPREASONS = { 
  unable_to_take_photo: "Unable to take photo",
  customer_refused: "Customer refused",
};

export const BRAND_IMAGES = {
  BRAND_LOGO
}

export const CANCEL_REASON = [
  'No rider found',
  'I change my mind',
  'The delivery fee is high',
  'Change pick-up location',
  'Change drop off location',
  'Double order',
  'Service is no longer required',
  'Restaurant took too long to accept',
  'Pinned the wrong delivery location'
]

export const OPEN_ORDERS = [
  "pending",
  "assigned",
  "for_pickup",
  "picked_up",
  "on_going", 
  "accepted",
  "time_in"
];


export const DATA_TYPE = {
  completed: "express-orders-completed",
  open: "express-orders"
};


export const VEHICLE = {
  motorcycle: "Motorcycle",
  mpv200:  "MPV 200",
  mpv300: "MPV 300",
  mpv600: "MPV 600",
  lt1000: "LT1000",
  truck: "Truck",
  truck6w:"6-Wheeler Truck",
  truck10w:"10-Wheeler Truck"
};