import { createModel } from "@rematch/core";
import { RootModel } from "./index";;
 
type Location = {
  lat: number;
  lng: number;
  address: string; 
} 

const initialState = { 
  location: {
    lat: 0,
    lng: 0,
    address: '',
  }, 
}
 
export const Parcels = createModel<RootModel>()({
  state: initialState, 
  reducers: { 
    setLocation(state, payload: Location) {
      return {...state, location: payload};
    },
  },
  effects: (dispatch) => ({    
    async updateAddress(payload: Location, state){
      dispatch.cart.setLocation(payload);
    },
    async clearAddress(payload: Location, state){
      dispatch.cart.updateAddress(payload);
    }, 
  }),
});