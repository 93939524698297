import { createModel } from "@rematch/core";
import firebase from "firebase";
import { RootModel } from ".";
import { getRecentTransactions, getWalletBalance } from "../common/api";
import { IDataWallet, IWalletList } from "../types/IDataWallet";

let data: IDataWallet = {
  data: [],
  currentPage: 1
};
 
const initialState = {
  page: 1,
  maxPage: 0,
  balance: 0,
  dataWallet: data, 
  isLoading: false,
  isAllEasyTokenSet: false,
} as IWalletList;

export const Wallet = createModel<RootModel>()({

  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    setNewState(state, newState: Partial<IWalletList>) {
      return { ...state, ...newState };
    }, 
    setState(state, newState: Partial<IWalletList>) {
      return { ...state, ...newState };
    },
    setDataWallet(state, payload: IDataWallet) {
      state.dataWallet = payload; 
      return { ...state };
    },
    handleNext: (_) => ({ ..._, page: _.page + 1 }),
    handlePrevious: (_) => ({ ..._, page: _.page - 1 }),
    setIsLoading: (_, isLoading: boolean) => ({ ..._, isLoading }),
    setMaxPage: (_, maxPage: number) => ({ ..._, maxPage }),
  },
  effects: (dispatch) => ({
    /**
     * @name getWalletBalance
     * @desc get user's wallet balance
     */
    async getWalletBalance(){
      let balance
      try {
        dispatch.Wallet.setNewState({isLoading: true})
        balance = await getWalletBalance();
      } catch (err) {
        throw err
      } finally {
        dispatch.Wallet.setNewState({ balance: balance, isLoading: false, isAllEasyTokenSet: Boolean((await firebase.auth().currentUser?.getIdTokenResult(true))?.claims["allEasyToken"])})
      }
    },
    /**
     * @name getWalletTransactions
     * @desc get user's wallet transactions
     */
    // async getRecentTransactionsWallet(){
    //   let transactions
    //   try {
    //     dispatch.Wallet.setNewState({isLoading: true})
    //     transactions = await getRecentTransactions();
    //   } catch (err) {
    //     throw err
    //   } finally {
    //     dispatch.Wallet.setNewState({ dataWallet: transactions, isLoading: false, isAllEasyTokenSet: Boolean((await firebase.auth().currentUser?.getIdTokenResult(true))?.claims["allEasyToken"]) })
    //   }
    // },

  }), 
});
