import { Span } from '../../../../common/Span'  

const Info = () => {   
    return (<>  
        <Span padding='none'>
            Booking of multiple schedules is supported. <br />
            You can book daily, weekly, or monthly delivery.
        </Span> 
    </>);

};

export default Info;