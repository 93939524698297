/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useMemo, useState } from "react";
import MainContainer from "../../../components/MainContainer/MainContainer";
import Table from "../Table";
import { useDispatch, useSelector } from "react-redux";
import { RootState, Dispatch } from "../../../store";
import "react-datetime/css/react-datetime.css";
import "../RentalList.scss";
import { IDataRental, IRentalList } from "../../../types/IDataRentals";

import { Link, useParams } from "react-router-dom";

import { Span } from "../../../common/Span";
import { Button } from "../../../common/Button";
import { Input } from "../../../common/Input";
import { Select } from "../../../common/Select";

import "react-datepicker/dist/react-datepicker.css";

import NoOrder from "../../../assets/img/web/noactivebooking-icon.png";
import NoData from "../../../assets/img/Nodata_icon.png";

import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { COLUMNS } from "../column";
import RentalContent from "../Responsive/RentalContent";

import RentalDetails from "../RentalDetails/RentalDetails";
import { getRentalListCompleteOrder } from "../../../common/api";
import { FaFilter } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

const ROW_COUNT = 10;

function RentalListCompleted() {
  let { orderCode }: any = useParams();

  const dispatch = useDispatch<Dispatch>();

  const page: number = useSelector<RootState>(
    ((state) => state.RentalList.page) || 1
  ) as number;

  const isLoading = useSelector<RootState>(
    ((state) => state.RentalList.isLoading) || 1
  ) as number;

  const rentalListModel = useSelector<RootState>(
    (state: RootState) => state.RentalList
  ) as IRentalList;

  const { dataRentalCompleted: orderList }: any = rentalListModel || {};

  const columns = useMemo(() => COLUMNS, []);

  const [rentalDataCompleted, setRentalDataCompleted] = useState([] as any);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [filteredData] = useState([] as any);

  const isDefaultSearchValue = search?.length === 0 && statusFilter === "all";

  const listenToRentalListCompleted = async () => {
    dispatch.RentalList.setIsLoading(true);
    try {
      const res = await getRentalListCompleteOrder();
      setRentalDataCompleted(res?.results);
    } catch (err) {
      console.log(err);
    }
    dispatch.RentalList.setIsLoading(false);
  };

  const setOrderTable = () => {
    const finalData = !isDefaultSearchValue
      ? rentalDataCompleted.filter((d: any) => {
          if (search.length > 0) {
            return (
              JSON.stringify(Object.values(d)).search(new RegExp(search, "i")) >
              -1
            );
          }
          if (statusFilter != "all") {
            return d?.status.includes(statusFilter);
          }
        })
      : rentalDataCompleted;

    const totalItems = finalData.length;
    dispatch.RentalList.setMaxPage(Math.ceil(totalItems / ROW_COUNT));

    const startIndex = page * ROW_COUNT - ROW_COUNT;
    const endIndex = page * ROW_COUNT;

    dispatch.RentalList.setDataOrderCompleted({
      currentPage: 1,
      data: finalData.slice(startIndex, endIndex)
    } as IDataRental);

    dispatch.RentalList.setIsLoading(false);
  };

  const setFinalOrderTable = () => {
    const totalItems = filteredData.length;
    dispatch.RentalList.setMaxPage(Math.ceil(totalItems / ROW_COUNT));

    const startIndex = page * ROW_COUNT - ROW_COUNT;
    const endIndex = page * ROW_COUNT;

    dispatch.RentalList.setDataOrder({
      currentPage: 1,
      data: filteredData.slice(startIndex, endIndex)
    } as IDataRental);

    dispatch.RentalList.setIsLoading(false);
  };

  useEffect(() => {
    setOrderTable();
  }, [rentalDataCompleted, search, statusFilter]);

  useEffect(() => {
    setFinalOrderTable();
  }, [page, filteredData]);

  useEffect(() => {
    listenToRentalListCompleted();
  }, []);

  return (
    <>
      <MainContainer className="order-list">
        <div className="order">
          <div className="mt-10 flex">
            <Link to="/order-list">
              <div className="line">
                <div>
                  <Span size="xl" weight="medium">
                    Express List
                  </Span>
                </div>
              </div>
            </Link>

            <Link to="/order-history-rental">
              <div className="line">
                <div className="bottom">
                  <Span size="xl" weight="semi" color="orange">
                    Rental List
                  </Span>
                </div>
              </div>
            </Link>
          </div>

          <div className="rental-sub-categories">
            <Link to="/order-history-rental">
              <div className="line">
                <div>
                  <Span size="base">Open Order</Span>
                </div>
              </div>
            </Link>

            <Link to="/order-history-rental-completed">
              <div className="line">
                <div className="bottom">
                  <Span size="base" weight="semi" color="orange">
                    Complete Order
                  </Span>
                </div>
              </div>
            </Link>
          </div>

          <div className="order__grid7">
            <div className="filter">
              <div className="status">
                <div className="my-auto relative flex-none w-full p-1.5">
                  <Span
                    position="absolute"
                    size="xl"
                    width="none"
                    margin="none"
                    padding="none"
                  >
                    <FaFilter />
                  </Span>
                  <Select
                    className="w-11/12 ml-4"
                    onChange={(e: any) => {
                      setStatusFilter(e.target.value);
                    }}
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      Select Status{" "}
                    </option>
                    <option value="all"> All Status </option>
                    <option value="completed"> Completed </option>
                    <option value="canceled"> Canceled </option>
                  </Select>
                </div>
              </div>

              <div className="search">
                <div className="my-auto relative flex-none w-full p-1.5">
                  <Span position="absolute" size="xl" width="none">
                    <FiSearch className="my-auto" />
                  </Span>
                  <Input
                    padding="pl-10"
                    border="none"
                    type="text"
                    radius="md"
                    width="full"
                    value={search}
                    onChange={(e: any) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search anything"
                  />
                </div>
              </div>
            </div>
          </div>

          {!orderList?.data?.length && !filteredData.length ? (
            !filteredData.length ? (
              <div className="flex min-h-screen justify-center items-center">
                <div>
                  <img src={NoData} alt="" className="mt-3 h-24 mx-auto" />
                  <div className="text-center my-6">
                    <Span size="xl" color="grey">
                      No Data Found
                    </Span>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: 80
                }}
              >
                <img src={NoOrder} style={{ width: 250 }} />
                <div className="text-center my-6">
                  <Span size="xl" weight="semi">
                    You don't have any Rental order
                  </Span>
                  <p className="my-1">
                    <Span size="lg">
                      Here you'll see all your Rental Transaction History.
                    </Span>
                  </p>
                </div>
              </div>
            )
          ) : (
            <div>
              {orderCode && (
                <>
                  <RentalDetails />
                </>
              )}

              <div className="maintable">
                <Table
                  columns={columns}
                  data={orderList?.data}
                  currentPage={orderList?.currentPage || 0}
                  hideFooterControl={true}
                  showRentalDetails={true}
                  isLoading={isLoading}
                  dataType="rental-order-completed"
                />

                <Pagination />
              </div>

              <RentalContent rentalList={orderList?.data} />
            </div>
          )}
        </div>
      </MainContainer>
    </>
  );
}

export default RentalListCompleted;

function Pagination() {
  const dispatch = useDispatch<Dispatch>();

  const currentPage =
    useSelector((state: RootState) => state.RentalList.page) || 1;
  const maxPage = useSelector((state: RootState) => state.RentalList.maxPage);
  const isLoading = useSelector(
    (state: RootState) => state.RentalList.isLoading
  );

  const handlePrevious = () => {
    dispatch.RentalList.handlePrevious();
  };

  const handleNext = () => {
    dispatch.RentalList.handleNext();
  };

  if (!maxPage) return null;

  return (
    <section className="flex">
      <Button
        width="none"
        padding="normal"
        bg="lightorange"
        disabled={isLoading || currentPage === 1}
        onClick={handlePrevious}
      >
        <div className="flex text-center">
          <CgChevronLeft size={20} />
          <span>Previous</span>
        </div>
      </Button>
      <div className="my-auto mx-2">
        Page {currentPage} of {maxPage}
      </div>
      <Button
        width="none"
        padding="normal"
        bg="lightorange"
        disabled={isLoading || currentPage === maxPage}
        onClick={handleNext}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Next</span>
          <CgChevronRight size={20} />
        </div>
      </Button>
    </section>
  );
}
