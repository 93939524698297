import moment, { unix } from 'moment';
export const isNumber = (value: any) => {
	return !isNaN(Number(value));
}

export const currencyCommaFormat = (value: any, decimal = 0, hasPesoSign: boolean = false) => {
	if (isNumber(value)) {
		return Number(value).toFixed(decimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	} else {
		return `${hasPesoSign ? '₱ ' : ''}${String(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
	}
};

export const onlyNumberKey = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		return evt.preventDefault();
	}
	return true;
}

interface DateTimeProps {
	date: string;
	time: string;
}

const dateFormatToday = 'MMM DD [(Today)]', dateFormat = 'MMM DD (ddd)', timeDefaultFormat = 'h:mm A';

export const formatDeliveryDate = (date: any, dateFromat?: string, timeFormat?: string) => {
	let defaultFormat = dateFromat ? dateFromat : dateFormat;
	let defaultTodayFormat = dateFromat ? dateFromat : dateFormatToday;
	timeFormat = timeFormat ? timeFormat : timeDefaultFormat;

	if (!date && date === '') return '';

	const value = {
		date: (moment(date).isSame(moment(), 'date')) ? moment(date).format(defaultTodayFormat) : moment(date).format(defaultFormat),
		time: moment(date).format(timeFormat)
	} as DateTimeProps;

	return value;
}


export const formatDate = (date) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format('MMMM DD, YYYY, h:mm A')
	return formattedDate;
}

export const formatToDate = (date) => { 
	const formattedDate =  moment(date).format("MMMM DD, YYYY - h:mm:ss A")
	return formattedDate;
}
 


export const formatDateOnly = (date) => {
	const d = !date?.seconds ? new Date(date) : new Date(date?.seconds * 1000);
	const formattedDate = moment(d).format('MMM DD, YYYY')
	return formattedDate;
} 

export const formatDateStartEnd = (seconds: any) => {

	const formattedSD = moment(new Date(seconds * 1000)).format('MMM. DD, YYYY');

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm A');
	const formattedET = moment(date.setHours(date.getHours() + 9)).format('hh:mm A');
	
	const finalDateFormat = `${formattedSD} (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}

export const formatDateStartEndHalfday = (seconds: any) => {

	const formattedSD = moment(new Date(seconds * 1000)).format('MMM. DD, YYYY');

	const date = new Date(seconds * 1000)

	const formattedST = moment(new Date(seconds * 1000)).format('hh:mm A');
	const formattedET = moment(date.setHours(date.getHours() + 5)).format('hh:mm A');
	
	const finalDateFormat = `${formattedSD} (${formattedST} - ${formattedET})`;
	return finalDateFormat;
}

export const formatTripDateByUnix = (unixValue: number) => {

	if (unixValue) {
		const myMoment = unix(unixValue);
		const isToday = myMoment.isSame(moment(), 'day');

		const value = {
			date: myMoment.format(isToday ? dateFormatToday : dateFormat),
			time: myMoment.format(timeDefaultFormat)
		} as DateTimeProps;
		return value;
	}
	return null;
}


export const getVehicleLabel = (vehicle: string) => {
	if (vehicle === "motorcycle") return "Motorcycle";
	else if (vehicle === "mpv300") return "MPV300";
	else if (vehicle === "mpv600") return "MPV600";
	else if (vehicle === "lt1000") return "LT1000";


	return vehicle;
}

export const getTripTypeLabel = (type: number) => {
	if (type === 1) return "Single Trip";
	else if (type === 2) return "Round Trip";

	return "";
}

export const getTripScheduleTypeLabel = (type: number) => {
	if (type === 1) return "Scheduled";
	else if (type === 2) return "Immediate";

	return "";
}

export const rowCount = (props: any, isLowerDevice?: boolean) => {

	if (!isLowerDevice) {
		if (props?.value?.length > 0 && props?.value.length < 55) {
			return 1;
		}
		if (props?.value?.length >= 55 && props?.value?.length < 110) {
			return 2;
		}
		if (props?.value.length >= 110 && props?.value.length < 145) {
			return 3;
		}
		if (props?.value.length >= 145) {
			return 4;
		}
		return 1;
	}
	else {
		if (props?.value?.length > 0 && props?.value.length < 30) {
			return 1;
		}
		if (props?.value?.length >= 30 && props?.value.length <= 65) {
			return 2;
		}
		if (props?.value?.length > 65 && props?.value.length < 100) {
			return 3;
		}
		if (props?.value.length >= 100) {
			return 4;
		}
		return 1;
	}

}

export const getfinalStartEndDate = (start, end) => {
	const fsDate = moment(start).format('MMM. DD - ') //final start date
	const feDate = moment(end).format('MMM. DD, YYYY') // final end date

	const fsDay = moment(start).format('ddd') //final start day
	const feDay = moment(end).format('ddd') //final end day

	const fsTime = moment(start).format('HH:mm A');
	const feTime = moment(end).format('HH:mm A');

	
	let date = fsDate + feDate;
	let day = `(${fsDay} - ${feDay})`;
	const time = `${fsTime} - ${feTime}`;
	
	const isDateSame = fsDate.includes(moment(end).format('MMM. DD - '));
	if(isDateSame){
		date = feDate;
		day = feDay;
	}
	return { date, day, time };
}

export const downloadCSVFile = (data: Blob, filename: string) => {
	const url = window.URL.createObjectURL(new Blob(['\ufeff' + data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename); //or any other extension
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
  };

  
  export const formatDecimalPrice = (value = 0) =>
  value ? Number(parseFloat(`${value}`).toFixed(2)).toLocaleString('en') : 0;