import "./App.scss";
import "./globalScss/index.scss";
import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAlert } from "react-alert";
import { lazy } from "react";
import { RootState } from "./store";
import { useSelector } from "react-redux";
import moment from "moment";
import WebWorkers from "./components/WebWorkers";

import Login from "./containers/Login/Login";

import Rental from "./containers/RiderforADay/RiderforADay";
import RentalOrder from "./containers/RiderforADay/RiderOrder/RiderOrder";
import RentalList from "./containers/RentalList/RentalList";
import RentalListCompleted from "./containers/RentalList/Completed/RentalListCompleted";

import Order from "./containers/Order/Order";
import OrderListCompleted from "./containers/OrderList/Completed/OrderListCompleted";

import OrderConfirmation from "./containers/OrderConfirmation/OrderConfirmation";
import OrderList from "./containers/OrderList/OrderList";
import WalletLedger from "./containers/WalletLedger/WalletLedger";
import OrderDetails from "./containers/OrderDetails/OrderDetailsPage";

const Logout = lazy(() => import("./containers/Logout/Logout"));
const PinLogin = lazy(() => import("./containers/PinLogin/Login"));
const LoginPin = lazy(() => import("./containers/PinLogin/LoginPin"));
const Config = lazy(() => import("./containers/Config/Config"));
const PinPage = lazy(() => import("./containers/AllEasySignUp/PinPage"));
const OTPPage = lazy(() => import("./containers/AllEasySignUp/OTPPage"));
const SignUp = lazy(() => import("./containers/SignUp/SignUp"));
const ForgetPassword = lazy(
  () => import("./containers/ForgetPassword/ForgetPassword")
);
const SMS = lazy(() => import("./containers/ForgetPassword/SMS"));
const AuthRoute = lazy(() => import("./components/AuthRoute/AuthRoute"));
const Home = lazy(() => import("./containers/Home/Home"));
const TopUp = lazy(() => import("./containers/TopUp/TopUp"));
const TopUpWallet = lazy(() => import("./containers/TopUp/TopUpWallet"));
const TopUpSummary = lazy(() => import("./containers/TopUp/TopUpSummary"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));
const ChangePassword = lazy(
  () => import("./containers/ChangePassword/ChangePassword")
);
const Webhook = lazy(() => import("./containers/Webhook/index"));

const Loader = lazy(() => import("./components/Loader/Loader"));

const SideMenu = lazy(() => import("./components/SideMenu/SideMenu"));
const Account = lazy(() => import("./components/Account/Account"));

const ShareRoute = lazy(() => import("./containers/ShareRoute/ShareRoute"));

require("dotenv").config();

// component app
const App = () => {
  const alertHook = useAlert();

  const isUserLoggedIn = useSelector(
    (state: RootState) => state.User.isUserLoggedIn
  );

  const { isLoading, alert } = useSelector((state: RootState) => state.UI);
  const { message, type, alertWatcher } = alert;

  useEffect(() => {
    // checks alert watcher if it is update just two sec ago
    let isLessThanTwoSecs = moment(alertWatcher).isAfter(
      moment(moment.now()).subtract(0.5, "seconds")
    );

    if (message && isLessThanTwoSecs) {
      alertHook.show(message, { type, timeout: 3000 });
    }

    // eslint-disable-next-line
  }, [alertWatcher]);
  return (
    <WebWorkers>
      <Router>
        {isUserLoggedIn && !window.location.pathname.includes("/share") ? (
          <SideMenu />
        ) : null}
        <Switch>
          <Route exact path="/" component={PinLogin} />
          <Route exact path="/connect" component={LoginPin} />
          <Route exact path="/config" component={Config} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/signup/otppage" component={OTPPage} />
          <Route exact path="/signup/pinpage" component={PinPage} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/sms" component={SMS} />
          <Route path="/login/:authId" component={Login} />
          <AuthRoute exact path="/alleasy/connect" component={LoginPin} />
          <AuthRoute path="/home" component={Home} />
          <AuthRoute path="/rental" component={Rental} />
          <AuthRoute path="/rental-order" component={RentalOrder} />
          <AuthRoute
            path="/order-history-rental/:orderCode?"
            component={RentalList}
          />
          <AuthRoute
            path="/order-history-rental-completed/:orderCode?"
            component={RentalListCompleted}
          />
          <AuthRoute path="/order" component={Order} />;
          <AuthRoute
            path="/order-details/:referenceNumber"
            component={OrderDetails}
          />
          <AuthRoute path="/topup" component={TopUp} />
          <AuthRoute path="/wallet" component={WalletLedger} />
          <AuthRoute path="/topupwallet" component={TopUpWallet} />
          <AuthRoute path="/topupsummary" component={TopUpSummary} />
          <AuthRoute path="/order-confirmation" component={OrderConfirmation} />
          <AuthRoute
            path="/order-list/:referenceNumber?"
            component={OrderList}
          />
          <AuthRoute
            path="/order-list-completed/:referenceNumber?"
            component={OrderListCompleted}
          />
          <AuthRoute path="/acct" component={Account} />
          <AuthRoute path="/changepassword" component={ChangePassword} />
          <AuthRoute exact path="/webhook" component={Webhook} />
          <Route
            exact
            path="/share/:referenceNumber/:shareAuth"
            component={ShareRoute}
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
      {isLoading ? <Loader ultra={true} /> : null}
    </WebWorkers>
  );
};

export default App;
